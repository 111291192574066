import { DatePicker } from '@mui/x-date-pickers';
import { MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { DateFilter } from 'types/api';

export interface DateRangeFilterProps {
  dateFilter: DateFilter | null;
  filterTypes: DateFilter[] | undefined;
  fromDate: Date | string | null | undefined;
  toDate: Date | string | null | undefined;
  disabled?: boolean;
  onFilterTypeChanged: (option: string) => void;
  onFromDateChanged: (value: Dayjs) => void;
  onToDateChanged: (value: Dayjs) => void;
}

export const DateRangeFilter = (props: DateRangeFilterProps) => {
  return (
    <Stack direction="row" gap={1} alignItems="stretch">
      <Stack>
        {!!props.filterTypes && (
          <Select
            size="small"
            variant="outlined"
            disabled={props.disabled}
            readOnly={props.filterTypes?.length <= 1}
            value={props.dateFilter?.type}
            onChange={(event) => props.onFilterTypeChanged(event.target.value)}
            renderValue={() => <Typography variant="body1">{props.dateFilter?.displayValue}</Typography>}
          >
            {props.filterTypes.map((o) => (
              <MenuItem key={o.type} value={o.type}>
                {o.displayValue}
              </MenuItem>
            ))}
          </Select>
        )}
      </Stack>

      <Stack>
        {props.dateFilter?.hasFromDate && (
          <DatePicker
            label={props.dateFilter.fromDisplayValue}
            inputFormat="DD/MM/YYYY"
            value={props.fromDate}
            disabled={props.disabled}
            onChange={(value) => props.onFromDateChanged(dayjs(value))}
            renderInput={(params) => (
              <TextField
                autoComplete="off"
                sx={{
                  '& .MuiInputBase-input': {
                    height: '16px'
                  },
                  maxWidth: 150,
                  minWidth: 150
                }}
                onKeyDown={(e) => e.preventDefault()}
                {...params}
              />
            )}
          />
        )}
      </Stack>

      <Stack>
        {props.dateFilter?.hasToDate && (
          <DatePicker
            label={props.dateFilter.toDisplayValue}
            inputFormat="DD/MM/YYYY"
            value={props.toDate}
            disabled={props.disabled}
            onChange={(value) => props.onToDateChanged(dayjs(value))}
            renderInput={(params) => (
              <TextField
                autoComplete="off"
                size="medium"
                sx={{
                  '& .MuiInputBase-input': {
                    height: '16px'
                  },
                  maxWidth: 150,
                  minWidth: 150
                }}
                onKeyDown={(e) => e.preventDefault()}
                {...params}
              />
            )}
          />
        )}
      </Stack>
    </Stack>
  );
};
