import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { ApiResponse } from 'types/api';
import { AccountAlert } from 'types/customer-alerts';
import axiosServices from 'utils/api/axiosServices';

export interface ICustomerAlertsProps {
  alerts: AccountAlert[] | null;
  isFetching: boolean;
  abortController: AbortController | null;
}

export const CustomerAlertsInitialState: ICustomerAlertsProps = {
  alerts: null,
  isFetching: false,
  abortController: null
};

const slice = createSlice({
  name: 'customerAlerts',
  initialState: { ...CustomerAlertsInitialState },
  reducers: {
    setAlerts(state, action: PayloadAction<AccountAlert[]>) {
      state.alerts = action.payload;
    },
    setFetching(state, action: PayloadAction<{ isFetching: boolean; abortController?: AbortController }>) {
      state.isFetching = action.payload.isFetching;
      state.abortController = action.payload.abortController ?? null;
    },
    resetCustomerAlerts(state) {
      state.isFetching = CustomerAlertsInitialState.isFetching;
      state.alerts = CustomerAlertsInitialState.alerts;
    }
  }
});

export default slice.reducer;
export const { setAlerts } = slice.actions;

export function updateCustomerAlerts() {
  return async (dispatch: any, getState: () => RootState) => {
    const state = getState().customerAlerts;
    const abortPreviousRequest: boolean = state.isFetching;

    let abortController = state.abortController ?? new AbortController();

    if (abortPreviousRequest) {
      abortController.abort();
      abortController = new AbortController();
    }

    try {
      dispatch(slice.actions.setFetching({ isFetching: true, abortController: abortController }));

      const response = await axiosServices.get<ApiResponse<AccountAlert[]>>('accounts/customer-alerts');

      dispatch(slice.actions.setAlerts(response.data.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setFetching({ isFetching: false, abortController: abortController }));
    }
  };
}

export function clearCustomerAlerts() {
  return async (dispatch: any) => {
    try {
      dispatch(slice.actions.setAlerts([]));
    } catch (error) {
      console.error(error);
    }
  };
}
