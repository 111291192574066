import { PaginationState, SortByColumn } from 'types/data-tables';
import { Address, Country, DateFilter, LookupItem } from 'types/api';
import { ProductStockDto } from './products';

export interface SalesOrderSummary {
  header: SalesOrderHeader;
  lines: SalesOrderLine[];
}

export interface SalesOrderLine {
  id: number;
  name: string;
  stockCode: string;
  quantity: number;
  unitPrice: number;
  net: number;
  tax: number;
  gross: number;
  unitDiscount: number;
  mpn: string;
  ean: string;
  salesOrderNumber: number;
  lineNumber: number;
  customerSku: string;
  serialNumbers: string[];
  ordered: number;
  allocated: number;
  invoiced: number;
  delivered: number;
  isDeliveryLine: boolean;
  isCancelled: boolean;
  grade: StockGrade;
  gradeDescription: string;
}

export interface SalesOrderHeader {
  id: number;
  customerCode: string;
  number: number;
  net: number;
  tax: number;
  gross: number;
  currencySymbol: string;
  customerRef: string;
  customerRef2: string;
  customerRef3: string;
  deliveryAddressId: number;
  invoiceAddressId: number;
  lines: SalesOrderLine[];
  deliveryAddress: Address;
  invoiceAddress: Address;
  createdAt: Date;
  createdBy: string;
  despatchOn: Date;
  publicStatus: SalesOrderPublicStatus;
  publicStatusDescription: string;
  deliveryOption: DeliveryOption;
  deliveryNotes: string;
  sourceDescription: string;
  shipType: string;
  customData: string;
}

export interface DeliveryOption {
  carrierCode: string;
  serviceCode: string;
  serviceName: string;
  sellPrice: number;
  sellCurrency: string;
  postcodeOnly: string;
  group: string;
  displayNameWithoutSellPrice: string;
  displayNameWithSellPrice: string;
  displayNameSimple: string;
  key: string;
  isTracked: boolean;
  isOverride: boolean;
  isReturn: boolean;
  helpText: string;
}

export interface SalesOrdersSnapshot {
  openOrders: OpenOrdersStatusSnapshot;
  statuses: SalesOrderStatusSnapshot;
}

export interface OpenOrdersStatusSnapshot {
  openOrders: number;
  openOrdersValue: number;
  backOrders: number;
  backOrdersValue: number;
  currencySymbol: string;
}

export interface SalesOrderStatusSnapshot {
  total: number;
  items: SalesOrderStatusSnapshotItem[];
}

export interface SalesOrderStatusSnapshotItem {
  status: SalesOrderPublicStatus;
  description: string;
  percentage: number;
  total: number;
}

export interface SalesOrderFilter {
  entityType: LookupItem;
  entities?: string;
  status: LookupItem | null;
  dateFilter: DateFilter;
  fromDate: string;
  toDate: string;
  pagination: PaginationState;
  sortBy?: SortByColumn;
}

export interface SalesOrderDateFilterType {
  type: SalesOrderFilterDateType;
  displayValue: string;
  fromDisplayValue: string;
  hasFromDate: boolean;
  toDisplayValue: string;
  hasToDate: boolean;
}

export interface SalesOrderFilterOptions {
  statuses: LookupItem[];
  dateFilterTypes: DateFilter[];
  entityTypes: LookupItem[];
}

export interface SalesOrderSummary {
  header: SalesOrderHeader;
  lines: SalesOrderLine[];
}

export interface SaveSalesOrderDraftLineDto {
  stockCode: string;
  quantity: number;
  stockGrade: StockGrade | null;
}

export interface SaveSalesOrderDraftDto {
  accountCode: string;
  existingDraftId: number | null;
  deliveryOptionKey: string;
  customerRef: string;
  customerRef2: string;
  customerRef3: string;
  despatchOn: Date | null;
  deliveryAddress: Address | null;
  invoiceAddress: Address | null;
  fullShip: boolean | null;
  deliveryNotes: string;
  warehouseNotes: string;
  whiteLabelled: boolean;
  lines: SaveSalesOrderDraftLineDto[];
}

export interface SalesOrderDraftDto {
  id: number | null;
  customerRef: string;
  customerRef2: string;
  customerRef3: string;
  despatchOn: Date | null;
  deliveryAddress: Address;
  invoiceAddress: Address;
  deliveryOptionKey: string;
  fullShip: boolean;
  deliveryNotes: string;
  warehouseNotes: string;
  whiteLabelled: boolean;
  vatCode: string;
  vatDescription: string;
  products: DraftProductDto[];
  orderDate?: Date | null;
  createdBy?: string;
  orderSourceDescription?: string;
}

export interface DraftProductDto {
  productDetails: ProductStockDto;
  quantity: number;
  totalPrice: number | null;
  currencySymbol: string;
  grade: StockGrade | null;
}

export interface SalesOrderDraftTotalsDto {
  isPOA: boolean;
  subTotal: number;
  tax: number;
  taxRate: number;
  total: number;
  taxDescription: string;
  currencySymbol: string;
  maxDimensionInCm: number;
  approximateWeightInKg: number;
}

export interface SalesOrderCreateOptionsDto {
  countries: Country[];
  defaultInvoiceAddress: Address;
  autoSetWhiteLabel: boolean;
}

export interface SalesOrderImportOptionDto {
  mappingId: number;
  name: string;
  description: string;
  templateUrl: string;
  fileType: string;
}

export enum SalesOrderImportHistoryItemStatus {
  Success = 0,
  PartialFailure = 1,
  Failure = 2,
  Processing = 98,
  Unknown = 99
}

export interface SalesOrderImportHistoryItemDto {
  status: SalesOrderImportHistoryItemStatus;
  fileName: string;
  message: string;
  date: Date;
}

export interface SalesOrderFileImportDto {
  mappingId: number;
  fileName: string;
  fileBlob: File;
}

export enum StockGrade {
  New = 100,
  NewPackaging = 150,
  NewCosmetics = 175,
  ManufacturerRefurbished = 200,
  ManufacturerRefurbishedCosmetics = 250,
  Used = 300,
  UsedLimitedFunctions = 325,
  UsedPackaging = 350,
  UsedCosmetics = 375,
  ForPartsNotWorking = 700
}

export enum SalesOrderPublicStatus {
  Processing = 0,
  Held = 1,
  BackOrder = 2,
  Completed = 3,
  Cancelled = 4,
  Scheduled = 5
}

export enum SalesOrderFilterEntityType {
  CustomerReference = 1,
  OrderNumber = 2
}

export enum SalesOrderFilterDateType {
  Created = 1
}

export const getSalesOrderStatusColour = (status: SalesOrderPublicStatus) => {
  switch (status) {
    case SalesOrderPublicStatus.Processing:
      return 'secondary';
    case SalesOrderPublicStatus.BackOrder:
    case SalesOrderPublicStatus.Scheduled:
      return 'info';
    case SalesOrderPublicStatus.Held:
      return 'warning';
    case SalesOrderPublicStatus.Completed:
      return 'success';
    default:
      return 'error';
  }
};

export const mapToSaveDraft = (customerCode: string, draft: SalesOrderDraftDto) => {
  const lines: SaveSalesOrderDraftLineDto[] = draft.products?.map((product) => mapToSaveDraftProduct(product)) ?? [];

  const dto: SaveSalesOrderDraftDto = {
    accountCode: customerCode,
    existingDraftId: draft.id,
    deliveryOptionKey: draft.deliveryOptionKey,
    customerRef: draft.customerRef,
    customerRef2: draft.customerRef2,
    customerRef3: draft.customerRef3,
    despatchOn: draft.despatchOn,
    deliveryAddress: draft.deliveryAddress,
    invoiceAddress: draft.invoiceAddress,
    fullShip: draft.fullShip,
    deliveryNotes: draft.deliveryNotes,
    warehouseNotes: draft.warehouseNotes,
    whiteLabelled: draft.whiteLabelled,
    lines: lines
  };

  return dto;
};

export const mapToSaveDraftProduct = (draftProduct: DraftProductDto) => {
  return {
    stockCode: draftProduct.productDetails.product.sku,
    quantity: draftProduct.quantity,
    stockGrade: draftProduct.grade
  };
};
