import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dispatch } from 'store';
import { ApiResponse } from 'types/api';
import { SalesInvoiceSummary } from 'types/pages/sales-invoices';
import axiosServices from 'utils/api/axiosServices';
import { openSnackbar } from '../snackbar';
import { RelatedSalesEntities } from 'types/related-entities';

export interface SalesInvoiceSummaryProps {
  isSummaryFetching: boolean;
  summary: SalesInvoiceSummary | null;
  related: RelatedSalesEntities | null;
  isRelatedFetching: boolean;
  isActionWorking: boolean;
}

const initialState: SalesInvoiceSummaryProps = {
  isSummaryFetching: false,
  summary: null,
  related: null,
  isRelatedFetching: false,
  isActionWorking: false
};

const slice = createSlice({
  name: 'salesInvoiceSummary',
  initialState: { ...initialState },
  reducers: {
    setSummaryFetching(state, action: PayloadAction<boolean>) {
      state.isSummaryFetching = action.payload;
    },
    setActionWorking(state, action: PayloadAction<boolean>) {
      state.isActionWorking = action.payload;
    },
    setSummary(state, action: PayloadAction<SalesInvoiceSummary>) {
      state.summary = action.payload;
    },
    setRelated(state, action: PayloadAction<RelatedSalesEntities>) {
      state.related = action.payload;
    },
    setRelatedFetching(state, action: PayloadAction<boolean>) {
      state.isRelatedFetching = action.payload;
    },
    resetSalesInvoiceSummary(state) {
      state.summary = null;
      state.isSummaryFetching = false;
      state.related = null;
      state.isRelatedFetching = false;
    }
  }
});

export default slice.reducer;
export const { resetSalesInvoiceSummary } = slice.actions;

export function getSalesInvoiceSummary(transactionId: number) {
  return async () => {
    try {
      dispatch(slice.actions.setSummaryFetching(true));

      const summaryResponse = await axiosServices.get<ApiResponse<SalesInvoiceSummary>>(`sales-invoice-summary/${transactionId}`);

      dispatch(slice.actions.setSummary(summaryResponse.data.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setSummaryFetching(false));
    }
  };
}

export function getRelatedSalesEntities(transactionId: number) {
  return async () => {
    try {
      dispatch(slice.actions.setRelatedFetching(true));

      const relatedResponse = await axiosServices.get<ApiResponse<RelatedSalesEntities>>(`sales-invoices/${transactionId}/related`);

      dispatch(slice.actions.setRelated(relatedResponse.data.data));
    } catch (error) {
    } finally {
      dispatch(slice.actions.setRelatedFetching(false));
    }
  };
}

export function sendInvoiceEmail(transactionId: number) {
  return async () => {
    try {
      dispatch(slice.actions.setActionWorking(true));

      await axiosServices.post(`sales-invoices/${transactionId}/queue-invoice-email`);

      dispatch(
        openSnackbar({
          open: true,
          message: 'Your request is being processed. You should receive your exported invoice by email in a minute or two.',
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setActionWorking(false));
    }
  };
}
