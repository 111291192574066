import { IPublicClientApplication } from '@azure/msal-browser';
import axios, { AxiosInstance } from 'axios';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import addAzureB2cTokenInterceptor from './addAzureB2cTokenInterceptor';
import { IAccountInContextProps } from 'store/reducers/accountInContext';

let store: any = null;

const axiosServices = axios.create({
  baseURL: window.__RUNTIME_CONFIG__.REACT_APP_API_BASE_URL
});

// TODO : Look at proper store typing
export const configureAxiosServices = (msalInstance: IPublicClientApplication, storeParam: any) => {
  store = storeParam;

  addAzureB2cTokenInterceptor(axiosServices, msalInstance);
  addErrorHandlerInterceptor(axiosServices);
  addAccountHeadersInterceptor(axiosServices);

  return axiosServices;
};

const addErrorHandlerInterceptor = (axiosServices: AxiosInstance) => {
  axiosServices.interceptors.response.use(
    (response) => response,
    (error) => {
      if (axios.isCancel(error)) {
        return Promise.reject(error);
      }

      if (error.response) {
        showSnackbarError(error.response.data.errors?.join(', '));
      } else if (error.request) {
        showSnackbarError(error.message);
      } else {
        showSnackbarError(error.message);
      }

      console.error(error);

      return Promise.reject(error.response || 'A network error has occurred. Check your internet connection and try again.');
    }
  );
};

// Make sure the account code / account type headers are added
const addAccountHeadersInterceptor = (axiosServices: AxiosInstance) => {
  axiosServices.interceptors.request.use((config: any) => {
    const accountInContext = store.getState().accountInContext as IAccountInContextProps;
    const account = accountInContext.accountRole?.account;

    if (!account) {
      return config;
    }
    config.headers = {
      ...config.headers,
      accountCode: account.accountCode,
      accountTypeId: account.accountTypeId
    };

    return config;
  });
};

const showSnackbarError = (message: string) => {
  dispatch(
    openSnackbar({
      open: true,
      message: message,
      variant: 'alert',
      alert: {
        color: 'error'
      },
      close: true
    })
  );
};

export default axiosServices;
