import { AccountSelectItem } from './AccountSelectItem';
import { useSelector } from 'store';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useNavigate } from 'react-router';
import Routes from 'routes/RouteNames';
import useActiveAccount from 'hooks/useActiveAccount';
import { formatDashboardBaseUrl } from 'utils/urlUtils';

export const CustomerAccountSelector: React.FC = () => {
  const settings = useSelector((state) => state.userSettings);
  const activeAccount = useActiveAccount();
  const navigate = useNavigate();

  const accountChangedHandler = (event: SelectChangeEvent<string>) => {
    const matchingAccount = settings.accountRoles.find((role) => role.account.key === event.target.value);

    if (matchingAccount) {
      const baseUrl = formatDashboardBaseUrl(matchingAccount.account.accountCode, matchingAccount.account.accountType);

      navigate(`${baseUrl}${Routes.Home}`);
    }
  };

  if (!settings) return <div>Loading...</div>;

  return (
    <FormControl>
      <Select
        sx={{
          width: { xs: 350 }
        }}
        variant={!activeAccount ? 'outlined' : 'standard'}
        disableUnderline={true}
        size="small"
        value={activeAccount?.key ?? ''}
        onChange={accountChangedHandler}
      >
        {settings.accountRoles?.map((option) => (
          <MenuItem key={option.account.key} value={option.account.key}>
            <AccountSelectItem account={option.account} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
