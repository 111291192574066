import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dispatch, RootState } from 'store';
import { ApiResponse } from 'types/api';
import { ReportDetail, ReportList, ReportListFilter, ReportListFilterOptions } from 'types/pages/reports';
import axiosServices from 'utils/api/axiosServices';

export interface ReportsProps {
  isLoading: boolean;
  isLoadingOptions: boolean;
  data: ReportList | null;
  filteredItems: ReportDetail[];
  filterOptions: ReportListFilterOptions | null;
  filter: ReportListFilter;
}

export const reportsInitialState: ReportsProps = {
  isLoading: false,
  isLoadingOptions: false,
  data: null,
  filterOptions: null,
  filteredItems: [],
  filter: {
    selectedCategory: null,
    titleFilter: null
  }
};

const slice = createSlice({
  name: 'reports',
  initialState: { ...reportsInitialState },
  reducers: {
    setData(state, action: PayloadAction<ReportList>) {
      state.data = action.payload;
    },
    setFilteredItems(state, action: PayloadAction<ReportDetail[]>) {
      state.filteredItems = action.payload;
    },
    setReportsFilter(state, action: PayloadAction<ReportListFilter>) {
      state.filter = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setLoadingOptions(state, action: PayloadAction<boolean>) {
      state.isLoadingOptions = action.payload;
    },
    setFilterOptions(state, action: PayloadAction<ReportListFilterOptions>) {
      state.filterOptions = action.payload;
    },
    resetReports(state) {
      state.isLoading = reportsInitialState.isLoading;
      state.isLoadingOptions = reportsInitialState.isLoadingOptions;
      state.filter = reportsInitialState.filter;
      state.filteredItems = reportsInitialState.filteredItems;
      state.data = reportsInitialState.data;
      state.filterOptions = reportsInitialState.filterOptions;
    }
  }
});

export default slice.reducer;
export const { resetReports, setReportsFilter, setFilteredItems } = slice.actions;

export function loadReports() {
  return async () => {
    try {
      dispatch(slice.actions.setLoading(true));

      const response = await axiosServices.get<ApiResponse<ReportList>>('reports/list');

      dispatch(slice.actions.setData(response.data.data));
      dispatch(slice.actions.setFilteredItems(response.data.data?.reports ?? []));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };
}

export function getFilteredReports() {
  return (dispatch: any, getState: () => RootState) => {
    try {
      const currentState = getState();
      const filter = currentState?.reports?.filter;
      const allReports = currentState?.reports?.data?.reports;

      const matchesFilter = (report: ReportDetail) => {
        return (
          (!filter.titleFilter || report.title.toLowerCase().includes(filter.titleFilter?.toLowerCase() ?? '')) &&
          (!filter.selectedCategory || report.categoryId === filter.selectedCategory.value)
        );
      };

      if (filter.selectedCategory || filter.titleFilter) {
        let items = allReports?.filter((r) => matchesFilter(r)) ?? [];
        dispatch(setFilteredItems(items));
        return;
      }

      dispatch(setFilteredItems(currentState.reports.data?.reports ?? []));
    } catch (error) {
      console.error(error);
    }
  };
}

export function loadReportFilterOptions() {
  return async () => {
    try {
      dispatch(slice.actions.setLoading(true));

      const response = await axiosServices.get<ApiResponse<ReportListFilterOptions>>('reports/list/filter-options');

      dispatch(slice.actions.setFilterOptions(response.data.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };
}

export function clearReportsFilter() {
  return async () => {
    try {
      dispatch(setReportsFilter({ ...reportsInitialState.filter }));
      dispatch(getFilteredReports());
    } catch (error) {
      console.error(error);
    }
  };
}
