import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { dispatch } from 'store';
import { ApiResponse } from 'types/api';
import { SalesReturnSummary } from 'types/pages/sales-returns';
import { RelatedSalesEntities } from 'types/related-entities';
import axiosServices from 'utils/api/axiosServices';

export interface SalesReturnDetailsProps {
  isSummaryFetching: boolean;
  summary: SalesReturnSummary | null;
  isActionWorking: boolean;
  related: RelatedSalesEntities | null;
  isRelatedFetching: boolean;
}

const initialState: SalesReturnDetailsProps = {
  isSummaryFetching: false,
  summary: null,
  isActionWorking: false,
  related: null,
  isRelatedFetching: false
};

const slice = createSlice({
  name: 'salesReturnDetails',
  initialState: { ...initialState },
  reducers: {
    setSummaryFetching(state, action: PayloadAction<boolean>) {
      state.isSummaryFetching = action.payload;
    },
    setActionWorking(state, action: PayloadAction<boolean>) {
      state.isActionWorking = action.payload;
    },
    setSummary(state, action: PayloadAction<SalesReturnSummary>) {
      state.summary = action.payload;
    },
    setRelated(state, action: PayloadAction<RelatedSalesEntities>) {
      state.related = action.payload;
    },
    setRelatedFetching(state, action: PayloadAction<boolean>) {
      state.isRelatedFetching = action.payload;
    },
    resetSalesReturnSummary(state) {
      state.summary = null;
      state.isSummaryFetching = false;
      state.related = null;
      state.isRelatedFetching = false;
    }
  }
});

export default slice.reducer;
export const { resetSalesReturnSummary } = slice.actions;

export function loadRelatedSalesEntities(returnId: number) {
  return async () => {
    try {
      dispatch(slice.actions.setRelatedFetching(true));

      const relatedResponse = await axiosServices.get<ApiResponse<RelatedSalesEntities>>(`sales-returns/${returnId}/related`);

      dispatch(slice.actions.setRelated(relatedResponse.data.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setRelatedFetching(false));
    }
  };
}

export function loadReturnSummary(returnId: number) {
  return async () => {
    try {
      dispatch(slice.actions.setSummaryFetching(true));

      const summaryResponse = await axiosServices.get<ApiResponse<SalesReturnSummary>>(`sales-returns/${returnId}`);

      dispatch(slice.actions.setSummary(summaryResponse.data.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setSummaryFetching(false));
    }
  };
}
