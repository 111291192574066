import { Button } from '@mui/material';
import { useSelector } from 'store';
import TrueLayerPaymentDialog from './TrueLayerPaymentDialog';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
export interface MakeATrueLayerPaymentButtonProps {
  returnLocation: string;
  fullAmount: number;
  dueAmount: number;
  open: boolean;
  onClicked: () => void;
  onCompleted: () => void;
  onClose: () => void;
}

const MakeATrueLayerPaymentButton = (props: MakeATrueLayerPaymentButtonProps) => {
  const state = useSelector((state) => state.payments);

  return (
    <>
      <Button
        id="make-a-payment-button"
        color="primary"
        variant="dashed"
        disabled={state.isCreatingPayment}
        onClick={props.onClicked}
        startIcon={<LockTwoToneIcon color="warning" />}
        disableRipple
      >
        Make a Payment
      </Button>

      {props.open && (
        <TrueLayerPaymentDialog
          open={props.open}
          onCompleted={props.onCompleted}
          fullAmount={props.fullAmount}
          dueAmount={props.dueAmount}
          returnLocation={props.returnLocation}
          onClose={props.onClose}
        />
      )}
    </>
  );
};

export default MakeATrueLayerPaymentButton;
