import { Grid, Stack, Typography, Skeleton } from '@mui/material';
import MainCard from 'components/MainCard';
import TableLoadingSkeleton from 'components/table-loading-skeleton/TableLoadingSkeleton';

const MarketingDetailSkeleton = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={3}>
        <Skeleton variant="rectangular" height="150px" />
      </Grid>
      <Grid item xs={12} sm={9}>
        <Skeleton variant="text" width="200px" height="50px" />
        <Skeleton variant="text" width="250px" />
        <Skeleton variant="text" height="75px" />
      </Grid>
      <Grid item xs={12}>
        <MainCard
          content={false}
          title={
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack>
                <Typography variant="h5">Files</Typography>
                <Typography variant="body2" color="secondary">
                  Click the files below to download
                </Typography>
              </Stack>
            </Stack>
          }
        >
          <Stack spacing={1} m={1}>
            <TableLoadingSkeleton rows={5} />
          </Stack>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default MarketingDetailSkeleton;
