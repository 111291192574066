export interface RouteNamesType {
  Home: string;
  Invoices: string;
  Orders: string;
  OrderDrafts: string;
  OrderImport: string;
  CreateOrder: string;
  Deliveries: string;
  Returns: string;
  Products: string;
  Promotions: string;
  Marketing: string;
  Feeds: string;
  InboundIntegrations: string;
  OutboundIntegrations: string;
  IntegrationEvents: string;
  Reports: string;
  Team: string;
  Account: string;
}

const Routes: RouteNamesType = {
  Home: 'home',
  Invoices: 'invoices',
  Orders: 'orders',
  OrderDrafts: 'orders/drafts',
  CreateOrder: 'orders/create',
  OrderImport: 'orders/import',
  Deliveries: 'deliveries',
  Returns: 'returns',
  Products: 'products',
  Promotions: 'promotions',
  Marketing: 'marketing',
  Feeds: 'feeds',
  InboundIntegrations: 'inbound-integrations',
  OutboundIntegrations: 'outbound-integrations',
  IntegrationEvents: 'integration-events',
  Reports: 'reports',
  Team: 'team',
  Account: 'account'
};

export default Routes;
