import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LightTooltip from 'components/LightToolTip';

export interface TooltipIconProps {
  message: string | JSX.Element;
  size?: 'small' | 'medium' | 'large' | 'inherit';
  color?: 'primary' | 'secondary' | 'action' | 'error' | 'disabled' | 'info' | 'success' | 'warning' | 'inherit';
}

const TooltipIcon = (props: TooltipIconProps) => {
  return (
    <LightTooltip title={props.message}>
      <InfoOutlinedIcon color={props.color ?? 'secondary'} fontSize={props.size ?? 'small'} />
    </LightTooltip>
  );
};

export default TooltipIcon;
