import { useSelector } from 'react-redux';

// material-ui
import { Box, Typography } from '@mui/material';

// types
import { RootStateProps } from 'types/root';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { filterMenuItems } from './navigation-fns';
import { RootState } from 'store';
import useActivePermissionLevel from 'hooks/useActivePermissionLevel';
import useEnv from 'hooks/useEnv';
import React from 'react';
import useActiveAccount from 'hooks/useActiveAccount';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const menu = useSelector((state: RootStateProps) => state.menu);
  const features = useSelector((state: RootState) => state.features);
  const userAccountRole = useActivePermissionLevel();
  const activeAccount = useActiveAccount();
  const env = useEnv();

  const { drawerOpen } = menu;

  const navGroups = React.useMemo(() => {
    return menuItem.items
      ?.filter((menuItem) => filterMenuItems(menuItem, activeAccount, userAccountRole, features.features, env))
      .map((item) => {
        switch (item.type) {
          case 'group':
            return <NavGroup key={item.id} item={item} />;
          default:
            return (
              <Typography key={item.id} variant="h6" color="error" align="center">
                Fix - Navigation Group
              </Typography>
            );
        }
      });
  }, [activeAccount, userAccountRole, features.features, env]);

  return <Box sx={{ pt: drawerOpen ? 2 : 0, '& > ul:first-of-type': { mt: 0 } }}>{navGroups}</Box>;
};

export default Navigation;
