import { Typography } from '@mui/material';
import { formatMoney } from 'utils/stringUtils';

export interface CurrencyFieldProps {
  value: number;
  symbol: string;
  fontVariant?: string; // TODO : Type properly
}

const CurrencyField: React.FC<CurrencyFieldProps> = (props) => {
  //@ts-ignore
  return <Typography variant={props.fontVariant ?? 'body1'}>{formatMoney(props.symbol, props.value)}</Typography>;
};

export default CurrencyField;
