import useActiveAccount from 'hooks/useActiveAccount';
import useDocumentTitle from 'hooks/useDocumentTitle';
import UnderConstruction from 'pages/maintenance/under-construction';
import { AccountType } from 'types/account';
import SalesOrderImportPage from './sales-order-import/sales-order-import';

const OrderImportPage = () => {
  useDocumentTitle('Order Import');
  const activeAccount = useActiveAccount();

  return activeAccount?.accountTypeId === AccountType.Customer ? <SalesOrderImportPage /> : <UnderConstruction />;
};

export default OrderImportPage;
