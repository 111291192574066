export interface ApiResponse<T> {
  statusCode: number;
  data: T;
  errors: string[] | null | undefined;
  hasErrors: boolean;
}

export interface ApiResponseNoData {
  statusCode: number;
  errors: string[] | null | undefined;
  hasErrors: boolean;
}

export interface PagedList<T> {
  items: T[];
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
}

export interface LookupItem {
  value: string;
  displayValue: string;
  description?: string;
}

export interface SimpleLookupItem<T> {
  value: T;
  displayValue: string;
}

export enum DateFilterType {
  Created = '1',
  Due = '2'
}

export interface DateRange {
  hasToDate: boolean;
  toDate?: Date;
  hasFromDate: boolean;
  fromDate?: boolean;
}

export interface DateFilter {
  type: DateFilterType;
  displayValue: string;
  fromDisplayValue?: string;
  hasFromDate: boolean;
  toDisplayValue?: string;
  hasToDate: boolean;
}

export interface CreateUserAccountLink {
  email: string;
  accountCode: string;
  accountTypeId: number;
  teamRoleId: number;
}

export interface CreateTeamUsers {
  accountLinks: CreateUserAccountLink[];
}

export interface editUserAccountLink {
  id: number;
  teamRoleId: number;
}

export interface EventSummary {
  entityId: number;
  type: CustomerEventType;
  eventTime: Date;
  description: string;
}

export enum CustomerEventType {
  Created = 1,
  Accepted = 2,
  Held = 3,
  Complete = 4,
  GoodsPicking = 11,
  GoodsPicked = 12,
  GoodsOutHeld = 13,
  GoodsPacked = 14,
  AwaitingDispatch = 21,
  InTransit = 22,
  Delivered = 23
}

export interface SortOptionDto {
  name: string;
  sortBy: string;
  sortDirection: string;
}

export interface Address {
  contact: string;
  customerCode: string;
  companyName: string;
  address: string;
  address1: string;
  address2: string;
  townOrCity: string;
  county: string;
  postcode: string;
  country: string;
  countryCode: string;
  phoneNumber: string;
  exportCode: string;
  emailAddress: string;
  emailCcAddress: string;
  vatNumber: string;
  whiteLabel: boolean;
  vatTwoDigits: string;
  forInvoice: boolean;
  forDelivery: boolean;
  forStatement: boolean;
  business?: boolean;
}

export interface ValidationFailure {
  propertyName: string;
  errorMessage: string;
}

export interface Country {
  code: string;
  name: string;
  deliveryTerms: string;
  deliveryNature: string;
  deliveryMethod: string;
  displayCode: string;
  displayName: string;
  vatPrefix: string;
  threeLetterIsoRegionName: string;
  twoLetterIsoRegionName: string;
  englishName: string;
}

export interface AccountSummaryDto {
  onStop: boolean;
  creditLimit: number;
  creditAvailable: number;
  currencySymbol: string;
}

export interface ValidatedResult<T> {
  result: T;
  validationFailures: ValidationFailure[];
  isValid: boolean;
}
