import { Close, PushPinOutlined } from '@mui/icons-material';
import { Button, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import IconButton from 'components/@extended/IconButton';
import LoadingButton from 'components/@extended/LoadingButton';
import CurrencyField from 'components/CurrencyField';
import LightTooltip from 'components/LightToolTip';
import ProductInfoColumn from 'components/columns/products/ProductInfoColumn';
import FormNumberInput from 'components/form-inputs/FormNumberInput';

import { DashboardTable } from 'components/third-party/DashboardTable';
import TooltipIcon from 'components/tooltip-icon/TooltipIcon';
import useDashboardNavigate from 'hooks/useDashboardNavigate';
import useEffectAfterMounted from 'hooks/useEffectAfterMount';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Routes from 'routes/RouteNames';
import { RootState, dispatch, useSelector } from 'store';
import { fetchSalesOrderDrafts } from 'store/reducers/pages/sales-order-drafts';
import { AddProductToOrder, addOrUpdatePinnedOrderProducts, fetchPinnedOrder } from 'store/reducers/pinned-order';

export interface PinnedOrderSummaryProps {
  onCheckout?: () => void;
  onClose?: () => void;
  onClear?: () => void;
}

const PinnedOrderSummary = (props: PinnedOrderSummaryProps) => {
  const pinnedOrderState = useSelector((state: RootState) => state.pinnedOrder);
  const draftsState = useSelector((state) => state.salesOrderDrafts);
  const navigate = useDashboardNavigate();

  const cartItemCount = pinnedOrderState?.pinnedOrder?.products?.length ?? 0;

  const total = pinnedOrderState?.pinnedOrder?.products.map((p) => p.totalPrice).reduce((t, val) => (t ?? 0) + (val ?? 0), 0);
  const symbol = pinnedOrderState?.pinnedOrder?.products?.[0]?.currencySymbol ?? '£';
  const hasPoA = pinnedOrderState?.pinnedOrder?.products?.some((p) => p.totalPrice === null);
  const updating = pinnedOrderState?.isUpdatingPinnedOrder || pinnedOrderState?.isFetchingPinnedOrder;

  const pinnedDraft = pinnedOrderState?.pinnedOrder;

  useEffect(() => {
    dispatch(fetchSalesOrderDrafts());
  }, []);

  const otherDraftsList = useMemo(
    () =>
      !!pinnedOrderState?.pinnedOrder
        ? [pinnedOrderState.pinnedOrder, ...draftsState.drafts?.filter((d) => d.id !== pinnedDraft?.id).slice(0, 4)]
        : draftsState.drafts?.slice(0, 5),
    [draftsState.drafts, pinnedDraft]
  );

  const debouncedUpdateQuantity = useCallback(
    debounce(async (value: AddProductToOrder[]) => {
      await dispatch(addOrUpdatePinnedOrderProducts(value, true));
    }, 250),
    []
  );

  const activeOrderColumns = useMemo(
    () => [
      {
        Header: 'Product',
        accessor: 'title',
        size: 1,
        sortable: false,
        disableSortBy: true,
        Cell: ({ row }: any) => <ProductInfoColumn productStock={row.original.productDetails} />
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        className: 'cell-center',
        size: 1,
        sortable: false,
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const [quantity, setQuantity] = useState<number>(row.original.quantity);

          useEffectAfterMounted(() => {
            debouncedUpdateQuantity([
              {
                itemToAdd: row.original.productDetails,
                quantity: quantity - row.original.quantity
              }
            ]);
          }, [quantity, debouncedUpdateQuantity]);

          return (
            <Stack direction="row" spacing={1} justifyContent="flex-start">
              <FormNumberInput
                disabled={pinnedOrderState.isUpdatingPinnedOrder}
                initialValue={quantity}
                name="quantity"
                onChange={async (e) => {
                  setQuantity(e.value);
                }}
              />
            </Stack>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pinnedOrderState]
  );

  const draftColumns = useMemo(
    () => [
      {
        accessor: 'id',
        Header: 'Draft Id',
        sortable: false,
        disableSortBy: true,
        Cell: ({ value, row }: any) => {
          return <Typography variant="body1">{value}</Typography>;
        }
      },
      {
        accessor: 'customerRef',
        Header: 'Reference 1',
        sortable: false,
        disableSortBy: true,
        Cell: ({ value, row }: any) => {
          return <Typography variant="body1">{value}</Typography>;
        }
      },
      {
        accessor: 'createdBy',
        Header: 'User',
        sortable: false,
        disableSortBy: true,
        Cell: ({ value, row }: any) => {
          return <Typography variant="body1">{value}</Typography>;
        }
      },
      {
        accessor: 'actions',

        sortable: false,
        disableSortBy: true,
        Cell: ({ value, row }: any) => {
          const isPinned = pinnedOrderState?.pinnedOrder?.id === row.original.id;

          return (
            <Stack direction="row" alignItems="center" justifyContent="center">
              <LightTooltip title={isPinned ? 'This draft is already pinned' : 'Pin this draft'}>
                <IconButton
                  shape="rounded"
                  variant={isPinned ? 'light' : 'text'}
                  color={isPinned ? 'success' : 'inherit'}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isPinned) return;
                    dispatch(fetchPinnedOrder(row.original.id));
                  }}
                >
                  {!updating && <PushPinOutlined fontSize="small" />}
                  {updating && <CircularProgress size={20} />}
                </IconButton>
              </LightTooltip>
            </Stack>
          );
        }
      }
    ],
    [pinnedOrderState]
  );

  return (
    <Stack direction="column" p={2} spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Typography variant="h5">Your pinned order</Typography>
          <Stack direction="row" spacing={1} justifyContent="space-between" width="100%">
            <Typography variant="subtitle1">Draft #{pinnedDraft?.id}</Typography>
          </Stack>
        </Stack>

        <Stack alignItems="flex-start">
          <IconButton color="secondary" variant="text" onClick={props.onClose}>
            <Close />
          </IconButton>
        </Stack>
      </Stack>
      {cartItemCount === 0 && <Divider />}

      {cartItemCount === 0 && !pinnedOrderState.isFetchingPinnedOrder && (
        <Stack width="100%" justifyContent="center" alignItems="center" p={3}>
          <Typography variant="subtitle1">No items in your order</Typography>
        </Stack>
      )}
      {pinnedOrderState.isFetchingPinnedOrder && (
        <Stack width="100%" justifyContent="center" alignItems="center" p={3} spacing={3}>
          <Typography variant="subtitle1">Loading order...</Typography>
          <CircularProgress />
        </Stack>
      )}

      {cartItemCount > 0 && (
        <DashboardTable
          columns={activeOrderColumns}
          rows={pinnedOrderState?.pinnedOrder?.products ?? []}
          isFetching={pinnedOrderState?.isFetchingPinnedOrder || pinnedOrderState?.isUpdatingPinnedOrder}
          loadingText="Loading..."
        />
      )}

      <Divider />

      <Stack direction="row" justifyContent="flex-end" spacing={5} pr={3} alignItems="center">
        <Stack>
          <Typography variant="subtitle1">Total</Typography>
          <Typography variant="subtitle2" color="secondary">
            excl. VAT & Shipping
          </Typography>
        </Stack>

        {hasPoA && (
          <Stack direction="row" spacing={1}>
            <Typography variant="subtitle1">{`${symbol}POA`}</Typography>
            <TooltipIcon message="Price on application" />
          </Stack>
        )}

        {updating && <CircularProgress size={15} />}
        {!hasPoA && !updating && <CurrencyField fontVariant="subtitle1" value={total ?? 0} symbol={symbol} />}
      </Stack>

      <Stack direction="row" justifyContent="space-between" spacing={1} py={1}>
        <Stack direction="row" spacing={1}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              navigate(Routes.OrderDrafts);
              props.onClose?.();
            }}
          >
            View all drafts
          </Button>

          <Button
            variant="contained"
            color="success"
            onClick={() => {
              navigate(Routes.Products);
              props.onClose?.();
            }}
          >
            Find more products
          </Button>
        </Stack>
        {cartItemCount > 0 && (
          <Stack direction="row" spacing={1}>
            <Button
              variant="outlined"
              color="warning"
              disabled={pinnedOrderState?.isUpdatingPinnedOrder || pinnedOrderState?.isFetchingPinnedOrder}
              onClick={() => props.onClear?.()}
            >
              Unpin
            </Button>
            <LoadingButton
              variant="contained"
              onClick={() => {
                props.onCheckout?.();
              }}
              loading={pinnedOrderState?.isUpdatingPinnedOrder || pinnedOrderState?.isFetchingPinnedOrder}
            >
              Complete order
            </LoadingButton>
          </Stack>
        )}
      </Stack>

      <Divider />

      <Stack direction="row" pt={2}>
        <Typography variant="subtitle1">Recently created drafts</Typography>
      </Stack>

      <Stack direction="row">
        <DashboardTable
          rows={otherDraftsList}
          columns={draftColumns}
          isFetching={draftsState.isFilterFetching}
          noResultsRegion={
            <Stack direction="column" alignItems="center" justifyContent="center" my={5}>
              <Typography variant="body1" color="secondary">
                No drafts have been created
              </Typography>
            </Stack>
          }
          onReset={() => {}}
        />
      </Stack>
    </Stack>
  );
};

export default PinnedOrderSummary;
