// project import
import { NavItemType } from 'types/menu';
import main from './main';
import admin from './admin';
import orders from './orders';
import products from './catalogue';
import integrations from './integrations';
import analytics from './analytics';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [main, orders, products, integrations, analytics, admin]
};

export default menuItems;
