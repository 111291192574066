import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dispatch } from 'store';
import { ApiResponse } from 'types/api';
import axiosServices from 'utils/api/axiosServices';

export interface HomePageProps {
  isLoading: boolean;
  reportUrl: string | null;
}

const initialState: HomePageProps = {
  isLoading: false,
  reportUrl: null
};

const slice = createSlice({
  name: 'homePage',
  initialState: { ...initialState },
  reducers: {
    setReportUrl(state, action: PayloadAction<string>) {
      state.reportUrl = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    resetHomePage(state) {
      state.isLoading = false;
      state.reportUrl = null;
    }
  }
});

export default slice.reducer;

export function loadHomePage() {
  return async () => {
    try {
      dispatch(slice.actions.setLoading(true));

      const response = await axiosServices.get<ApiResponse<string>>('reports/home');

      dispatch(slice.actions.setReportUrl(response.data.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };
}
