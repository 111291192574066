import { FileUpload } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { DragEvent, useRef, useState } from 'react';

export interface FileDragAndDropProps {
  onFileDrop: (files: File[]) => void;
  selectedFiles: File[] | null;
  disabled?: boolean;
  multiple?: boolean;
}

export function FileDragAndDrop(props: FileDragAndDropProps) {
  const [isOver, setIsOver] = useState(false);
  const fileRef = useRef(null);

  const onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    var files = props.multiple ? event.target.files : [event.target.files[0]];
    props.onFileDrop(files);
  };

  // Define the event handlers
  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOver(true);
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsOver(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    if (props.disabled) return;

    event.preventDefault();
    setIsOver(false);

    // Fetch the files
    const droppedFiles = Array.from(props.multiple ? event.dataTransfer.files : [event.dataTransfer.files[0]]);

    props.onFileDrop(droppedFiles);
  };

  const hasFiles = props.selectedFiles && props.selectedFiles.length > 0;

  return (
    <Stack
      py={2}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      justifyContent="center"
      alignItems="center"
      color={isOver ? 'primary' : 'secondary'}
      style={{
        border: '1px dotted',
        backgroundColor: isOver ? 'lightgray' : 'white'
      }}
    >
      {hasFiles && (
        <Stack alignItems="center" justifyContent="center" spacing={1}>
          <Typography variant="subtitle1">{props.multiple ? 'Selected files' : 'Selected file'} </Typography>
          <Typography>{props.selectedFiles?.map((f) => f.name).join(', ')}</Typography>
        </Stack>
      )}
      {!hasFiles && (
        <Stack spacing={1} justifyContent="center" alignItems="center">
          <Typography>Drag and drop files here or click browse</Typography>
        </Stack>
      )}
      <Stack width={150}>
        <Button
          disabled={props.disabled}
          variant="text"
          startIcon={<FileUpload />}
          onClick={() => {
            // @ts-ignore
            fileRef.current.click();
          }}
        >
          Browse
          <input type="file" multiple={props.multiple} style={{ display: 'none' }} ref={fileRef} onChange={onChangeFile} />
        </Button>
      </Stack>
    </Stack>
  );
}

export default FileDragAndDrop;
