import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dispatch } from 'store';
import { ApiResponse } from 'types/api';
import { WarehouseSnapshotDto } from 'types/snapshots/deliveriesSummarySnapshot';
import axiosServices from 'utils/api/axiosServices';

export interface WarehouseSummarySnapshotProps {
  isLoading: boolean;
  snapshot: WarehouseSnapshotDto | null;
}

const initialState: WarehouseSummarySnapshotProps = {
  isLoading: true,
  snapshot: null
};

const slice = createSlice({
  name: 'warehouseSummarySnapshot',
  initialState: { ...initialState },
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setSnapshotData(state, action: PayloadAction<WarehouseSnapshotDto>) {
      state.snapshot = action.payload;
    }
  }
});

export default slice.reducer;

export function loadWarehouseSummarySnapshot() {
  return async () => {
    try {
      dispatch(slice.actions.setLoading(true));

      const response = await axiosServices.get<ApiResponse<WarehouseSnapshotDto>>('customer-deliveries/warehouse-snapshot');

      dispatch(slice.actions.setSnapshotData(response.data.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };
}
