import { ReportProblemOutlined } from '@mui/icons-material';
import { Chip, Typography } from '@mui/material';

export interface DisputedChipProps {
  size?: 'small' | 'medium' | 'large';
}

const DisputedChip = (props: DisputedChipProps) => {
  return (
    <Chip
      variant="combined"
      size={props?.size ?? 'small'}
      label={<Typography variant="body1">Disputed</Typography>}
      color="error"
      icon={<ReportProblemOutlined />}
    />
  );
};

export default DisputedChip;
