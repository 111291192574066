import { useMsal } from '@azure/msal-react';
import { Grid } from '@mui/material';
import Loader from 'components/Loader';
import Logo from 'components/logo';
import { CREATE_SALES_ORDER_FEATURE } from 'featureConstants';
import useAuth from 'hooks/useAuth';
import LocalStorageKeys from 'localStorageKeys';
import GeneralError from 'pages/maintenance/general-error';
import { useEffect } from 'react';
import { dispatch, store, useSelector } from 'store';
import { loadFeatures } from 'store/reducers/features';
import { fetchPinnedOrder } from 'store/reducers/pinned-order';
import { fetchSettings } from 'store/reducers/user-settings';
import { UserSettingsProps } from 'types/userSettings';
import { configureAxiosServices } from 'utils/api/axiosServices';
import embedExternalScript from 'utils/embedExternalScript';
import { getLocalStorage, clearLocalStorage } from 'utils/localStorage';

interface AppInitialisationGuardProps {
  children: React.ReactNode | null;
}

// This acts as a guard for the rest of the application. It should ensure that the App component is never rendered until we are both logged in and have successfully retrieved the settings for the user from API.
const AppInitialisationGuard = (props: AppInitialisationGuardProps) => {
  const settings = useSelector<UserSettingsProps>((state) => state.userSettings);
  const msal = useMsal();

  const { isLoggedIn } = useAuth();

  const initialiseAppSettings = async () => {
    if (!isLoggedIn) return;

    await dispatch(fetchSettings());
    const features = (await dispatch(loadFeatures())) ?? [];

    const createSalesOrderFeatureEnabled = features
      .filter((f) => f.enabled)
      .map((f) => f.name)
      .includes(CREATE_SALES_ORDER_FEATURE);

    const activeDraftItem = getLocalStorage<number>(LocalStorageKeys.ActiveDraftId);

    if (activeDraftItem?.value && createSalesOrderFeatureEnabled) {
      let activeDraft = await dispatch(fetchPinnedOrder(activeDraftItem?.value));

      if (!activeDraft) {
        clearLocalStorage(LocalStorageKeys.ActiveDraftId);
      }
    }
  };

  useEffect(() => {
    configureAxiosServices(msal.instance, store);
    embedExternalScript('/gorgias-initialisation.js');
    embedExternalScript('/favicon-initialisation.js');
    embedExternalScript('/hotjar-initialisation.js');
    embedExternalScript('/datadog-initialisation.js');
    embedExternalScript('/prevent-ios-auto-zoom.js');
  }, []);

  // Once we're successfully logged in get the settings from the api and initialise the account in context
  useEffect(() => {
    initialiseAppSettings();
  }, [isLoggedIn]);

  if (settings.error) {
    return <GeneralError message={settings.errorMessage ?? 'Unknown error'}></GeneralError>;
  }

  // Don't render app until we have successfully retrieved the api settings.
  if (!settings.loaded || settings.fetching || !isLoggedIn) {
    return (
      <>
        <Loader></Loader>
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
          <Grid item xs={3}>
            <Logo
              isIcon={false}
              sx={{
                height: 35
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  // Render the the App!
  return props.children as React.ReactElement<any, any>;
};

export default AppInitialisationGuard;
