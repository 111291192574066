import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from 'store';
import { UserSettingsProps } from 'types/userSettings';
import axiosServices from 'utils/api/axiosServices';

const initialState: UserSettingsProps = {
  teamUserId: null,
  isEmployee: false,
  accountRoles: [],
  loaded: false,
  fetching: false,
  error: false
};

const userSettings = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    setUserSettings(state, action) {
      state.teamUserId = action.payload.teamUserId;
      state.isEmployee = action.payload.isEmployee;
      state.accountRoles = action.payload.accountRoles;
      state.error = false;
    },
    setSettingsLoaded(state) {
      state.fetching = false;
      state.loaded = true;
    },
    setFetching(state, action) {
      state.fetching = action.payload;
    },
    setError(state, action) {
      state.error = action.payload.error;
      state.errorMessage = action.payload.errorMessage;
    },
    resetUserSettings(state) {
      state.accountRoles = { ...initialState.accountRoles };
      state.error = initialState.error;
      state.errorMessage = initialState.errorMessage;
      state.fetching = initialState.fetching;
      state.isEmployee = initialState.isEmployee;
      state.loaded = initialState.loaded;
      state.teamUserId = initialState.teamUserId;
    }
  }
});

export default userSettings.reducer;
export const { setUserSettings, resetUserSettings, setSettingsLoaded } = userSettings.actions;

export function fetchSettings() {
  return async () => {
    try {
      await dispatch(userSettings.actions.setFetching(true));

      const response = await axiosServices.get('settings');

      await dispatch(userSettings.actions.setUserSettings(response.data.data));

      await dispatch(setSettingsLoaded());
    } catch (error: any) {
      if (error?.data?.hasErrors) {
        const errors: string[] = error?.data?.errors;

        dispatch(userSettings.actions.setError({ error: true, errorMessage: errors.join(', ') }));
      } else {
        dispatch(
          userSettings.actions.setError({
            error: true,
            errorMessage: 'A network error has occurred while connecting to the dashboard API. Check your network connection and try again.'
          })
        );
      }
    }
  };
}
