import { useEffect, useRef } from 'react';

const useEffectAfterMounted = (func: () => void, deps: any[]) => {
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) func();
    else mounted.current = true;
  }, deps);
};

export default useEffectAfterMounted;
