// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { HomeOutlined } from '@mui/icons-material';

// type
import { NavItemType } from 'types/menu';
import { MenuItemIds } from './menu-item-ids';
import Routes from 'routes/RouteNames';

// icons
const icons = {
  HomeOutlined
};

const main: NavItemType = {
  id: 'main',
  type: 'group',
  children: [
    {
      id: MenuItemIds.HomePageNavItemId,
      title: <FormattedMessage id={MenuItemIds.HomePageNavItemId} />,
      type: 'item',
      url: `/${Routes.Home}`,
      icon: icons.HomeOutlined,
      breadcrumbs: false
    }
  ]
};

export default main;
