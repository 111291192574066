import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

// tooltip
const LightTooltip = styled(({ className, ...props }: TooltipProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, ...rest } = props;

  return (
    <Tooltip {...rest} classes={{ popper: className }}>
      <span>{props.children}</span>
    </Tooltip>
  );
})(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.grey[50],
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[4],
    fontSize: 11
  }
}));
LightTooltip.displayName = 'LightTooltip';

export default LightTooltip;
