import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { dispatch } from 'store';
import { ApiResponse } from 'types/api';
import { ReturnsSnapshotDto } from 'types/snapshots/returnsSnapshot';
import axiosServices from 'utils/api/axiosServices';

export interface ReturnsSnapshotProps {
  isLoading: boolean;
  snapshot: ReturnsSnapshotDto | null;
}

const initialState: ReturnsSnapshotProps = {
  isLoading: true,
  snapshot: null
};

const slice = createSlice({
  name: 'returnsSnapshot',
  initialState: { ...initialState },
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setSnapshotData(state, action: PayloadAction<ReturnsSnapshotDto>) {
      state.snapshot = action.payload;
    }
  }
});

export default slice.reducer;

export function loadReturnsSnapshot(fromDate?: Date | string | null) {
  return async () => {
    try {
      dispatch(slice.actions.setLoading(true));

      const dateParam = !!fromDate ? dayjs(fromDate).toISOString() : null;

      const response = await axiosServices.get<ApiResponse<ReturnsSnapshotDto>>(`sales-returns/snapshot?fromDate=${dateParam}`);

      dispatch(slice.actions.setSnapshotData(response.data.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };
}
