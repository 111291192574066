import { Chip } from '@mui/material';

export interface BundledStatusChipProps {
  size: 'small' | 'medium';
  variant: 'filled' | 'outlined' | 'light' | 'combined';
}

const BundledStatusChip = (props: BundledStatusChipProps) => {
  return <Chip label="Bundle" color="info" variant={props.variant} size={props.size} />;
};

export default BundledStatusChip;
