// material-ui
import { Stack, Typography, useMediaQuery } from '@mui/material';
import useEnv from 'hooks/useEnv';

const hrefStyle = {
  textDecoration: 'none'
};

const Footer = () => {
  const env = useEnv();

  const xsAndDown = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <Stack
      direction={xsAndDown ? 'column' : 'row'}
      justifyContent="space-between"
      alignItems="center"
      sx={{ p: '24px 16px 0px', mt: 'auto' }}
      spacing={2}
    >
      <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
        <a href={env.REACT_APP_PRIVACY_PAGE_LINK as string} target="_blank" rel="noreferrer" style={hrefStyle}>
          <Typography variant="caption" color="textPrimary">
            Privacy
          </Typography>
        </a>
        <a href={env.REACT_APP_WEBSITE_TERMS_PAGE_LINK as string} target="_blank" rel="noreferrer" style={hrefStyle}>
          <Typography variant="caption" color="textPrimary">
            Website Terms
          </Typography>
        </a>
        <a href={env.REACT_APP_TOS_PAGE_LINK as string} target="_blank" rel="noreferrer" style={hrefStyle}>
          <Typography variant="caption" color="textPrimary">
            Terms of Sale
          </Typography>
        </a>
        <a href={env.REACT_APP_PURCHASE_TERMS_PAGE_LINK as string} target="_blank" rel="noreferrer" style={hrefStyle}>
          <Typography variant="caption" color="textPrimary">
            Terms of Purchase
          </Typography>
        </a>
      </Stack>
      <Typography variant="caption">&copy; All rights reserved</Typography>
    </Stack>
  );
};

export default Footer;
