import { Stack, Box, Badge } from '@mui/material';
import useActiveAccount from 'hooks/useActiveAccount';
import useAuth from 'hooks/useAuth';
import useEnv from 'hooks/useEnv';
import { useMemo } from 'react';
import { Account } from 'types/account';
import { AzureB2cContextType } from 'types/auth';
import { IMessage, NovuProvider, PopoverNotificationCenter } from '@novu/notification-center';
import { NotificationsTwoTone } from '@mui/icons-material';
import Avatar from 'components/@extended/Avatar';
import useDashboardNavigate from 'hooks/useDashboardNavigate';

const getNovuSubscriberId = (activeAccount: Account, auth: AzureB2cContextType) =>
  `${activeAccount!.accountType.toLowerCase()}-${activeAccount!.accountCode.toLocaleLowerCase()}-${auth.user?.email?.toLocaleLowerCase()}`;

const NotificationsButton = () => {
  const activeAccount = useActiveAccount();
  const env = useEnv();
  const auth = useAuth();
  const navigate = useDashboardNavigate();

  const subscriberId = useMemo(() => getNovuSubscriberId(activeAccount!, auth), [activeAccount, auth]);
  const applicationIdentifier = env.REACT_APP_NOVU_APP_ID;
  const novuApiUrl = env.REACT_APP_NOVU_API_URL;
  const novuWsUrl = env.REACT_APP_NOVU_WS_URL;

  const handleOnNotificationClick = (message: IMessage) => {
    if (message?.cta?.data?.url) {
      navigate(message.cta.data.url);
    }
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <NovuProvider
        subscriberId={subscriberId}
        applicationIdentifier={applicationIdentifier!}
        backendUrl={novuApiUrl!}
        socketUrl={novuWsUrl!}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{ p: 1, cursor: 'pointer', '&:hover': { bgcolor: 'secondary.lighter' }, borderRadius: 1 }}
        >
          <PopoverNotificationCenter colorScheme="light" onNotificationClick={handleOnNotificationClick}>
            {({ unseenCount }) => (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Badge badgeContent={unseenCount} showZero color={unseenCount ?? 0 <= 0 ? 'error' : 'secondary'}>
                  <Avatar alt="help" size="xs">
                    <NotificationsTwoTone color="primary" fontSize="small" />
                  </Avatar>
                </Badge>
              </Stack>
            )}
          </PopoverNotificationCenter>
        </Stack>
      </NovuProvider>
    </Box>
  );
};

export default NotificationsButton;
