// third-party
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector, TypedUseSelectorHook } from 'react-redux';

// project import
import reducers from './reducers';
import { resetReports } from './reducers/pages/reports';
import { resetTeamAdmin } from './reducers/pages/team-admin';
import { resetCreateSalesOrder } from './reducers/pages/create-sales-order';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false
});

const store = configureStore({
  reducer: reducers,
  middleware: customizedMiddleware
});

export type RootState = ReturnType<typeof reducers>;

export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

const useDispatch = () => useAppDispatch<AppDispatch>();
const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export { store, dispatch, useSelector, useDispatch };

// Reset any page state that isn't required after the account context has been changed.
export const resetPageState = (dispatch: AppDispatch) => {
  dispatch(resetTeamAdmin());
  dispatch(resetReports());
  dispatch(resetCreateSalesOrder());
};
