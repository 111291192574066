import useActivePermissionLevel from 'hooks/useActivePermissionLevel';
import { AccountPermissionLevel } from 'types/account';

export interface PermissionGuardProps {
  canAccess: AccountPermissionLevel[];
  children: React.ReactElement;
}

const PermissionGuard = (props: PermissionGuardProps) => {
  const accountRole = useActivePermissionLevel();

  if (accountRole === AccountPermissionLevel.Employee) return props.children;
  if (props.canAccess.includes(accountRole)) return props.children;

  return <></>;
};

export default PermissionGuard;
