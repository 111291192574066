import { Chip } from '@mui/material';

export interface ProposedStatusChipProps {
  size: 'small' | 'medium';
}

const ProposedStatusChip = (props: ProposedStatusChipProps) => {
  return <Chip label="Proposed" color="secondary" variant="light" size={props.size} />;
};

export default ProposedStatusChip;
