import { InputAdornment, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

export interface CurrencyInputChangeEvent {
  target: {
    name: string;
    value: string;
  };
}

export interface CurrencyInputProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  amount: number;
  label: string;
  currencySymbol?: string;
  disabled?: boolean;
  readonly?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  readOnlyFill: {
    '& .MuiOutlinedInput-root': {
      background: theme.palette.secondary.lighter
    }
  }
}));

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CurrencyInputProps>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      valueIsNumericString
    />
  );
});

const CurrencyInput = (props: CurrencyInputProps) => {
  const classes = useStyles();

  return (
    <TextField
      id="currency-input"
      size="small"
      value={props.amount}
      className={props.readonly ? classes.readOnlyFill : ''}
      InputProps={{
        inputComponent: NumericFormatCustom as any,
        startAdornment: <InputAdornment position="start">{props.currencySymbol ?? '£'}</InputAdornment>,
        readOnly: props.readonly
      }}
      onChange={(event) => props.onChange({ target: { name: props.name, value: event.target.value } })}
      disabled={props.disabled ?? false}
      label={!props.readonly ? props.label : null}
    />
  );
};

export default CurrencyInput;
