import { Stack, Typography } from '@mui/material';
import Avatar from 'components/@extended/Avatar';
import StockIdentifiers from 'components/stock-identifiers/StockIdentifiers';
import StockStatusChips from 'components/stock-status-chips/StockStatusChips';
import { ProductDto, ProductStockDto } from 'types/pages/products';

export interface ProductInfoColumnProps {
  productStock: ProductStockDto;
  quantity?: number;
  size?: 'small' | 'medium';
  hideStockStatus?: boolean;
}

const ProductInfoColumn = (props: ProductInfoColumnProps) => {
  const product = props.productStock.product as ProductDto;
  const productStock = props.productStock;

  return (
    <Stack direction="row" spacing={2.5} alignItems="start" width="100%">
      <Avatar
        variant="rounded"
        alt={product.title}
        color="secondary"
        size={props.size === 'small' ? 'sm' : 'md'}
        src={product.previewImage}
      />
      <Stack direction="column" width="100%">
        <Stack direction="row">
          <Typography variant={props.size === 'small' ? 'subtitle2' : 'subtitle1'}>{product.title}</Typography>
        </Stack>
        <Stack direction="row" sx={{ flexWrap: 'wrap', rowGap: 0, columnGap: 1 }}>
          <StockIdentifiers sku={product.sku} mpn={product.mpn} barcodeEan={product.barcodeEan} customerSku={product.customerSku} />
        </Stack>
        {props.hideStockStatus !== true && (
          <Stack direction="row" spacing={1}>
            <StockStatusChips size={props.size ?? 'small'} productStock={productStock} />
          </Stack>
        )}
      </Stack>
      {props.quantity && (
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          <Typography variant={props.size === 'small' ? 'body2' : 'body1'} noWrap>
            {props.quantity} item(s)
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default ProductInfoColumn;
