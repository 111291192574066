import { Breakpoint } from '@mui/system';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface AlertDialogProps {
  open: boolean;
  title: React.ReactElement | React.ReactNode;
  message: React.ReactElement | React.ReactNode;
  cancelButtonText?: string;
  cancelButtonColour?: string;
  confirmButtonText?: string;
  confirmButtonColour?: string;
  size?: false | Breakpoint | undefined;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const initialState: AlertDialogProps = {
  open: false,
  title: 'Placeholder title',
  message: 'Placeholder message',
  cancelButtonText: 'Cancel',
  confirmButtonText: 'Confirm',
  size: 'xs',
  onCancel: () => {}
};

const slice = createSlice({
  name: 'alertDialog',
  initialState,
  reducers: {
    openAlert(state, action: PayloadAction<AlertDialogProps>) {
      state.open = action.payload.open;
      state.title = action.payload.title;
      state.message = action.payload.message;
      state.cancelButtonText = action.payload.cancelButtonText;
      state.cancelButtonColour = action.payload.cancelButtonColour;
      state.confirmButtonText = action.payload.confirmButtonText;
      state.confirmButtonColour = action.payload.confirmButtonColour;
      state.size = action.payload.size ?? 'xs';
      state.onCancel = action.payload.onCancel;
      state.onConfirm = action.payload.onConfirm;
    },
    closeAlert(state) {
      state.open = false;
    }
  }
});

export default slice.reducer;
export const { openAlert, closeAlert } = slice.actions;
