import { HelpCenterOutlined } from '@mui/icons-material';
import { Box, ButtonBase, Stack, useTheme } from '@mui/material';
import Avatar from 'components/@extended/Avatar';
import useEnv from 'hooks/useEnv';

const HelpButton = () => {
  const theme = useTheme();

  const env = useEnv();

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <a href={env.REACT_APP_HELP_LINK} target="_blank" rel="noreferrer" style={{ color: 'inherit', textDecoration: 'inherit' }}>
        <ButtonBase
          sx={{
            p: 0.25,
            px: 1,
            borderRadius: 1,
            '&:hover': { bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter' },
            '&:focus-visible': {
              outline: `2px solid ${theme.palette.secondary.dark}`,
              outlineOffset: 2
            }
          }}
          aria-label="open help"
          aria-haspopup="false"
        >
          <Stack direction="row" spacing={1} alignItems="center" sx={{ p: 0.5 }}>
            <Avatar alt="help" size="xs">
              <HelpCenterOutlined fontSize="small" />
            </Avatar>
          </Stack>
        </ButtonBase>
      </a>
    </Box>
  );
};

export default HelpButton;
