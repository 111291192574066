// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { NotificationImportantOutlined, ForkLeftOutlined, ForkRightOutlined, ImportExportOutlined } from '@mui/icons-material';

// type
import { NavItemType } from 'types/menu';
import { AccountType } from 'types/account';
import { MenuItemIds } from './menu-item-ids';
import Routes from 'routes/RouteNames';
import { INTEGRATION_FEEDS_FEATURE } from 'featureConstants';

// icons
const icons = {
  NotificationImportantOutlined,
  ForkLeftOutlined,
  ForkRightOutlined,
  ImportExportOutlined
};

const orders: NavItemType = {
  id: MenuItemIds.IntegrationsGroupItemId,
  title: <FormattedMessage id={MenuItemIds.IntegrationsGroupItemId} />,
  type: 'group',
  children: [
    {
      id: MenuItemIds.IntegrationsFeedPageNavItemId,
      title: <FormattedMessage id={MenuItemIds.IntegrationsFeedPageNavItemId} />,
      type: 'item',
      url: `/${Routes.Feeds}`,
      icon: icons.ImportExportOutlined,
      underConstruction: [AccountType.Customer, AccountType.Supplier],
      breadcrumbs: false,
      featureFlag: INTEGRATION_FEEDS_FEATURE,
      accountType: AccountType.Customer
    },
    {
      id: MenuItemIds.IntegrationsInboundPageNavItemId,
      title: <FormattedMessage id={MenuItemIds.IntegrationsInboundPageNavItemId} />,
      type: 'item',
      url: `/${Routes.InboundIntegrations}`,
      icon: icons.ForkRightOutlined,
      underConstruction: [AccountType.Customer, AccountType.Supplier],
      breadcrumbs: false,
      featureFlag: INTEGRATION_FEEDS_FEATURE,
      accountType: AccountType.Customer
    },
    {
      id: MenuItemIds.IntegrationsOutboundPageNavItemId,
      title: <FormattedMessage id={MenuItemIds.IntegrationsOutboundPageNavItemId} />,
      type: 'item',
      url: `/${Routes.OutboundIntegrations}`,
      icon: icons.ForkLeftOutlined,
      underConstruction: [AccountType.Customer, AccountType.Supplier],
      breadcrumbs: false,
      featureFlag: INTEGRATION_FEEDS_FEATURE,
      accountType: AccountType.Customer
    },
    {
      id: MenuItemIds.IntegrationsEventsPageNavItemId,
      title: <FormattedMessage id={MenuItemIds.IntegrationsEventsPageNavItemId} />,
      type: 'item',
      url: `/${Routes.IntegrationEvents}`,
      icon: icons.NotificationImportantOutlined,
      underConstruction: [AccountType.Customer, AccountType.Supplier],
      breadcrumbs: false,
      featureFlag: INTEGRATION_FEEDS_FEATURE,
      accountType: AccountType.Customer
    }
  ]
};

export default orders;
