// material-ui
import { useSelector } from 'store';
import { CustomerAccountSelector } from './CustomerAccountSelector';
import { EmployeeAccountSearch } from './EmployeeAccountSearch';

// ==============================|| HEADER CONTENT - SEARCH ||============================== //

const AccountSelector = () => {
  const settings = useSelector((state) => state.userSettings);

  return <>{settings.isEmployee ? <EmployeeAccountSearch /> : <CustomerAccountSelector />}</>;
};

export default AccountSelector;
