import { AccountPermissionLevel } from 'types/account';

export const getSaveDraftPermission = (userAccountRole: AccountPermissionLevel) =>
  userAccountRole === AccountPermissionLevel.Admin ||
  userAccountRole === AccountPermissionLevel.Employee ||
  userAccountRole === AccountPermissionLevel.User;

export const getCreateSalesOrderPermission = (userAccountRole: AccountPermissionLevel) =>
  userAccountRole === AccountPermissionLevel.Admin ||
  userAccountRole === AccountPermissionLevel.Employee ||
  userAccountRole === AccountPermissionLevel.User;

export const getManageTeamsPermission = (userAccountRole: AccountPermissionLevel) =>
  userAccountRole === AccountPermissionLevel.Admin || userAccountRole === AccountPermissionLevel.Employee;

export const createSalesOrderPermissions = [AccountPermissionLevel.Admin, AccountPermissionLevel.Employee, AccountPermissionLevel.User];
