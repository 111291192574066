// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { PeopleOutlined } from '@mui/icons-material';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
// type
import { NavItemType } from 'types/menu';
import { AccountPermissionLevel, AccountType } from 'types/account';
import { MenuItemIds } from './menu-item-ids';
import Routes from 'routes/RouteNames';

// icons
const icons = {
  PeopleOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const admin: NavItemType = {
  id: MenuItemIds.AdminGroupItemId,
  title: <FormattedMessage id={MenuItemIds.AdminGroupItemId} />,
  type: 'group',
  children: [
    {
      id: MenuItemIds.TeamAdminPageNavItemId,
      title: <FormattedMessage id={MenuItemIds.TeamAdminPageNavItemId} />,
      type: 'item',
      url: `/${Routes.Team}`,
      icon: icons.PeopleOutlined,
      requiredAccountRole: AccountPermissionLevel.Admin,
      breadcrumbs: false
    },
    {
      id: MenuItemIds.AccountAdminPageNavItemId,
      title: <FormattedMessage id={MenuItemIds.AccountAdminPageNavItemId} />,
      type: 'item',
      url: `/${Routes.Account}`,
      icon: LocationCityOutlinedIcon,
      requiredAccountRole: AccountPermissionLevel.User,
      underConstruction: [AccountType.Supplier],
      breadcrumbs: false
    }
  ]
};

export default admin;
