import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { Account } from 'types/account';
import { getLocalStorage, setLocalStorage } from 'utils/localStorage';

interface RecentlyUsedAccountStore {
  account: Account;
  lastUsed: Date;
}

export interface RecentlyUsedAccountsProps {
  accounts: RecentlyUsedAccountStore[] | null;
}

const initialState: RecentlyUsedAccountsProps = {
  accounts: []
};

const recentlyUsedAccounts = createSlice({
  name: 'recentlyUsedAccounts',
  initialState,
  reducers: {
    setRecentlyUsedAccounts(state, action: PayloadAction<RecentlyUsedAccountStore[] | null>) {
      state.accounts = action.payload;
    }
  }
});

export default recentlyUsedAccounts.reducer;

const getStorageKey = (teamUserId: string | null) => `recentlyUsedAccounts-${teamUserId}`;

export function loadRecentlyUsedAccounts() {
  return (dispatch: any, getState: () => RootState) => {
    try {
      const userSettings = getState().userSettings;

      if (!userSettings.isEmployee) return;

      const storageKey = getStorageKey(userSettings.teamUserId);

      const accounts = getLocalStorage<RecentlyUsedAccountStore[]>(storageKey)?.value ?? [];

      dispatch(recentlyUsedAccounts.actions.setRecentlyUsedAccounts(accounts));
    } catch (error: any) {
      console.error(error);
    }
  };
}

export function addToRecentlyUsedAccounts(account: Account) {
  return (dispatch: any, getState: () => RootState) => {
    try {
      const userSettings = getState().userSettings;
      const currentState = getState().recentlyUsedAccounts;

      if (!userSettings.isEmployee) return;

      const storageKey = getStorageKey(userSettings.teamUserId);

      let valuesToUpdate = [...(currentState.accounts ?? getLocalStorage<RecentlyUsedAccountStore[]>(storageKey)?.value ?? [])];

      if (valuesToUpdate.length >= 10) {
        valuesToUpdate.splice(9, 1);
      }

      const accountIndex = valuesToUpdate.findIndex((accountStorage) => accountStorage.account.key === account.key) ?? -1;

      if (accountIndex > -1) {
        valuesToUpdate.splice(accountIndex, 1);
      }

      valuesToUpdate.push({ account, lastUsed: new Date() });
      valuesToUpdate = valuesToUpdate.sort((a, b) => new Date(b.lastUsed).getTime() - new Date(a.lastUsed).getTime());

      setLocalStorage<RecentlyUsedAccountStore[]>(storageKey, valuesToUpdate);
      dispatch(recentlyUsedAccounts.actions.setRecentlyUsedAccounts(valuesToUpdate));
    } catch (error: any) {
      console.error(error);
    }
  };
}

export function clearRecentlyUsedAccounts() {
  return (dispatch: any, getState: () => RootState) => {
    try {
      const userSettings = getState().userSettings;

      if (!userSettings.isEmployee) return;

      const storageKey = getStorageKey(userSettings.teamUserId);

      setLocalStorage<RecentlyUsedAccountStore[]>(storageKey, []);
      dispatch(recentlyUsedAccounts.actions.setRecentlyUsedAccounts([]));
    } catch (error: any) {
      console.error(error);
    }
  };
}
