import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  debounce,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import useApiBaseUrl from 'hooks/useApiBaseUrl';
import React, { useCallback, useEffect, useState } from 'react';
import { Account } from 'types/account';
import { ApiResponse } from 'types/api';
import axiosServices from 'utils/api/axiosServices';
import { isEmptyOrSpaces } from 'utils/stringUtils';
import { AccountSelectItem } from './AccountSelectItem';
import Routes from 'routes/RouteNames';
import useActiveAccount from 'hooks/useActiveAccount';
import { useNavigate } from 'react-router-dom';
import { formatDashboardBaseUrl } from 'utils/urlUtils';
import { ArrowDropDown } from '@mui/icons-material';
import HistoryIcon from '@mui/icons-material/History';
import useRecentAccounts from 'hooks/useRecentAccounts';

export const EmployeeAccountSearch: React.FC = () => {
  const [filter, setFilter] = useState('');
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [filteredAccounts, setFilteredAccounts] = useState<Account[]>([]);
  const apiUrl = useApiBaseUrl();
  const [loading, setLoading] = useState(false);
  const activeAccount = useActiveAccount();
  const navigate = useNavigate();
  const [recentAnchorEl, setRecentAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { accounts: recentAccounts, clear: clearRecentAccounts } = useRecentAccounts();

  const handleRecentsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setRecentAnchorEl(event.currentTarget);
  };

  const handleRecentsClosed = () => {
    setRecentAnchorEl(null);
  };

  const recentOpen = Boolean(recentAnchorEl);
  const hasRecentAccounts = recentAccounts.length > 0;

  const filterAccountsDebounce = useCallback(
    debounce((filter, callback) => {
      setFilteredAccounts([]);
      setLoading(true);
      axiosServices.get<ApiResponse<Account>>(`${apiUrl}/accounts/filter/${filter}`).then((response) => {
        callback(response.data.data);
        setLoading(false);
      });
    }, 250),
    []
  );

  useEffect(() => {
    if (!isEmptyOrSpaces(filter)) {
      filterAccountsDebounce(filter, (accounts: Account[]) => setFilteredAccounts(accounts ?? []));
    }
  }, [filter, filterAccountsDebounce]);

  const handleAccountChanged = (e: any, value: Account | null) => {
    if (value?.accountCode) {
      handleRecentsClosed();

      const baseUrl = formatDashboardBaseUrl(value.accountCode, value.accountType);

      navigate(`${baseUrl}${Routes.Home}`);
    }
  };

  const handleClearRecentAccounts = () => {
    clearRecentAccounts();
    handleRecentsClosed();
  };

  const isSearching: boolean = !isEmptyOrSpaces(filter) && loading;

  return (
    <Stack>
      {!searchOpen && (
        <Stack direction="row" alignItems="center">
          <Button onClick={() => setSearchOpen(true)} variant="text" color="inherit" size="small" sx={{ height: '40px' }}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <AccountSelectItem account={activeAccount ?? null} />
              <ArrowDropDown color="secondary" />
            </Stack>
          </Button>

          <IconButton disabled={!hasRecentAccounts} onClick={handleRecentsClick}>
            <HistoryIcon />
          </IconButton>
        </Stack>
      )}

      {recentOpen && (
        <Popover
          open={recentOpen}
          anchorEl={recentAnchorEl}
          onClose={handleRecentsClosed}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <Stack>
            <Box border={1} borderColor="grey.300" borderRadius={1}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body1" mx={2} my={1}>
                  Recently Used Accounts
                </Typography>
                <Button variant="text" size="extraSmall" color="error" onClick={handleClearRecentAccounts}>
                  CLEAR
                </Button>
              </Stack>
              <Divider></Divider>
              <FormControl>
                {recentAccounts.map((account) => (
                  <MenuItem key={account.key} value={account.key} onClick={(e) => handleAccountChanged(e, account ?? null)}>
                    <AccountSelectItem account={account} />
                  </MenuItem>
                ))}
              </FormControl>
            </Box>
          </Stack>
        </Popover>
      )}

      {searchOpen && (
        <Autocomplete
          id="employee-account-search"
          sx={{ width: 400 }}
          open={searchOpen}
          size="small"
          onClose={() => setSearchOpen(false)}
          isOptionEqualToValue={(option, value) => option.key === value.key}
          getOptionLabel={(option) => option.key}
          // Disable client filtering
          filterOptions={(x) => x}
          onInputChange={(e, newValue) => setFilter(newValue)}
          options={filteredAccounts}
          loading={isSearching}
          onChange={handleAccountChanged}
          noOptionsText="Start typing an account name or code..."
          renderOption={(props, option) => (
            <MenuItem {...props} key={option.key}>
              <AccountSelectItem account={option} />
            </MenuItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              autoComplete="off"
              autoFocus
              variant="outlined"
              hiddenLabel
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isSearching ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      )}
    </Stack>
  );
};
