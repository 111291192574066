import { Chip } from '@mui/material';

export interface RangedStatusChipProps {
  size: 'small' | 'medium';
}

const RangedStatusChip = (props: RangedStatusChipProps) => {
  return <Chip label="Ranged" color="highlight" variant="light" size={props.size} />;
};

export default RangedStatusChip;
