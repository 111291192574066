import React, { createContext, useEffect, useReducer } from 'react';
import { AccountInfo, InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';

// action - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project import
//import Loader from 'components/Loader';
import { AuthProps, AzureB2cContextType } from 'types/auth';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { loginRequestOptions, msalConfig } from 'b2cAuthConfig';

// const
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

// ==============================|| AzURE B2C CONTEXT & PROVIDER ||============================== //

const buildUser = (account: AccountInfo | null | undefined) => {
  if (!account) return null;

  return {
    id: account!.idTokenClaims?.sub,
    email: account!.idTokenClaims?.emails?.[0] ?? '',
    name: account!.name
  };
};

const AzureB2cContext = createContext<AzureB2cContextType | null>(null);

export const AzureB2cContextProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const { instance: msalInstance } = useMsal();
  const { login, error, result } = useMsalAuthentication(InteractionType.Silent, loginRequestOptions);

  useEffect(
    () => {
      // If the silent authentication is successful, then set the active account returned.
      if (!error && !!result?.account) {
        msalInstance.setActiveAccount(result.account);

        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user: buildUser(result?.account),
            token: result.accessToken
          }
        });

        // If we do not have an active session then we need to redirect and login
      } else if (error instanceof InteractionRequiredAuthError) {
        login(InteractionType.Redirect).then((result) => {
          msalInstance.setActiveAccount(result!.account);

          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: buildUser(result?.account),
              token: result?.accessToken
            }
          });
        });
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, error, result, msalInstance]
  );

  const logout = () => {
    msalInstance.setActiveAccount(null);
    msalInstance.logoutRedirect({
      postLogoutRedirectUri: msalConfig.auth.redirectUri
    });
    dispatch({ type: LOGOUT });
  };

  return (
    <AzureB2cContext.Provider
      value={{
        ...state,
        token: result?.accessToken,
        login,
        logout
      }}
    >
      {children}
    </AzureB2cContext.Provider>
  );
};

export default AzureB2cContext;
