import { FileDownloadOutlined, CleaningServicesOutlined } from '@mui/icons-material';
import { Grid, Stack, Typography } from '@mui/material';
import IconButton from 'components/@extended/IconButton';
import LoadingButton from 'components/@extended/LoadingButton';
import CurrencyField from 'components/CurrencyField';
import DateField from 'components/DateField';
import LightTooltip from 'components/LightToolTip';
import Loader from 'components/Loader';
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import ActionBar from 'components/action-bar/ActionBar';
import TitleAndCaptionRegion from 'components/TitleAndCaption/TitleAndCaptionRegion';
import CustomerSnapshot from 'components/customer-snapshot/CustomerSnapshot';
import { DateRangeFilter } from 'components/date-range-selector/date-range-selector';
import DisputedChip from 'components/disputed-status-chip/DisputedChip';
import { FilterSearch } from 'components/filter-search/filter-search';
import { FilterSelect } from 'components/filter-select/filter-select';
import { PaymentStatus } from 'components/payment-status/payment-status';
import { DashboardTable } from 'components/third-party/DashboardTable';
import dayjs from 'dayjs';
import useDashboardFilter, { DashboardFilterParamType } from 'hooks/useDashboardFilter';
import useDashboardNavigate from 'hooks/useDashboardNavigate';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Routes from 'routes/RouteNames';
import { dispatch, useSelector } from 'store';
import { sendCustomerStatement } from 'store/reducers/customer-statement';
import { openSnackbar } from 'store/reducers/snackbar';
import { ApiResponse, ApiResponseNoData, DateFilterType, PagedList } from 'types/api';
import { SortDirection } from 'types/data-tables';
import { SalesInvoiceFilterOptions, SalesTransaction } from 'types/pages/sales-invoices';
import axiosServices from 'utils/api/axiosServices';
import { mapObjectToQueryString } from 'utils/urlUtils';

export type SalesInvoicesFilterEntityType = 'references' | 'ordernumbers' | 'customerrefs' | null;

// TODO : Move to API layer
export interface SalesInvoicesQueryParams extends DashboardFilterParamType {
  dateFilterType?: DateFilterType | null;
  fromDate?: string | null;
  toDate?: string | null;
  transactionTypes?: string | null;
  entityType?: SalesInvoicesFilterEntityType;
  entities?: string | null;
  paymentStatusId?: string | null;
}

// TODO : Move to API layer
const InvoicesQueryParamKeys = {
  dateFilterType: 'dateFilterType',
  fromDate: 'fromDate',
  toDate: 'toDate',
  transactionTypes: 'transactionTypes',
  entityType: 'entityType',
  entities: 'entities',
  paymentStatusId: 'paymentStatusId',
  page: 'page',
  pageSize: 'pageSize',
  order: 'order',
  sort: 'sort'
};

const queryParamsToFilter = (params: URLSearchParams): SalesInvoicesQueryParams => {
  return {
    dateFilterType:
      (params.get(InvoicesQueryParamKeys.dateFilterType) as DateFilterType) ?? (defaultFilter.dateFilterType as DateFilterType),
    fromDate: params.get(InvoicesQueryParamKeys.fromDate) ?? defaultFilter.fromDate,
    toDate: params.get(InvoicesQueryParamKeys.toDate) ?? defaultFilter.toDate,
    transactionTypes: params.get(InvoicesQueryParamKeys.transactionTypes) ?? defaultFilter.transactionTypes,
    entityType:
      (params.get(InvoicesQueryParamKeys.entityType) as 'references' | 'ordernumbers' | 'customerrefs' | null) ?? defaultFilter.entityType,
    entities: params.get(InvoicesQueryParamKeys.entities) ?? defaultFilter.entities,
    paymentStatusId: params.get(InvoicesQueryParamKeys.paymentStatusId) ?? defaultFilter.paymentStatusId,
    order: params.get(InvoicesQueryParamKeys.order) ?? defaultFilter.order,
    sort: params.get(InvoicesQueryParamKeys.sort) ?? defaultFilter.sort,
    page: parseInt(params.get(InvoicesQueryParamKeys.page) ?? defaultFilter.page.toString()),
    pageSize: parseInt(params.get(InvoicesQueryParamKeys.pageSize) ?? defaultFilter.pageSize.toString())
  };
};

const defaultFilter: SalesInvoicesQueryParams = {
  page: 1,
  pageSize: 15,
  entityType: 'customerrefs',
  dateFilterType: DateFilterType.Created,
  fromDate: dayjs().subtract(90, 'days').startOf('day').toISOString(),
  toDate: dayjs().endOf('day').toISOString()
};

// TODO : Move to API layer
const getInvoices = async (value: SalesInvoicesQueryParams) => {
  const queryString = mapObjectToQueryString(value);

  const request: string = `sales-invoices?${queryString}`;
  const response = await axiosServices.get<ApiResponse<PagedList<SalesTransaction>>>(request);
  return response.data.data;
};

// TODO : Move to API layer
const exportInvoices = async (value: SalesInvoicesQueryParams) => {
  const queryString = mapObjectToQueryString(value);

  const request: string = `sales-invoices/queue-csv-export?${queryString}`;
  await axiosServices.post<ApiResponseNoData>(request);

  dispatch(
    openSnackbar({
      open: true,
      message: 'Your request is being processed. You should receive your exported invoices by email in a minute or two.',
      variant: 'alert',
      alert: {
        color: 'success'
      },
      close: true
    })
  );
};

const SalesInvoicesPage = () => {
  const navigate = useDashboardNavigate();
  const { filter, setOrUpdateParams, clearFilter, pagination, rows, fetching, refreshFilter, exportCsv, isExportingCsv } =
    useDashboardFilter<SalesInvoicesQueryParams, SalesTransaction>(defaultFilter, queryParamsToFilter, getInvoices, exportInvoices);
  const customerStatementState = useSelector((state) => state.customerStatements);
  const [entities, setEntities] = useState<string | null>();
  const [options, setOptions] = useState<SalesInvoiceFilterOptions>();
  const [loadingOptions, setLoadingOptions] = useState<boolean>(false);

  const getFilterOptions = useCallback(async () => {
    try {
      setLoadingOptions(true);
      // TODO : Move to API Layer
      const response = await axiosServices.get<ApiResponse<SalesInvoiceFilterOptions>>('sales-invoices/filter-options');
      setOptions(response.data.data);
    } finally {
      setLoadingOptions(false);
    }
  }, []);

  useEffect(() => {
    getFilterOptions();
    refreshFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSingleParam = (key: string, value: string | undefined) => {
    setOrUpdateParams([
      { key, value },
      // Always reset page when setting any param
      { key: InvoicesQueryParamKeys.page, value: defaultFilter.page.toString() }
    ]);
  };

  const columns = useMemo(
    () => [
      {
        accessor: 'createdAt',
        Header: 'Created',
        Cell: ({ value }: any) => {
          return <DateField format={'DD-MMM-YYYY'} value={value} />;
        },
        sortDirection: filter.sort === 'createdAt' ? filter.order : SortDirection.None
      },
      {
        accessor: 'typeDescription',
        Header: 'Type',
        sortable: false,
        disableSortBy: true,
        Cell: ({ value }: any) => {
          return <Typography variant="body1">{value}</Typography>;
        }
      },
      {
        accessor: 'paymentStatus',
        Header: 'Status',
        sortable: false,
        disableSortBy: true,
        Cell: ({ value, row }) => {
          return (
            <Stack spacing={0.5}>
              <PaymentStatus type={row.original.type} hasOutstanding={row.original.outstanding > 0} paymentStatus={value}></PaymentStatus>
              {row.original.disputed && <DisputedChip />}
            </Stack>
          );
        }
      },
      {
        accessor: 'orderNumber',
        Header: 'Order No.',
        Cell: ({ value }: any) => {
          return <Typography variant="body1">{value === -999 ? 'MULTI' : value}</Typography>;
        },
        sortDirection: filter.sort === 'orderNumber' ? filter.order : SortDirection.None
      },
      {
        accessor: 'reference',
        Header: 'Invoice No.',
        Cell: ({ value }: any) => {
          return <Typography variant="body1">{value}</Typography>;
        },
        sortDirection: filter.sort === 'reference' ? filter.order : SortDirection.None
      },
      {
        accessor: 'customerRef',
        Header: 'Your Ref#',
        Cell: ({ value }: any) => {
          return (
            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '6rem' }}>
              <Typography noWrap variant="body1">
                {value}
              </Typography>
            </div>
          );
        },
        sortDirection: filter.sort === 'customerRef1' ? filter.order : SortDirection.None
      },
      {
        accessor: 'net',
        Header: 'Net',
        Cell: ({ value, row }) => {
          return <CurrencyField symbol={row.original.currencySymbol} value={value} />;
        },
        sortDirection: filter.sort === 'net' ? filter.order : SortDirection.None
      },
      {
        accessor: 'tax',
        Header: 'Tax',
        Cell: ({ value, row }) => {
          return <CurrencyField symbol={row.original.currencySymbol} value={value} />;
        },
        sortDirection: filter.sort === 'tax' ? filter.order : SortDirection.None
      },
      {
        accessor: 'gross',
        Header: 'Gross',
        Cell: ({ value, row }) => {
          return <CurrencyField symbol={row.original.currencySymbol} value={value} />;
        },
        sortDirection: filter.sort === 'gross' ? filter.order : SortDirection.None
      },
      {
        accessor: 'outstanding',
        Header: 'Outstanding',
        Cell: ({ value, row }) => {
          return <CurrencyField symbol={row.original.currencySymbol} value={value} />;
        },
        sortDirection: filter.sort === 'outstanding' ? filter.order : SortDirection.None
      },
      {
        accessor: 'dueAt',
        Header: 'Due',
        Cell: ({ value }: any) => {
          if (!value) return <></>;

          return <DateField format={'DD-MMM-YYYY'} value={value} />;
        },
        sortDirection: filter.sort === 'dueAt' ? filter.order : SortDirection.None
      }
    ],
    [filter.sort, filter.order]
  );

  const isOptionsLoaded = !!options && !loadingOptions;
  const isReady = isOptionsLoaded && !fetching;

  if (!isOptionsLoaded) {
    return <Loader />;
  }

  return (
    <Stack direction="column">
      <ActionBar
        hideBackNavigation
        mainRegion={<TitleAndCaptionRegion titleFontVariant="h5" title="Invoices" caption="View, manage and pay your invoices" />}
        primaryAction={
          <Stack direction="row" spacing={1}>
            <LoadingButton
              color="success"
              variant="dashed"
              loadingPosition="end"
              loading={customerStatementState.isSendingStatement}
              disabled={!isReady}
              size="small"
              endIcon={<FileDownloadOutlined />}
              onClick={() => dispatch(sendCustomerStatement())}
            >
              Email Statement
            </LoadingButton>
          </Stack>
        }
      />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CustomerSnapshot onTrueLayerDialogClosed={() => clearFilter()} />
        </Grid>
        <Grid item xs={12}>
          <MainCard title={null} content={false}>
            <ScrollX>
              <>
                {fetching && <Loader />}

                <Stack direction="row" gap={1} m={1} sx={{ flexWrap: 'wrap' }} justifyContent="space-between">
                  <Stack direction="row" gap={1} sx={{ flexWrap: 'wrap' }}>
                    <DateRangeFilter
                      dateFilter={options?.dateFilterTypes.find((o) => o.type.toString() === filter?.dateFilterType?.toString()) ?? null}
                      filterTypes={options?.dateFilterTypes}
                      fromDate={filter?.fromDate}
                      toDate={filter?.toDate}
                      disabled={!isOptionsLoaded}
                      onFilterTypeChanged={(value) => setSingleParam(InvoicesQueryParamKeys.dateFilterType, value)}
                      onFromDateChanged={(value) => setSingleParam(InvoicesQueryParamKeys.fromDate, value.toISOString())}
                      onToDateChanged={(value) => setSingleParam(InvoicesQueryParamKeys.toDate, value.toISOString())}
                    />

                    <FilterSelect
                      label="Status"
                      options={options?.paymentStatuses ?? []}
                      selected={options?.paymentStatuses.find((p) => p.value.toString() === filter.paymentStatusId) ?? null}
                      disabled={!isOptionsLoaded}
                      onSelected={(option) => setSingleParam(InvoicesQueryParamKeys.paymentStatusId, option?.value)}
                    />

                    <FilterSelect
                      label="Type"
                      options={options?.transactionTypes ?? []}
                      selected={options?.transactionTypes.find((t) => t.value.toString() === filter.transactionTypes) ?? null}
                      disabled={!isOptionsLoaded}
                      onSelected={(option) => setSingleParam(InvoicesQueryParamKeys.transactionTypes, option?.value)}
                    />
                  </Stack>

                  <Stack direction="row" gap={1}>
                    <FilterSearch
                      entityTypes={options!.entityTypes}
                      selectedEntityType={options?.entityTypes.find((e) => e.value === filter.entityType)}
                      searchString={entities ?? ''}
                      disabled={!isOptionsLoaded}
                      onEntityTypeChanged={(value) => {
                        setEntities('');
                        setOrUpdateParams([
                          { key: InvoicesQueryParamKeys.entityType, value: value?.value },
                          { key: InvoicesQueryParamKeys.page, value: defaultFilter.page.toString() },
                          { key: InvoicesQueryParamKeys.entities, value: undefined }
                        ]);
                      }}
                      onSearch={(value) => setSingleParam(InvoicesQueryParamKeys.entities, value)}
                      onSearchStringChanged={(value) => setEntities(value)}
                    />

                    <LightTooltip title="Clear Filters">
                      <IconButton
                        color="secondary"
                        disabled={!isOptionsLoaded}
                        variant="outlined"
                        onClick={() => {
                          setEntities('');
                          clearFilter();
                        }}
                      >
                        <CleaningServicesOutlined />
                      </IconButton>
                    </LightTooltip>

                    <LightTooltip title="Export list as CSV">
                      <LoadingButton
                        variant="outlined"
                        color="secondary"
                        loading={isExportingCsv}
                        disabled={!isOptionsLoaded}
                        shape="square"
                        onClick={exportCsv}
                      >
                        <FileDownloadOutlined color="primary" />
                      </LoadingButton>
                    </LightTooltip>
                  </Stack>
                </Stack>

                <Stack>
                  <DashboardTable
                    rows={rows}
                    columns={columns}
                    isFetching={fetching}
                    loadingText="Fetching your invoices..."
                    pagination={pagination}
                    onPaginationChanged={(newPaginationValue) => {
                      setOrUpdateParams([
                        { key: InvoicesQueryParamKeys.page, value: newPaginationValue.page.toString() },
                        { key: InvoicesQueryParamKeys.pageSize, value: newPaginationValue.pageSize.toString() }
                      ]);
                    }}
                    onHeaderClicked={(column, sortDirection) => {
                      setOrUpdateParams([
                        { key: InvoicesQueryParamKeys.sort, value: column?.id },
                        { key: InvoicesQueryParamKeys.order, value: sortDirection }
                      ]);
                    }}
                    onReset={() => {
                      setEntities('');
                      clearFilter();
                    }}
                    onRowClicked={(row) => navigate(`${Routes.Invoices}/${row.id}`)}
                  />
                </Stack>
              </>
            </ScrollX>
          </MainCard>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default SalesInvoicesPage;
