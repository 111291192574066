import { Chip } from '@mui/material';

export interface ClearanceStatusChipProps {
  size: 'small' | 'medium';
  variant: 'filled' | 'outlined' | 'light' | 'combined';
}

const ClearanceStatusChip = (props: ClearanceStatusChipProps) => {
  return <Chip label="Clearance" color="warning" variant={props.variant} size={props.size} />;
};

export default ClearanceStatusChip;
