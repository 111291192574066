// material-ui
import { useTheme } from '@mui/material/styles';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import Logo from 'components/logo';

// ==============================|| DRAWER HEADER ||============================== //

interface Props {
  open: boolean;
}

const DrawerHeader = ({ open }: Props) => {
  const theme = useTheme();

  return (
    <DrawerHeaderStyled theme={theme} open={open}>
      <Logo
        isIcon={!open}
        sx={{
          ml: open ? 4 : 0,
          mt: open ? 2 : 0,
          width: open ? 'auto' : 35,
          height: 35
        }}
      />
    </DrawerHeaderStyled>
  );
};

export default DrawerHeader;
