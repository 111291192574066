import { NavigateOptions, useNavigate } from 'react-router';
import useBasePath from './useBasePath';

const useDashboardNavigate = () => {
  const navigate = useNavigate();
  const getBasePath = useBasePath();

  const navigateFromBasePath = (path: string, options?: NavigateOptions | undefined) => {
    navigate(`${getBasePath(path)}`, options);
  };

  return navigateFromBasePath;
};

export default useDashboardNavigate;
