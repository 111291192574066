import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dispatch } from 'store';
import { ApiResponse, EventSummary } from 'types/api';
import { DeliverySummary } from 'types/pages/customer-deliveries';
import axiosServices from 'utils/api/axiosServices';
import { openSnackbar } from '../snackbar';
import { RelatedSalesEntities } from 'types/related-entities';

export interface DeliveryDetailsProps {
  isSummaryFetching: boolean;
  isEventsFetching: boolean;
  isActionWorking: boolean;
  summary: DeliverySummary | null;
  events: EventSummary[] | null;
  related: RelatedSalesEntities | null;
  isRelatedFetching: boolean;
}

const initialState: DeliveryDetailsProps = {
  isSummaryFetching: false,
  isEventsFetching: false,
  isActionWorking: false,
  summary: null,
  events: [],
  related: null,
  isRelatedFetching: false
};

const slice = createSlice({
  name: 'deliveryDetails',
  initialState: { ...initialState },
  reducers: {
    setSummaryFetching(state, action: PayloadAction<boolean>) {
      state.isSummaryFetching = action.payload;
    },
    setSummary(state, action: PayloadAction<DeliverySummary>) {
      state.summary = action.payload;
    },
    setEventsFetching(state, action: PayloadAction<boolean>) {
      state.isEventsFetching = action.payload;
    },
    setEvents(state, action: PayloadAction<EventSummary[]>) {
      state.events = action.payload;
    },
    setActionWorking(state, action: PayloadAction<boolean>) {
      state.isActionWorking = action.payload;
    },
    setRelated(state, action: PayloadAction<RelatedSalesEntities>) {
      state.related = action.payload;
    },
    setRelatedFetching(state, action: PayloadAction<boolean>) {
      state.isRelatedFetching = action.payload;
    },
    reset(state) {
      state.summary = null;
      state.isSummaryFetching = false;
      state.isEventsFetching = false;
      state.events = [];
      state.related = null;
      state.isRelatedFetching = false;
    }
  }
});

export default slice.reducer;
export const { reset } = slice.actions;

export function loadRelatedSalesEntities(goodsOutId: number) {
  return async () => {
    try {
      dispatch(slice.actions.setRelatedFetching(true));

      const relatedResponse = await axiosServices.get<ApiResponse<RelatedSalesEntities>>(`customer-deliveries/${goodsOutId}/related`);

      dispatch(slice.actions.setRelated(relatedResponse.data.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setRelatedFetching(false));
    }
  };
}

export function getDeliverySummary(goodsOutNumber: number) {
  return async () => {
    try {
      dispatch(slice.actions.setSummaryFetching(true));

      const summaryResponse = await axiosServices.get<ApiResponse<DeliverySummary>>(`customer-deliveries/${goodsOutNumber}`);

      dispatch(slice.actions.setSummary(summaryResponse.data.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setSummaryFetching(false));
    }
  };
}

export function getDeliveryEvents(goodsOutNumber: number) {
  return async () => {
    try {
      dispatch(slice.actions.setEventsFetching(true));

      const summaryResponse = await axiosServices.get<ApiResponse<EventSummary[]>>(`customer-events/deliveries/${goodsOutNumber}`);

      dispatch(slice.actions.setEvents(summaryResponse.data.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setEventsFetching(false));
    }
  };
}

export function queueDeliveryNoteEmail(goodsOutNumber: number) {
  return async () => {
    try {
      dispatch(slice.actions.setActionWorking(true));

      await axiosServices.post(`customer-deliveries/${goodsOutNumber}/queue-delivery-note-email`);

      dispatch(
        openSnackbar({
          open: true,
          message: 'Your request is being processed. You should receive your delivery note by email in a minute or two.',
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setActionWorking(false));
    }
  };
}
