// ==============================|| AUTH HOOKS ||============================== //

import { useSelector } from 'store';
import { AccountPermissionLevel } from 'types/account';

// TODO : Probably doesn't to be a hook?
const useActivePermissionLevel = (): AccountPermissionLevel => {
  const settings = useSelector((state) => state.userSettings);
  const accountInContext = useSelector((state) => state.accountInContext);

  if (settings.isEmployee) {
    return AccountPermissionLevel.Employee;
  }

  if (accountInContext?.accountRole?.teamRole) {
    return accountInContext.accountRole.teamRole.id;
  }

  return AccountPermissionLevel.ViewOnly;
};

export default useActivePermissionLevel;
