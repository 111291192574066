import useEnv from 'hooks/useEnv';
import useImage from 'hooks/useImage';

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  const env = useEnv();
  const image = useImage(`branding/${env.REACT_APP_LOGO_SMALL_URL}`);

  if (image.loading || !image.image) return <></>;

  return <img src={image.image} alt="Branding Small" width="40" />;
};

export default LogoIcon;
