import { AccountType } from 'types/account';

export const ParseAccountAndTypeFromUrl = (path: string): { accountCode: string; accountType: AccountType } | null => {
  if (!path) return null;

  const splitPath = path.split('/');

  const accountTypeName = splitPath[1]?.toLowerCase() as 'customers' | 'suppliers';

  if (!accountTypeName) return null;

  const accountCode = splitPath[2]?.toUpperCase();

  if (!accountCode) return null;

  let accountType: AccountType;

  if (accountTypeName === 'customers') accountType = AccountType.Customer;
  else if (accountTypeName === 'suppliers') accountType = AccountType.Supplier;
  else return null;

  return { accountCode, accountType };
};

export const formatDashboardBaseUrl = (accountCode: string, accountType: string): string => {
  return `/${accountType.toLowerCase()}s/${accountCode.toUpperCase()}/`;
};

export const mapObjectToQueryString = (queryParams: any) => {
  let queryString = Object.keys(queryParams)
    // @ts-ignore
    .filter((key) => queryParams[key] !== undefined && queryParams[key] !== null)
    // @ts-ignore
    .map((key) => key + '=' + encodeURIComponent(queryParams[key]))
    .join('&');

  return queryString;
};
