import { AccessTimeOutlined, DoneOutlined, CloseOutlined } from '@mui/icons-material';
import { Chip, Typography } from '@mui/material';
import { isEmptyOrSpaces } from 'utils/stringUtils';

export interface PaymentStatusProps {
  paymentStatus: string;
  size?: 'small' | 'medium' | 'large';
  hasOutstanding: boolean;
  type: 'INV' | 'PAY' | 'CRN' | 'ADR' | 'ACR';
}

const getCreditOrInvoiceColour = (paymentStatus: string) => {
  switch (paymentStatus.toLowerCase()) {
    case 'paid':
      return 'success';
    case 'unpaid':
      return 'warning';
    default:
      return 'error';
  }
};

const getPaymentColour = (paymentStatus: string) => {
  switch (paymentStatus.toLowerCase()) {
    case 'allocated':
      return 'success';
    case 'unallocated':
      return 'warning';
    default:
      return 'error';
  }
};

const getIcon = (paymentStatus: string) => {
  switch (paymentStatus.toLowerCase()) {
    case 'paid':
      return <DoneOutlined />;
    case 'unpaid':
      return <AccessTimeOutlined />;
    default:
      return <CloseOutlined />;
  }
};

export const PaymentStatus: React.FC<PaymentStatusProps> = (props: PaymentStatusProps) => {
  if (props.type === 'PAY') {
    return (
      <Chip
        variant="combined"
        size={props.size ?? 'small'}
        label={<Typography variant="body1">{props.hasOutstanding ? 'Unallocated' : 'Allocated'}</Typography>}
        color={getPaymentColour(props.hasOutstanding ? 'Unallocated' : 'Allocated')}
        icon={getIcon(props.paymentStatus)}
      />
    );
  }

  if (isEmptyOrSpaces(props.paymentStatus)) return <></>;

  return (
    <Chip
      variant="combined"
      size={props.size ?? 'small'}
      label={<Typography variant="body1">{props.paymentStatus}</Typography>}
      color={getCreditOrInvoiceColour(props.paymentStatus)}
      icon={getIcon(props.paymentStatus)}
    />
  );
};
