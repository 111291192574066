import useActiveAccount from 'hooks/useActiveAccount';
import UnderConstruction from 'pages/maintenance/under-construction';
import { AccountType } from 'types/account';
import SalesMarketingEditDetailPage from './sales-marketing-edit-detail-page';

const MarketingDetailsEditPage = () => {
  const activeAccount = useActiveAccount();

  return activeAccount?.accountTypeId === AccountType.Customer ? <SalesMarketingEditDetailPage /> : <UnderConstruction />;
};

export default MarketingDetailsEditPage;
