import { Button, Dialog, Stack, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import { dispatch, useSelector } from 'store';
import { closeAlert } from 'store/reducers/alert-dialog';

const AlertDialog = () => {
  const state = useSelector((state) => state.alertDialog);

  if (!state.open) return <></>;

  const handleCancel = () => {
    dispatch(closeAlert());

    if (state.onCancel) {
      state.onCancel();
    }
  };

  const handleConfirm = () => {
    dispatch(closeAlert());

    if (state.onConfirm) {
      state.onConfirm();
    }
  };

  return (
    <Dialog
      maxWidth={state.size || 'xs'}
      open={state.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableScrollLock
    >
      <MainCard>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" justifyContent="center">
            {state.title === typeof String ? <Typography variant="h6">{state.title}</Typography> : state.title}
          </Stack>
          {state.message === typeof String ? <Typography variant="body1">{state.message}</Typography> : state.message}
          <Stack alignItems="center" direction="row" spacing={1} justifyContent="center">
            {state.cancelButtonText && (
              // @ts-ignore
              <Button variant="outlined" color={state.cancelButtonColour ?? 'secondary'} onClick={handleCancel} autoFocus>
                {state.cancelButtonText}
              </Button>
            )}
            {state.confirmButtonText && (
              // @ts-ignore
              <Button variant="contained" color={state.confirmButtonColour ?? 'primary'} onClick={handleConfirm} autoFocus>
                {state.confirmButtonText}
              </Button>
            )}
          </Stack>
        </Stack>
      </MainCard>
    </Dialog>
  );
};

export default AlertDialog;
