// material-ui

// project import
import Profile from './Profile';
import AccountSelector from './AccountSelector/AccountSelector';
import HelpButton from './HelpButton';
import PinnedOrderButton from './PinnedOrderButton';
import FeatureToggle from 'components/feature-toggle/FeatureToggle';
import { CREATE_SALES_ORDER_FEATURE } from 'featureConstants';
import { CircularProgress, Stack } from '@mui/material';
import PermissionGuard from 'components/permission-guard/PermissionGuard';
import { createSalesOrderPermissions } from 'permissions';
import NotificationsButton from './NotificationsButton';
import IconButton from 'components/@extended/IconButton';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import NotificationsNoneTwoToneIcon from '@mui/icons-material/NotificationsNoneTwoTone';
import useActivePermissionLevel from 'hooks/useActivePermissionLevel';
import { AccountPermissionLevel } from 'types/account';
import { dispatch, useSelector } from 'store';
import LightTooltip from 'components/LightToolTip';
import { toggleEmployeeTopicSubscription } from 'store/reducers/notification-topics';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const accountPermissionLevel = useActivePermissionLevel();
  const notificationTopicState = useSelector((state) => state.notificationTopics);

  const isSubscribed = notificationTopicState.subscriptions?.employeeTopic;

  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <Stack ml={1} direction="row" alignItems="center">
        <AccountSelector />
        {accountPermissionLevel == AccountPermissionLevel.Employee && (
          <Stack>
            <>
              {notificationTopicState.isFetching && (
                <Stack ml={1}>
                  <CircularProgress size={20} />
                </Stack>
              )}

              {!notificationTopicState.isFetching && (
                <LightTooltip
                  title={isSubscribed ? 'Unsubscribe from notifications from this account' : 'Subscribe to notifications from this account'}
                >
                  <IconButton
                    shape="rounded"
                    color={isSubscribed ? 'success' : 'secondary'}
                    disabled={notificationTopicState.isFetching}
                    onClick={() => dispatch(toggleEmployeeTopicSubscription())}
                  >
                    {isSubscribed ? <NotificationsNoneTwoToneIcon /> : <NotificationsOffIcon />}
                  </IconButton>
                </LightTooltip>
              )}
            </>
          </Stack>
        )}
      </Stack>

      <Stack direction="row" alignItems="center">
        <NotificationsButton />
        <PermissionGuard canAccess={createSalesOrderPermissions}>
          <FeatureToggle feature={CREATE_SALES_ORDER_FEATURE}>
            <PinnedOrderButton />
          </FeatureToggle>
        </PermissionGuard>
        <HelpButton />
        <Profile />
      </Stack>
    </Stack>
  );
};

export default HeaderContent;
