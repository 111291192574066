import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dispatch, resetPageState } from 'store';
import { Account, AccountRole } from 'types/account';
import { assignPinnedOrder } from './pinned-order';
import { setLocalStorage } from 'utils/localStorage';

export interface IAccountInContextProps {
  accountRole: AccountRole | null;
}

const initialState: IAccountInContextProps = {
  accountRole: null
};

const accountInContext = createSlice({
  name: 'accountInContext',
  initialState,
  reducers: {
    setAccount(state, action: PayloadAction<AccountRole | null>) {
      state.accountRole = action.payload;
    }
  }
});

export default accountInContext.reducer;
export const { setAccount } = accountInContext.actions;

export function setAccountInContext(accountRole: AccountRole | null, teamUserId: string | null) {
  return () => {
    try {
      if (!accountRole?.account) {
        throw Error('Account is not defined. Unable to set account in context.');
      }

      resetPageState(dispatch);
      dispatch(accountInContext.actions.setAccount(accountRole));
      if (teamUserId) {
        setLocalStorage<Account>(`accountInContext-${teamUserId}`, accountRole.account);
      }
      dispatch(assignPinnedOrder(null));
    } catch (error: any) {
      console.error(error);
    }
  };
}

