import { Snackbar } from '@mui/material';
import useActiveAccount from 'hooks/useActiveAccount';
import usePrevious from 'hooks/usePrevious';
import InvalidAccountPage from 'pages/maintenance/invalid-account-page';
import { useState, useMemo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import RouteNames from 'routes/RouteNames';
import { dispatch, useSelector } from 'store';
import { setAccountInContext } from 'store/reducers/accountInContext';
import { AccountType, Account, AccountRole, AdminRole } from 'types/account';
import { ApiResponse } from 'types/api';
import { UserSettingsProps } from 'types/userSettings';
import axiosServices from 'utils/api/axiosServices';
import { getLocalStorage } from 'utils/localStorage';
import { ParseAccountAndTypeFromUrl, formatDashboardBaseUrl } from 'utils/urlUtils';

const getDefaultAccount = (userSettings: UserSettingsProps): { accountCode: string; accountType: AccountType } | null => {
  const accountFromStorage = userSettings.teamUserId ? getLocalStorage<Account>(`accountInContext-${userSettings.teamUserId}`) : null;

  // If we have an account in storage, use that
  if (accountFromStorage?.value) {
    return {
      accountCode: accountFromStorage.value.accountCode,
      accountType: AccountType[accountFromStorage.value.accountType as keyof typeof AccountType]
    };
  }

  // Else, if employee use the configured default account
  if (userSettings.isEmployee) {
    return {
      accountCode: window.__RUNTIME_CONFIG__.REACT_APP_EMPLOYEE_DEFAULT_ACCOUNT_CODE,
      accountType: window.__RUNTIME_CONFIG__.REACT_APP_EMPLOYEE_DEFAULT_ACCOUNT_TYPE === 1 ? AccountType.Customer : AccountType.Supplier
    };
  }

  // Else, if customer use the configured default account
  const defaultAccountRole = userSettings.accountRoles?.[0];

  if (!defaultAccountRole) {
    throw Error('No customer accounts configured for your user.');
  }

  return {
    accountCode: defaultAccountRole?.account.accountCode,
    accountType: AccountType[defaultAccountRole?.account.accountType as keyof typeof AccountType]
  };
};

async function getUserAccountRole(
  account: { accountCode: string; accountType: AccountType } | null,
  userSettings: UserSettingsProps
): Promise<AccountRole | null> {
  if (!account) return null;

  if (userSettings.isEmployee) {
    try {
      const response = await axiosServices.get<ApiResponse<Account>>(`accounts/code/${account!.accountCode}/type/${account!.accountType}`);
      return { account: response.data.data, teamRole: AdminRole };
    } catch {
      return null;
    }
  }

  // Else, customer
  return (
    userSettings.accountRoles.find(
      (accRole) => accRole.account.accountCode === account!.accountCode && accRole.account.accountTypeId === account!.accountType
    ) ?? null
  );
}

export interface AccountGuardProps {
  children: React.ReactNode | null;
}

// This prevents the main APp body being rendered if no valid account has been specified
// If an invalid account is in the url, it will show an error page or if no account is specified at all it will use the default account on the user settings
const AccountGuard = (props: AccountGuardProps) => {
  const location = useLocation();
  const userSettings = useSelector((state) => state.userSettings);
  const navigate = useNavigate();
  const activeAccount = useActiveAccount();
  const [invalidAccount, setInvalidAccount] = useState(false);
  const currentAccount = useMemo(() => ParseAccountAndTypeFromUrl(location.pathname), [location.pathname]);
  const previousAccount = usePrevious(currentAccount);

  useEffect(() => {
    if (
      currentAccount?.accountCode &&
      currentAccount?.accountCode === previousAccount?.accountCode &&
      currentAccount?.accountType === previousAccount?.accountType
    ) {
      return;
    }

    // We have a valid account in the url, so set it in context and return
    if (currentAccount?.accountCode && currentAccount?.accountType) {
      getUserAccountRole(currentAccount, userSettings).then((accRole) => {
        if (accRole === null) {
          setInvalidAccount(true);
          return;
        }

        dispatch(setAccountInContext(accRole, userSettings.teamUserId));
        setInvalidAccount(false);
      });
      return;
    }

    const defaultAccount = getDefaultAccount(userSettings);

    if (!defaultAccount) {
      throw Error('No default account configured. Unable to render application.');
    }

    let accountTypeDescription: string;

    if (defaultAccount.accountType === AccountType.Customer) accountTypeDescription = 'customer';
    else if (defaultAccount.accountType === AccountType.Supplier) accountTypeDescription = 'supplier';
    else throw Error('Invalid account type');

    const defaultPath = location.pathname === '/' ? RouteNames.Home : `${location.pathname}${location.search}`;

    const url = `${formatDashboardBaseUrl(defaultAccount.accountCode, accountTypeDescription)}${defaultPath}`;

    navigate(url.replace('//', '/'));
  }, [currentAccount, navigate, previousAccount, userSettings]);

  if (invalidAccount)
    return (
      <>
        <InvalidAccountPage />
        <Snackbar />
      </>
    );
  if (!activeAccount) return <></>;

  return props.children as React.ReactElement<any, any>;
};

export default AccountGuard;
