import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PagedList } from 'types/api';
import { PaginationState } from 'types/data-tables';

type paramMapperFunc = (searchParams: URLSearchParams) => any;
type getFilterFunc = <T extends DashboardFilterParamType>(value: T) => Promise<PagedList<any>>;
type exportCsvFunc = <T extends DashboardFilterParamType>(queryString: T) => Promise<void>;

export interface DashboardFilterParamType {
  page: number;
  pageSize: number;
  order?: string | null;
  sort?: string | null;
}

const useDashboardFilter = <ParamsType extends DashboardFilterParamType, RowType>(
  defaultFilter: ParamsType,
  paramMapper: paramMapperFunc,
  getFilterRequest: getFilterFunc,
  exportCsvRequest?: exportCsvFunc
) => {
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [rows, setRows] = useState<RowType[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterFetching, setFilterFetching] = useState<boolean>(false);
  const [isExportingCsv, setExportingCsv] = useState<boolean>(false);

  const filterParameters = useMemo<ParamsType>(() => paramMapper(searchParams), [searchParams, paramMapper]);

  const calculatedPagination = useMemo<PaginationState>(
    () => ({ page: filterParameters.page, pageSize: filterParameters.pageSize, totalCount: totalRows, totalPages: totalPages }),
    [filterParameters, totalPages, totalRows]
  );

  const updateFilterResults = useCallback(async (filter: ParamsType) => {
    try {
      setFilterFetching(true);

      const pagedList = (await getFilterRequest(filter)) as PagedList<RowType>;

      setTotalRows(pagedList.totalCount);
      setTotalPages(pagedList.totalPages);
      setRows(pagedList.items);
    } finally {
      setFilterFetching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exportCsv = useCallback(
    async (filter: ParamsType) => {
      try {
        setExportingCsv(true);

        if (exportCsvRequest) {
          await exportCsvRequest(filter);
        }
      } finally {
        setExportingCsv(false);
      }
    },
    [exportCsvRequest]
  );

  const setOrUpdateParams = useCallback(
    async (values: { key: string; value: string | null | undefined }[]): Promise<URLSearchParams> => {
      if (!values) return searchParams;

      for (const { key, value } of values) {
        if (value === null || value === undefined) {
          searchParams.delete(key);
        } else {
          searchParams.set(key, value);
        }
      }
      setSearchParams(searchParams);

      await updateFilterResults(paramMapper(searchParams));

      return searchParams;
    },
    [searchParams, paramMapper, updateFilterResults, setSearchParams]
  );

  const resetFilter = useCallback(() => {
    const resetParams = new URLSearchParams();
    setSearchParams(resetParams);
    updateFilterResults(defaultFilter);
  }, [defaultFilter, setSearchParams, updateFilterResults]);

  const refreshFilter = useCallback(() => {
    if (Array.from(searchParams.keys()).length === 0) {
      updateFilterResults(defaultFilter);
    } else {
      updateFilterResults(filterParameters);
    }
  }, [filterParameters, defaultFilter, searchParams, updateFilterResults]);

  return {
    filter: filterParameters as ParamsType,
    setOrUpdateParams,
    clearFilter: resetFilter,
    pagination: calculatedPagination,
    rows: rows as RowType[],
    fetching: filterFetching,
    refreshFilter,
    exportCsv: () => exportCsv(filterParameters),
    isExportingCsv
  };
};

export default useDashboardFilter;
