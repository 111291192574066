import { Account } from 'types/account';
import { Feature } from 'types/features';
import { NavItemType } from 'types/menu';

export const filterMenuItems = (
  item: NavItemType,
  account: Account | undefined,
  userAccountRole: number,
  features: Feature[],
  env: any
) => {
  // If no account in context, then hide all menu items
  if (!account) return false;
  // If the user does not have the required account role, hide menu item
  if (item.requiredAccountRole && item.requiredAccountRole < userAccountRole) return false;
  // if the menu item is not for the account in context then hide
  if (item.accountType && item.accountType !== account?.accountTypeId) return false;
  // if the menu item is feature controlled and the feature is not enabled then hide
  if (item.featureFlag && features && !features.find((f) => f.name === item.featureFlag)?.enabled) return false;
  // if the menu item is hidden in the env then hide
  if (env.REACT_APP_HIDDEN_MENU_ITEMS?.includes(item.id)) return false;

  return true;
};
