import { AddCircleOutlineOutlined, RemoveCircleOutline } from '@mui/icons-material';
import { Stack, TextField } from '@mui/material';
import IconButton from 'components/@extended/IconButton';
import { useState } from 'react';

export interface FormNumberInputProps {
  name: string;
  label?: string;
  initialValue?: number;
  disabled?: boolean;
  onChange: (event: FormNumberInputChangeEvent) => void;
}

export interface FormNumberInputChangeEvent {
  value: number;
  name: string;
}

const FormNumberInput = (props: FormNumberInputProps) => {
  const [quantity, setQuantity] = useState<number>(props.initialValue ?? 0);

  const handleTextChange = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      let parsedValue = parseInt(e.target.value);

      if (isNaN(parsedValue)) {
        parsedValue = 0;
      }

      setQuantity(parsedValue);
    }
  };

  const handleBlur = (value: number) => {
    props.onChange({ name: props.name, value: value });
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <IconButton
        variant="text"
        size="small"
        disabled={props.disabled}
        onClick={() => {
          setQuantity(quantity - 1);
          props.onChange({ name: props.name, value: quantity - 1 });
        }}
      >
        <RemoveCircleOutline fontSize="small" color="warning" />
      </IconButton>
      <TextField
        size="small"
        id={props.name}
        name={props.name}
        variant="outlined"
        value={quantity}
        disabled={props.disabled}
        autoComplete="off"
        onChange={handleTextChange}
        onBlur={(e) => handleBlur(parseInt(e.target.value))}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            props.onChange({ name: props.name, value: quantity });
          }
        }}
        onFocus={(e) => e.target.select()}
        inputProps={{ min: 0, style: { textAlign: 'center' } }}
        sx={{ width: '75px' }}
      />

      <IconButton
        variant="text"
        size="small"
        disabled={props.disabled}
        onClick={() => {
          setQuantity(quantity + 1);
          props.onChange({ name: props.name, value: quantity + 1 });
        }}
      >
        <AddCircleOutlineOutlined fontSize="small" color="success" />
      </IconButton>
    </Stack>
  );
};

export default FormNumberInput;
