import { formatDashboardBaseUrl } from 'utils/urlUtils';
import useActiveAccount from './useActiveAccount';

const useBasePath = () => {
  const account = useActiveAccount();

  const getBasePath = (path?: string): string => {
    const trimmedPath = path?.replace(/^\/+/, '') ?? '';

    const basePath = formatDashboardBaseUrl(account!.accountCode, account!.accountType);

    return `${basePath}${trimmedPath}`;
  };

  return getBasePath;
};

export default useBasePath;
