import { CustomerSnapshot } from 'types/snapshots/customerSnapshot';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dispatch } from 'store';
import axiosServices from 'utils/api/axiosServices';
import { ApiResponse } from 'types/api';

export interface CustomerSnapshotProps {
  isLoading: boolean;
  snapshot: CustomerSnapshot | null;
  isSendingStatement: boolean;
}

const initialState: CustomerSnapshotProps = {
  isLoading: true,
  snapshot: null,
  isSendingStatement: false
};

const slice = createSlice({
  name: 'customerSnapshot',
  initialState: { ...initialState },
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setSnapshotData(state, action: PayloadAction<CustomerSnapshot>) {
      state.snapshot = action.payload;
    }
  }
});

export default slice.reducer;

export function loadCustomerSnapshot() {
  return async () => {
    try {
      dispatch(slice.actions.setLoading(true));

      const response = await axiosServices.get<ApiResponse<CustomerSnapshot>>('customer-statement-snapshot');

      dispatch(slice.actions.setSnapshotData(response.data.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setLoading(false));
    }
  };
}
