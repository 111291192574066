const embedExternalScript = (url, callback) => {
  // Check if the script is already loaded
  if (document.querySelector(`script[src="${url}"]`)) {
    callback?.();
    return;
  }

  // Load the script
  var script = document.createElement('script');
  script.src = url;
  if (callback) {
    script.onload = callback;
  }
  var head = document.getElementsByTagName('head')[0];
  head.appendChild(script);
};
export default embedExternalScript;
