import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import { useEffect } from 'react';
import { dispatch, resetPageState, useSelector } from 'store';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import usePrevious from 'hooks/usePrevious';
import AlertDialog from 'components/alert-dialog/AlertDialog';
import useBasePath from 'hooks/useBasePath';
import { useLocation } from 'react-router';
import useActiveAccount from 'hooks/useActiveAccount';
import { clearCustomerAlerts, updateCustomerAlerts } from 'store/reducers/customer-alerts';
import AccountGuard from 'AccountGuard';
import AppInitialisationGuard from 'AppInitialisationGuard';
import useAuth from 'hooks/useAuth';
import { addToRecentlyUsedAccounts, loadRecentlyUsedAccounts } from 'store/reducers/recentlyUsedAccounts';
import { loadTopicSubscriptions } from 'store/reducers/notification-topics';
import useActivePermissionLevel from 'hooks/useActivePermissionLevel';
import { AccountPermissionLevel } from 'types/account';
import { AccountType } from 'types/account';

const MainAppContent = () => {
  const getBasePath = useBasePath();
  const location = useLocation();
  const menu = useSelector((state) => state.menu);
  const alertsState = useSelector((state) => state.customerAlerts);
  const notificationTopicsState = useSelector((state) => state.notificationTopics);
  const previousOpenItem = usePrevious<string[]>(menu.openItem);
  const activeAccount = useActiveAccount();
  const activePermissionLevel = useActivePermissionLevel();
  const auth = useAuth();

  useEffect(() => {
    dispatch(loadRecentlyUsedAccounts());
  }, []);

  useEffect(() => {
    // @ts-ignore
    window.GorgiasChat?.captureUserEmail(auth.user?.email);
  }, [auth.user?.email]);

  // Handle any global changes when the navigation menu item is changed
  useEffect(() => {
    if (previousOpenItem && menu.openItem[0] === previousOpenItem[0]) return;

    resetPageState(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousOpenItem, menu.openItem, dispatch]);

  // Side effects when page or active account changes
  useEffect(() => {
    dispatch(clearCustomerAlerts());
    dispatch(updateCustomerAlerts());

    const timerId = setInterval(() => {
      if (!activeAccount) return;
      if (alertsState.isFetching) return;
      if (activeAccount.accountTypeId !== AccountType.Customer) return;
      dispatch(updateCustomerAlerts());
    }, 1000 * 60 * 1);

    if (activeAccount) dispatch(addToRecentlyUsedAccounts(activeAccount));

    if (!alertsState.isFetching) {
      dispatch(updateCustomerAlerts());
    }

    return () => {
      clearInterval(timerId);
    };
  }, [activeAccount, location.pathname, activeAccount]);

  // Side effects when active account changes
  useEffect(() => {
    if (!activeAccount) return;

    if (!notificationTopicsState.isFetching && activePermissionLevel === AccountPermissionLevel.Employee) {
      dispatch(loadTopicSubscriptions());
    }
  }, [activeAccount]);

  return (
    <>
      <Routes basePath={getBasePath()} />

      <AlertDialog />
    </>
  );
};

const App = () => {
  return (
    <ThemeCustomization>
      <Locales>
        <ScrollTop>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Snackbar />
            <AppInitialisationGuard>
              <AccountGuard>
                <MainAppContent />
              </AccountGuard>
            </AppInitialisationGuard>
          </LocalizationProvider>
        </ScrollTop>
      </Locales>
    </ThemeCustomization>
  );
};

export default App;
