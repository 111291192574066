import { Configuration, LogLevel } from '@azure/msal-browser';

const env = window.__RUNTIME_CONFIG__;

const b2cPolicies = {
  authorities: {
    signUpSignIn: {
      authority: `https://${env.REACT_APP_B2C_AUTHORITY_DOMAIN}/${env.REACT_APP_B2C_TENANT_DOMAIN}/${env.REACT_APP_B2C_SIGNINSIGNUP_FLOW}`
    }
  },
  authorityDomain: env.REACT_APP_B2C_AUTHORITY_DOMAIN as string
};

const protectedResources = {
  direktekDashboardApi: {
    scopes: [`https://${env.REACT_APP_B2C_TENANT_DOMAIN}/${env.REACT_APP_B2C_API_APP_NAME}/${env.REACT_APP_B2C_API_ACCESS_SCOPE}`]
  }
};

export const msalConfig: Configuration = {
  auth: {
    clientId: env.REACT_APP_B2C_CLIENT_ID as string,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: env.REACT_APP_B2C_REDIRECT_URI as string
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
        }
      }
    }
  }
};

export const loginRequestOptions = {
  scopes: [...protectedResources.direktekDashboardApi.scopes]
};
