import { Button, Grid, Link, Stack, Typography } from '@mui/material';
import LoadingButton from 'components/@extended/LoadingButton';
import Loader from 'components/Loader';
import MainCard from 'components/MainCard';
import TitleAndCaptionRegion from 'components/TitleAndCaption/TitleAndCaptionRegion';
import ActionBar from 'components/action-bar/ActionBar';
import { FilterSelect } from 'components/filter-select/filter-select';
import { DashboardTable } from 'components/third-party/DashboardTable';
import { useEffect, useMemo, useState } from 'react';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { ApiResponse, ApiResponseNoData, LookupItem } from 'types/api';
import {
  SalesOrderFileImportDto,
  SalesOrderImportHistoryItemDto,
  SalesOrderImportHistoryItemStatus,
  SalesOrderImportOptionDto
} from 'types/pages/sales-orders';
import axiosServices from 'utils/api/axiosServices';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import FileDragAndDrop from 'components/FileDragAndDrop/FileDragAndDrop';

const toLookupItems = (templates: SalesOrderImportOptionDto[]): LookupItem[] => {
  return templates.map((template) => {
    return {
      value: template.mappingId.toString(),
      displayValue: template.name
    };
  });
};

const importHistoryDays = 7;

const StatusIcon = (props: { status: SalesOrderImportHistoryItemStatus }) => {
  switch (props.status) {
    case SalesOrderImportHistoryItemStatus.Success:
      return <CheckCircleTwoToneIcon color="success" />;
    case SalesOrderImportHistoryItemStatus.PartialFailure:
      return <WarningTwoToneIcon color="warning" />;
    case SalesOrderImportHistoryItemStatus.Failure:
      return <ErrorTwoToneIcon color="error" />;
    case SalesOrderImportHistoryItemStatus.Processing:
      return <AccessTimeTwoToneIcon color="secondary" />;
    default:
      return <CheckCircleTwoToneIcon color="disabled" />;
  }
};

const SalesOrderImportPage = () => {
  const [loadingPage, setLoadingPage] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [templates, setTemplates] = useState<SalesOrderImportOptionDto[]>([]);
  const [options, setOptions] = useState<LookupItem[]>([]);
  const [selectedOption, setSelectedOption] = useState<LookupItem | null>(null);
  const [fileToProcess, setFileToProcess] = useState<File | null>(null);
  const [loadingHistory, setLoadingHistory] = useState<boolean>(false);
  const [history, setHistory] = useState<SalesOrderImportHistoryItemDto[]>([]);

  const selectedTemplate = templates.find((t) => t.mappingId.toString() === selectedOption?.value);

  const columns = useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'status',
        sortable: false,
        disableSortBy: true,
        Cell: ({ row }: any) => {
          return (
            <Stack>
              <StatusIcon status={row.original.status} />
            </Stack>
          );
        }
      },
      {
        Header: 'File',
        accessor: 'fileName',
        sortable: false,
        disableSortBy: true,
        Cell: ({ row }: any) => {
          return (
            <Stack maxWidth={150}>
              <Typography variant="body2" fontWeight={600}>
                {row.original.fileName}
              </Typography>
            </Stack>
          );
        }
      },
      {
        Header: 'Message',
        accessor: 'message',
        sortable: false,
        disableSortBy: true,
        Cell: ({ row }: any) => {
          return (
            <Stack>
              <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                {row.original.message}
              </Typography>
            </Stack>
          );
        }
      },
      {
        Header: 'Date',
        accessor: 'date',
        sortable: false,
        disableSortBy: true,
        Cell: ({ row }: any) => {
          return (
            <Stack minWidth={125}>
              <Typography variant="body2">{new Date(row.original.date).toLocaleString()}</Typography>
            </Stack>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const loadPage = async () => {
    const request: string = `sales-orders/import-options`;

    try {
      setLoadingPage(true);

      const response = await axiosServices.get<ApiResponse<SalesOrderImportOptionDto[]>>(request);
      setTemplates(response.data.data);
      setOptions(toLookupItems(response.data.data));
    } finally {
      setLoadingPage(false);
    }
  };

  const loadImportHistory = async () => {
    const request: string = `sales-orders/import-history?daysHistory=${importHistoryDays}`;

    try {
      setLoadingHistory(true);

      const response = await axiosServices.get<ApiResponse<SalesOrderImportHistoryItemDto[]>>(request);

      setHistory(response.data.data);
    } finally {
      setLoadingHistory(false);
    }
  };

  const resetPage = () => {
    setSelectedOption(null);
    setFileToProcess(null);
    setLoadingPage(false);
    setProcessing(false);
  };

  // @ts-ignore
  const handleUploadFile = (filesInput: File[]) => {
    setFileToProcess(null);

    if (!filesInput) {
      return;
    }
    if (!selectedTemplate) return;

    setFileToProcess(filesInput[0]);
  };

  const handleProcess = async () => {
    if (!fileToProcess) return;

    var formData = new FormData();

    const importDto: SalesOrderFileImportDto = {
      mappingId: selectedTemplate?.mappingId ?? 0,
      fileName: fileToProcess.name,
      fileBlob: fileToProcess
    };

    formData.append('mappingId', importDto.mappingId.toString());
    formData.append('fileName', importDto.fileName);
    formData.append('fileBlob', importDto.fileBlob);

    const request: string = `sales-orders/import`;

    try {
      setProcessing(true);
      await axiosServices.post<ApiResponse<ApiResponseNoData>>(request, formData);

      resetPage();

      dispatch(
        openSnackbar({
          open: true,
          message: `We're processing your order file. If there are any issues we will let you know.`,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true
        })
      );

      loadImportHistory();
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    loadPage();
    loadImportHistory();
  }, []);

  return (
    <Stack>
      <ActionBar mainRegion={<TitleAndCaptionRegion titleFontVariant="h5" title="Import" caption="Import your orders" />} />
      {loadingPage && <Loader />}

      <Stack spacing={1}>
        <MainCard
          title={
            <TitleAndCaptionRegion titleFontVariant="h6" title="Instructions" caption="Follow the steps below to import your orders" />
          }
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Stack spacing={1}>
                <Typography variant="body1" fontWeight={500}>
                  1. Select your import template
                </Typography>
                <FilterSelect
                  label="Select template"
                  options={options ?? []}
                  disabled={loadingPage || processing}
                  selected={options?.find((p) => p.value === selectedOption?.value) ?? null}
                  onSelected={(option) => {
                    resetPage();
                    setSelectedOption(option);
                  }}
                  hideClearOption
                />
                {!!selectedTemplate && <Typography variant="body2">{selectedTemplate.description}</Typography>}
              </Stack>
            </Grid>
            {!!selectedTemplate && (
              <>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <Stack>
                      <Typography variant="body1" fontWeight={500}>
                        2. Upload your file
                      </Typography>
                      <Typography variant="body2">
                        You can download a template file{' '}
                        <Link target="_blank" href={selectedTemplate.templateUrl}>
                          here
                        </Link>{' '}
                        to get started
                      </Typography>
                    </Stack>
                    <FileDragAndDrop
                      disabled={loadingPage || processing}
                      onFileDrop={(files) => handleUploadFile(files)}
                      selectedFiles={fileToProcess === null ? null : [fileToProcess]}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <Stack>
                      <Typography variant="body1" fontWeight={500}>
                        3. Click the button below to start the import
                      </Typography>
                      <Typography variant="body2">
                        Once the processing has started you can navigate away from this page and check the import history below for updates.
                      </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                      <LoadingButton
                        loading={processing}
                        disabled={!fileToProcess || processing}
                        variant="dashed"
                        color={fileToProcess ? 'success' : 'secondary'}
                        onClick={() => handleProcess()}
                      >
                        Start Import
                      </LoadingButton>
                      <Button disabled={processing} variant="dashed" color={!processing ? 'warning' : 'secondary'} onClick={resetPage}>
                        Clear
                      </Button>
                    </Stack>
                  </Stack>
                </Grid>
              </>
            )}
          </Grid>
        </MainCard>

        <MainCard
          content={false}
          title={
            <Stack justifyContent="space-between" alignItems="center" direction="row">
              <TitleAndCaptionRegion
                titleFontVariant="h6"
                title="Recent Import History"
                caption={`Your recent imports from the last ${importHistoryDays} days`}
              />
              <Button color="primary" onClick={loadImportHistory} variant="text">
                Refresh
              </Button>
            </Stack>
          }
        >
          <DashboardTable
            columns={columns}
            rows={history}
            isFetching={loadingHistory}
            hideHeaders
            noResultsRegion={
              <Stack>
                <Typography variant="subtitle2">No recent imports.</Typography>
              </Stack>
            }
          />
        </MainCard>
      </Stack>
    </Stack>
  );
};

export default SalesOrderImportPage;
