import { RootState, useSelector } from 'store';

export interface FeatureToggleProps {
  feature: string;
  children: React.ReactNode;
}

const FeatureToggle = (props: FeatureToggleProps) => {
  const featuresState = useSelector((state: RootState) => state.features);

  if (!featuresState.isLoaded) return <></>;
  if (featuresState.isFetching) return <></>;

  const feature = featuresState.features.find((f) => f.name === props.feature);

  if (!feature) return <></>;
  if (!feature.enabled) return <></>;

  return <>{props.children}</>;
};

export default FeatureToggle;
