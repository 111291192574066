import { useSelector } from 'store';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, List, Typography } from '@mui/material';

// project import
import NavItem from './NavItem';
import NavCollapse from './NavCollapse';

// types
import { NavItemType } from 'types/menu';
import useActivePermissionLevel from 'hooks/useActivePermissionLevel';
import React from 'react';
import { filterMenuItems } from './navigation-fns';
import useEnv from 'hooks/useEnv';
import useActiveAccount from 'hooks/useActiveAccount';

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

interface Props {
  item: NavItemType;
}

const NavGroup = ({ item }: Props) => {
  const theme = useTheme();
  const menu = useSelector((state) => state.menu);
  const features = useSelector((state) => state.features);
  const { drawerOpen } = menu;
  const userAccountRole = useActivePermissionLevel();
  const activeAccount = useActiveAccount();
  const env = useEnv();

  const navGroup = React.useMemo(() => {
    return item.children
      ?.filter((menuItem) => filterMenuItems(menuItem, activeAccount, userAccountRole, features.features, env))
      ?.map((menuItem) => {
        switch (menuItem.type) {
          case 'collapse':
            return <NavCollapse key={menuItem.id} menu={menuItem} level={1} />;
          case 'item':
            return <NavItem key={menuItem.id} item={menuItem} level={1} />;
          default:
            return (
              <Typography key={menuItem.id} variant="h6" color="error" align="center">
                Fix - Group Collapse or Items
              </Typography>
            );
        }
      });
  }, [activeAccount, item, userAccountRole, features, env]);

  return (
    <List
      subheader={
        navGroup &&
        navGroup.length > 0 &&
        item.title &&
        drawerOpen && (
          <Box sx={{ pl: 3, mb: 1.5 }}>
            <Typography variant="subtitle2" color={theme.palette.mode === 'dark' ? 'textSecondary' : 'text.secondary'}>
              {item.title}
            </Typography>
            {item.caption && (
              <Typography variant="caption" color="secondary">
                {item.caption}
              </Typography>
            )}
          </Box>
        )
      }
      sx={{ mt: drawerOpen && item.title ? 1.5 : 0, py: 0, zIndex: 0 }}
    >
      {navGroup}
    </List>
  );
};

export default NavGroup;
