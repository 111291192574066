// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { LocalGroceryStoreOutlined, LocalShippingOutlined, ReceiptOutlined, KeyboardReturnOutlined } from '@mui/icons-material';

// type
import { NavItemType } from 'types/menu';
import { AccountPermissionLevel, AccountType } from 'types/account';

import { CREATE_SALES_ORDER_FEATURE, SALES_ORDER_IMPORT_FEATURE } from 'featureConstants';
import { MenuItemIds } from './menu-item-ids';
import Routes from 'routes/RouteNames';

// icons
const icons = {
  LocalGroceryStoreOutlined,
  LocalShippingOutlined,
  ReceiptOutlined,
  KeyboardReturnOutlined
};

const orders: NavItemType = {
  id: MenuItemIds.OrdersGroupItemId,
  title: <FormattedMessage id={MenuItemIds.OrdersGroupItemId} />,
  type: 'group',
  children: [
    {
      id: MenuItemIds.OrdersNavItemId,
      title: <FormattedMessage id={MenuItemIds.OrdersNavItemId} />,
      type: 'collapse',
      url: `/${Routes.Orders}`,
      icon: icons.LocalGroceryStoreOutlined,
      underConstruction: [AccountType.Supplier],
      accountType: AccountType.Customer,
      breadcrumbs: false,
      children: [
        {
          id: MenuItemIds.CreateOrderNavItemId,
          title: <FormattedMessage id={MenuItemIds.CreateOrderNavItemId} />,
          type: 'item',
          breadcrumbs: false,
          underConstruction: [AccountType.Supplier],
          requiredAccountRole: AccountPermissionLevel.User,
          featureFlag: CREATE_SALES_ORDER_FEATURE,
          accountType: AccountType.Customer,
          url: `/${Routes.CreateOrder}`
        },
        {
          id: MenuItemIds.OrderImportNavItemId,
          title: <FormattedMessage id={MenuItemIds.OrderImportNavItemId} />,
          type: 'item',
          breadcrumbs: false,
          underConstruction: [AccountType.Supplier],
          requiredAccountRole: AccountPermissionLevel.User,
          featureFlag: SALES_ORDER_IMPORT_FEATURE,
          accountType: AccountType.Customer,
          url: `/${Routes.OrderImport}`
        },
        {
          id: MenuItemIds.OrderDraftsNavItemId,
          title: <FormattedMessage id={MenuItemIds.OrderDraftsNavItemId} />,
          type: 'item',
          breadcrumbs: false,
          underConstruction: [AccountType.Supplier],
          requiredAccountRole: AccountPermissionLevel.User,
          featureFlag: CREATE_SALES_ORDER_FEATURE,
          accountType: AccountType.Customer,
          url: `/${Routes.OrderDrafts}`
        }
      ]
    },
    {
      id: MenuItemIds.DeliveriesNavItemId,
      title: <FormattedMessage id={MenuItemIds.DeliveriesNavItemId} />,
      type: 'item',
      url: `/${Routes.Deliveries}`,
      icon: icons.LocalShippingOutlined,
      underConstruction: [AccountType.Supplier],
      accountType: AccountType.Customer,
      breadcrumbs: false
    },
    {
      id: MenuItemIds.InvoicesNavItemId,
      title: <FormattedMessage id={MenuItemIds.InvoicesNavItemId} />,
      type: 'item',
      url: `/${Routes.Invoices}`,
      icon: icons.ReceiptOutlined,
      underConstruction: [AccountType.Supplier],
      accountType: AccountType.Customer,
      breadcrumbs: false
    },
    {
      id: MenuItemIds.ReturnsNavItemId,
      title: <FormattedMessage id={MenuItemIds.ReturnsNavItemId} />,
      type: 'item',
      url: `/${Routes.Returns}`,
      icon: icons.KeyboardReturnOutlined,
      underConstruction: [AccountType.Supplier],
      accountType: AccountType.Customer,
      breadcrumbs: false
    }
  ]
};

export default orders;
