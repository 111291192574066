import { Stack, Typography } from '@mui/material';

export interface StockIdentifierProps {
  sku?: string | null;
  mpn?: string;
  barcodeEan?: string;
  customerSku?: string;
  grade?: string;
  serialNumbers?: string[];
  strikethrough?: boolean;
  fontVariant?: string;
}

const StockIdentifier = (props: { label: string; value: string; variant?: string; strikethrough?: boolean }) => {
  return (
    <>
      {
        <Typography
          // @ts-ignore
          variant={props.variant ?? 'body2'}
          color="secondary"
          fontWeight={200}
          sx={{ textDecoration: props.strikethrough ? 'line-through' : '' }}
        >
          {props.label}
        </Typography>
      }
      {
        // @ts-ignore
        <Typography variant={props.variant ?? 'body2'} fontWeight={600} sx={{ textDecoration: props.strikethrough ? 'line-through' : '' }}>
          {props.value}
        </Typography>
      }
    </>
  );
};

const StockIdentifiers = (props: StockIdentifierProps) => {
  return (
    <>
      {props.grade && (
        <Stack direction="row" gap={1}>
          <StockIdentifier label="Grade:" value={props.grade} variant={props.fontVariant} strikethrough={props.strikethrough} />
        </Stack>
      )}
      {props.sku && (
        <Stack direction="row" gap={1}>
          {props.sku && <StockIdentifier label="Code" value={props.sku} variant={props.fontVariant} strikethrough={props.strikethrough} />}
        </Stack>
      )}
      {props.mpn && (
        <Stack direction="row" gap={1}>
          {props.mpn && <StockIdentifier label="MPN" value={props.mpn} variant={props.fontVariant} strikethrough={props.strikethrough} />}
        </Stack>
      )}
      {props.barcodeEan && (
        <Stack direction="row" gap={1}>
          {props.barcodeEan && (
            <StockIdentifier label="EAN" value={props.barcodeEan} variant={props.fontVariant} strikethrough={props.strikethrough} />
          )}
        </Stack>
      )}
      {props.customerSku && (
        <Stack direction="row" gap={1}>
          {props.customerSku && (
            <StockIdentifier label="Your SKU" value={props.customerSku} variant={props.fontVariant} strikethrough={props.strikethrough} />
          )}
        </Stack>
      )}
      {props.serialNumbers?.some((n) => n) && (
        <Stack direction="row" gap={1}>
          <StockIdentifier
            label="Serial Number(s):"
            value={props.serialNumbers?.map((n) => n).join(', ')}
            strikethrough={props.strikethrough}
          />
        </Stack>
      )}
    </>
  );
};

export default StockIdentifiers;
