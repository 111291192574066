import { ProductStockDto } from 'types/pages/products';
import RangedStatusChip from './RangedStatusChip';
import ProposedStatusChip from './ProposedStatusChip';
import DiscontinuedStatusChip from './DiscontinuedStatusChip';
import BundledStatusChip from './BundledStatusChip';
import ClearanceStatusChip from './ClearanceStatusChip';
import { Chip } from '@mui/material';
import TooltipIcon from 'components/tooltip-icon/TooltipIcon';

export interface StockStatusChipsProps {
  productStock: ProductStockDto | null;
  size: 'small' | 'medium';
}

const StockStatusChips = (props: StockStatusChipsProps) => {
  if (!props.productStock) return <></>;

  return (
    <>
      {props.productStock.product.approvalRequired && (
        <Chip
          deleteIcon={<TooltipIcon message="Please contact us for approval prior to ordering" color="error" />}
          label="Approval Required"
          size={props.size}
          variant="combined"
          color="error"
          onDelete={() => {}}
        />
      )}
      {props.productStock.product.isBundle && <BundledStatusChip variant="light" size={props.size} />}
      {props.productStock.isClearance && <ClearanceStatusChip variant="light" size={props.size} />}
      {props.productStock.isDiscontinued && <DiscontinuedStatusChip variant="light" size={props.size} />}
      {props.productStock.ranged && <RangedStatusChip size={props.size} />}
      {props.productStock.proposed && <ProposedStatusChip size={props.size} />}
    </>
  );
};

export default StockStatusChips;
