import { createSlice } from '@reduxjs/toolkit';
import { RootState, dispatch } from 'store';
import { NotificationTopicSubscriptions } from 'types/notification-topics';
import axiosServices from 'utils/api/axiosServices';

export interface NotificationTopicsState {
  subscriptions: NotificationTopicSubscriptions | null;
  isFetching: boolean;
}

export const initialState: NotificationTopicsState = {
  subscriptions: null,
  isFetching: false
};

const notificationTopics = createSlice({
  name: 'notificationTopics',
  initialState,
  reducers: {
    setEmployeeSubscribed(state, action) {
      state.subscriptions = action.payload;
    },
    setFetching(state, action) {
      state.isFetching = action.payload;
    }
  }
});

export default notificationTopics.reducer;

export const { setEmployeeSubscribed } = notificationTopics.actions;

export function loadTopicSubscriptions() {
  return async () => {
    try {
      await dispatch(notificationTopics.actions.setFetching(true));

      const response = await axiosServices.get('notifications/employee/subscriptions');

      await dispatch(notificationTopics.actions.setEmployeeSubscribed(response.data.data));
    } catch (error) {
      console.error(error);
    } finally {
      await dispatch(notificationTopics.actions.setFetching(false));
    }
  };
}

export function toggleEmployeeTopicSubscription() {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const subscriptions = getState().notificationTopics.subscriptions;

      await dispatch(notificationTopics.actions.setFetching(true));

      var response = subscriptions?.employeeTopic
        ? await axiosServices.post('notifications/employee/unsubscribe')
        : await axiosServices.post('notifications/employee/subscribe');

      console.log('response', response);

      await dispatch(notificationTopics.actions.setEmployeeSubscribed(response.data.data));
    } catch (error) {
      console.error(error);
    } finally {
      await dispatch(notificationTopics.actions.setFetching(false));
    }
  };
}
