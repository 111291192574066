import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// third-party
import { Provider as ReduxProvider } from 'react-redux';

// scroll bar
import 'simplebar/src/simplebar.css';

// apex-chart
import 'assets/third-party/apex-chart.css';
import 'assets/third-party/react-table.css';

// project import
import App from './App';
import { store } from 'store';
import { ConfigProvider } from 'contexts/ConfigContext';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './b2cAuthConfig';
import { MsalProvider } from '@azure/msal-react';
import { AzureB2cContextProvider as AuthProvider } from 'contexts/AzureB2cContext';

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

const msalInstance = new PublicClientApplication(msalConfig);

const RenderApp = () => {
  ReactDOM.render(
    <ReduxProvider store={store}>
      <ConfigProvider>
        <MsalProvider instance={msalInstance}>
          <AuthProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </AuthProvider>
        </MsalProvider>
      </ConfigProvider>
    </ReduxProvider>,
    document.getElementById('root')
  );
};

RenderApp();
