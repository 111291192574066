import { AccountType } from 'types/account';
import PurchaseInvoicesPage from './purchase-invoice-page';
import useActiveAccount from 'hooks/useActiveAccount';
import SalesInvoicesPage from './sales-invoice-page';
import useDocumentTitle from 'hooks/useDocumentTitle';

const InvoicesPage = () => {
  useDocumentTitle('Invoices');
  const activeAccount = useActiveAccount();

  return activeAccount?.accountTypeId === AccountType.Customer ? <SalesInvoicesPage /> : <PurchaseInvoicesPage />;
};

export default InvoicesPage;
