// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { CategoryOutlined, LocalOfferOutlined, CampaignOutlined } from '@mui/icons-material';

// type
import { NavItemType } from 'types/menu';
import { AccountType } from 'types/account';
import { MenuItemIds } from './menu-item-ids';
import Routes from 'routes/RouteNames';
import { PROMOTIONS_FEATURE } from 'featureConstants';

// icons
const icons = {
  CategoryOutlined,
  LocalOfferOutlined,
  CampaignOutlined
};

const orders: NavItemType = {
  id: MenuItemIds.CatalogueGroupItemId,
  title: <FormattedMessage id={MenuItemIds.CatalogueGroupItemId} />,
  type: 'group',
  children: [
    {
      id: MenuItemIds.CatalogueProductsNavItemId,
      title: <FormattedMessage id={MenuItemIds.CatalogueProductsNavItemId} />,
      type: 'item',
      url: `/${Routes.Products}`,
      icon: icons.CategoryOutlined,
      underConstruction: [AccountType.Supplier],
      accountType: AccountType.Customer,
      breadcrumbs: false
    },
    {
      id: MenuItemIds.CataloguePromotionsNavItemId,
      title: <FormattedMessage id={MenuItemIds.CataloguePromotionsNavItemId} />,
      type: 'item',
      url: `/${Routes.Promotions}`,
      icon: icons.LocalOfferOutlined,
      underConstruction: [AccountType.Supplier],
      accountType: AccountType.Customer,
      breadcrumbs: false,
      featureFlag: PROMOTIONS_FEATURE
    },
    {
      id: MenuItemIds.CatalogueMarketingNavItemId,
      title: <FormattedMessage id={MenuItemIds.CatalogueMarketingNavItemId} />,
      type: 'item',
      url: `/${Routes.Marketing}`,
      icon: icons.CampaignOutlined,
      underConstruction: [AccountType.Supplier],
      accountType: AccountType.Customer,
      breadcrumbs: false
    }
  ]
};

export default orders;
