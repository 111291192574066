export interface LocalStorageItem<T> {
  value: T;
  expiry?: number;
}

export const setLocalStorage = <T>(key: string, value: any, ttl?: number) => {
  let item: LocalStorageItem<T> | null = null;

  if (!ttl) {
    item = {
      value
    };
  } else {
    const now = new Date();

    item = {
      value,
      expiry: now.getTime() + ttl
    };
  }

  localStorage.setItem(key, JSON.stringify(item));
};

export const getLocalStorage = <T>(key: string): LocalStorageItem<T> | null => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (item.expiry && now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return item as LocalStorageItem<T>;
};

export const clearLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};
