import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Routes from './RouteNames';
import InvoicesPage from 'pages/invoices/invoices-page';
import MarketingDetailsEditPage from 'pages/marketing/marketing-details-edit-page';
import OrderImportPage from 'pages/orders/order-import-page';

// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const HomePage = Loadable(lazy(() => import('pages/home/home-page')));
const InvoiceDetailPage = Loadable(lazy(() => import('pages/invoice-details/invoice-details-page')));
const OrdersPage = Loadable(lazy(() => import('pages/orders/orders-page')));
const DeliveriesPage = Loadable(lazy(() => import('pages/deliveries/deliveries-page')));
const DeliveryDetailsPage = Loadable(lazy(() => import('pages/deliveries/delivery-details-page')));
const ReturnsPage = Loadable(lazy(() => import('pages/returns/returns-page')));
const ReturnDetailsPage = Loadable(lazy(() => import('pages/returns/returns-details-page')));
const ProductStocksPage = Loadable(lazy(() => import('pages/stocks/product-list-page')));
const FeedsPage = Loadable(lazy(() => import('pages/feeds/feeds-page')));
const InboundIntegrationsPage = Loadable(lazy(() => import('pages/inbound-integrations/inbound-integrations-page')));
const OutboundIntegrationsPage = Loadable(lazy(() => import('pages/outbound-integrations/outbound-integrations-page')));
const IntegrationEventsPage = Loadable(lazy(() => import('pages/integration-events/integration-events-page')));
const AccountAdminPage = Loadable(lazy(() => import('pages/account-admin/account-page')));
const ProductPromotionsPage = Loadable(lazy(() => import('pages/product-promotions/product-promotions')));
const SalesPromotionDetailsPage = Loadable(lazy(() => import('pages/product-promotions/sales-promotion-details')));
const SalesPromotionClaimPage = Loadable(lazy(() => import('pages/product-promotions/sales-promotion-claim-page')));
const ReportsPage = Loadable(lazy(() => import('pages/reports/reports-page')));
const ReportPage = Loadable(lazy(() => import('pages/reports/report-page')));
const TeamAdminPage = Loadable(lazy(() => import('pages/team-admin/team-admin-page')));
const SalesOrderDetailsPage = Loadable(lazy(() => import('pages/orders/sales-order-details-page')));
const MarketingPage = Loadable(lazy(() => import('pages/marketing/marketing-page')));
const MarketingDetailsPage = Loadable(lazy(() => import('pages/marketing/market-details-page')));
const ProductDetailsPage = Loadable(lazy(() => import('pages/stocks/product-details-page')));
const CreateOrderPage = Loadable(lazy(() => import('pages/orders/order-create-page')));
const OrderDraftsPage = Loadable(lazy(() => import('pages/orders/order-drafts-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = (basePath: string) => {
  return {
    path: '/',
    children: [
      {
        path: '/',
        element: (
          <AuthGuard>
            <MainLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: `${basePath}${Routes.Home}`,
            element: <HomePage />
          },
          {
            path: `${basePath}${Routes.Invoices}`,
            element: <InvoicesPage />
          },
          {
            path: `${basePath}${Routes.Invoices}/:id`,
            element: <InvoiceDetailPage />
          },
          {
            path: `${basePath}${Routes.Team}`,
            element: <TeamAdminPage />
          },
          {
            path: `${basePath}${Routes.Orders}`,
            element: <OrdersPage />
          },
          {
            path: `${basePath}${Routes.OrderImport}`,
            element: <OrderImportPage />
          },
          // TODO : ORDER DETAILS PAGE
          {
            path: `${basePath}${Routes.Orders}/:id`,
            element: <SalesOrderDetailsPage />
          },
          {
            path: `${basePath}${Routes.OrderDrafts}`,
            element: <OrderDraftsPage />
          },
          {
            path: `${basePath}${Routes.OrderDrafts}/:id`,
            element: <CreateOrderPage />
          },
          {
            path: `${basePath}${Routes.CreateOrder}`,
            element: <CreateOrderPage />
          },
          {
            path: `${basePath}${Routes.Deliveries}`,
            element: <DeliveriesPage />
          },
          {
            path: `${basePath}${Routes.Deliveries}/:id`,
            element: <DeliveryDetailsPage />
          },
          {
            path: `${basePath}${Routes.Returns}`,
            element: <ReturnsPage />
          },
          {
            path: `${basePath}${Routes.Returns}/:id`,
            element: <ReturnDetailsPage />
          },
          {
            path: `${basePath}${Routes.Products}`,
            element: <ProductStocksPage />
          },
          {
            path: `${basePath}${Routes.Products}/:id`,
            element: <ProductDetailsPage />
          },
          {
            path: `${basePath}${Routes.Feeds}`,
            element: <FeedsPage />
          },
          {
            path: `${basePath}${Routes.InboundIntegrations}`,
            element: <InboundIntegrationsPage />
          },
          {
            path: `${basePath}${Routes.OutboundIntegrations}`,
            element: <OutboundIntegrationsPage />
          },
          {
            path: `${basePath}${Routes.IntegrationEvents}`,
            element: <IntegrationEventsPage />
          },
          {
            path: `${basePath}${Routes.Account}`,
            element: <AccountAdminPage />
          },
          {
            path: `${basePath}${Routes.Promotions}`,
            element: <ProductPromotionsPage />
          },
          {
            path: `${basePath}${Routes.Promotions}/:id`,
            element: <SalesPromotionDetailsPage />
          },
          {
            path: `${basePath}${Routes.Promotions}/:id/claim`,
            element: <SalesPromotionClaimPage />
          },
          {
            path: `${basePath}${Routes.Reports}`,
            element: <ReportsPage />
          },
          {
            path: `${basePath}${Routes.Reports}/:id`,
            element: <ReportPage />
          },
          {
            path: `${basePath}${Routes.Marketing}`,
            element: <MarketingPage />
          },
          {
            path: `${basePath}${Routes.Marketing}/create`,
            element: <MarketingDetailsEditPage />
          },
          {
            path: `${basePath}${Routes.Marketing}/edit/:id`,
            element: <MarketingDetailsEditPage />
          },
          {
            path: `${basePath}${Routes.Marketing}/:id`,
            element: <MarketingDetailsPage />
          },
          {
            path: '*',
            element: <HomePage />
          }
        ]
      },
      {
        path: '/maintenance',
        element: <MainLayout />,
        children: [
          {
            path: '404',
            element: <MaintenanceError />
          },
          {
            path: '500',
            element: <MaintenanceError500 />
          }
        ]
      }
    ]
  };
};

export default MainRoutes;
