export interface PaginationState {
  page: number;
  pageSize: number;
  totalPages?: number;
  totalCount?: number;
}

export enum SortDirection {
  None = 'none',
  ASC = 'asc',
  DESC = 'desc'
}

export interface SortByColumn {
  column?: string;
  sortDirection: SortDirection;
}
