import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { LookupItem } from 'types/api';

export interface FilterSelectProps {
  label?: string;
  options: LookupItem[];
  selected: LookupItem | null;
  disabled?: boolean;
  fullWidth?: boolean;
  menuHeight?: number;
  hideClearOption?: boolean;
  onSelected: (option: LookupItem | null) => void;
}

const CLEAR_VALUE = 'clear';

const clearOption: LookupItem = {
  value: CLEAR_VALUE,
  displayValue: 'Remove Filter'
};

export const FilterSelect: React.FC<FilterSelectProps> = (props) => {
  const handleChanged = (event: SelectChangeEvent) => {
    const matchingOption = props.options.find((o) => o.value === event.target.value);

    if (!matchingOption || matchingOption?.value === CLEAR_VALUE) {
      props.onSelected(null);
      return;
    }

    props.onSelected(matchingOption);
  };

  const allOptions = !props.hideClearOption ? [...props.options, clearOption] : props.options;

  return (
    <Select
      value={props.selected?.value ?? ''}
      onChange={handleChanged}
      size="small"
      displayEmpty
      fullWidth={props.fullWidth}
      MenuProps={{ sx: { height: props.menuHeight } }}
      disabled={props.disabled}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return (
            <Typography variant="body1" color="secondary">
              {props.label ?? ''}
            </Typography>
          );
        }

        return <Typography variant="body1">{props.selected?.displayValue}</Typography>;
      }}
    >
      {allOptions.map((o) => {
        if (o.value === CLEAR_VALUE) {
          return (
            <MenuItem key={o.value} value={o.value}>
              <Typography color="error" variant="body1">
                {o.displayValue}
              </Typography>
            </MenuItem>
          );
        }

        return (
          <MenuItem key={o.value} value={o.value}>
            <Typography variant="body1">{o.displayValue}</Typography>
          </MenuItem>
        );
      })}
    </Select>
  );
};
