import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dispatch } from 'store';
import { TeamRole } from 'types/account';
import { ApiResponse, CreateTeamUsers, editUserAccountLink as EditUserAccountLink } from 'types/api';
import { TeamUser } from 'types/pages/team';
import axiosServices from 'utils/api/axiosServices';
import { openSnackbar } from '../snackbar';

export interface TeamAdminProps {
  isFetching: boolean;
  isSendingInvite: boolean;
  roles: TeamRole[];
  teamUsers: TeamUser[];
}

const initialState: TeamAdminProps = {
  isFetching: false,
  isSendingInvite: false,
  roles: [],
  teamUsers: []
};

const slice = createSlice({
  name: 'teamAdmin',
  initialState: { ...initialState },
  reducers: {
    setTeamUsers(state, action: PayloadAction<TeamUser[]>) {
      state.teamUsers = action.payload;
    },
    setTeamRoles(state, action: PayloadAction<TeamRole[]>) {
      state.roles = action.payload;
    },
    setFetching(state, action: PayloadAction<boolean>) {
      state.isFetching = action.payload;
    },
    setSendingInvite(state, action: PayloadAction<boolean>) {
      state.isSendingInvite = action.payload;
    },
    resetTeamAdmin(state) {
      state.teamUsers = initialState.teamUsers;
      state.roles = initialState.roles;
      state.isFetching = initialState.isFetching;
      state.isSendingInvite = initialState.isSendingInvite;
    }
  }
});

export default slice.reducer;
export const { resetTeamAdmin } = slice.actions;

export function loadTeamAdmin() {
  return async () => {
    try {
      dispatch(slice.actions.setFetching(true));

      // TODO : Cache this request.
      const teamRoleResponse = await axiosServices.get<ApiResponse<TeamRole[]>>('teamUsers/roles');

      dispatch(slice.actions.setTeamRoles(teamRoleResponse.data.data));

      const teamUsersResponse = await axiosServices.get<ApiResponse<TeamUser[]>>('teamUsers');

      dispatch(slice.actions.setTeamUsers(teamUsersResponse.data.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setFetching(false));
    }
  };
}

export function resendTeamInvite(email: string) {
  return async () => {
    try {
      dispatch(slice.actions.setSendingInvite(true));

      await axiosServices.post(`TeamInvitation/${email}/resend`);

      dispatch(
        openSnackbar({
          open: true,
          message: `An inivitation email is being resent to ${email}.`,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setSendingInvite(false));
    }
  };
}

export function removeTeamUser(accountLinkId: string) {
  return async () => {
    try {
      dispatch(slice.actions.setFetching(true));

      await axiosServices.delete(`teamUsers/accountlinks/${accountLinkId}`);

      dispatch(
        openSnackbar({
          open: true,
          message: `The user has been removed from the team.`,
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setFetching(false));
    }
  };
}

export function createNewUser(createTeamUser: CreateTeamUsers) {
  return async () => {
    try {
      dispatch(slice.actions.setFetching(true));

      await axiosServices.post('teamUsers', createTeamUser);

      dispatch(
        openSnackbar({
          open: true,
          message: 'Team user added successfully',
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setFetching(false));
    }
  };
}

export function updateTeamRole(editUserAccountLink: EditUserAccountLink) {
  return async () => {
    try {
      dispatch(slice.actions.setFetching(true));
      dispatch(slice.actions.setTeamUsers([]));

      await axiosServices.put<ApiResponse<TeamUser>>('teamUsers/accountlinks', editUserAccountLink);

      dispatch(
        openSnackbar({
          open: true,
          message: 'Team user updated successfully',
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true
        })
      );

      await dispatch(loadTeamAdmin());
    } finally {
      dispatch(slice.actions.setFetching(false));
    }
  };
}
