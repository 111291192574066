import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { dispatch } from 'store';
import { Payment } from 'truelayer-embedded-payment-page';
import { ApiResponse } from 'types/api';
import { TrueLayerPaymentAuthRequest } from 'types/payments';
import axiosServices from 'utils/api/axiosServices';

export interface PaymentProps {
  isCreatingPayment: boolean;
}

const initialState: PaymentProps = {
  isCreatingPayment: false
};

const slice = createSlice({
  name: 'payments',
  initialState: { ...initialState },
  reducers: {
    setPaymentCreating(state, action: PayloadAction<boolean>) {
      state.isCreatingPayment = action.payload;
    },
    resetPayments(state) {
      state.isCreatingPayment = initialState.isCreatingPayment;
    }
  }
});

export function createPaymentRequest(amount: number, returnUrl: string, onDone: () => void, onError: (error: any) => void) {
  return async () => {
    try {
      dispatch(slice.actions.setPaymentCreating(true));

      const response = await axiosServices.post<ApiResponse<TrueLayerPaymentAuthRequest>>(`payments`, { amount, returnUrl });

      const paymentRequest = response.data.data;

      const payment = Payment({
        payment_id: paymentRequest.paymentId,
        resource_token: paymentRequest.resourceToken,
        return_uri: paymentRequest.returnUrl,
        open_bank_in_new_tab: true,
        production: window.__RUNTIME_CONFIG__.REACT_APP_TRUE_LAYER_PRODUCTION === 'true',
        debug_mode: window.__RUNTIME_CONFIG__.REACT_APP_TRUE_LAYER_DEBUG_MODE === 'true',
        onDone,
        onError
      });

      payment.start();
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setPaymentCreating(false));
    }
  };
}

export default slice.reducer;
