import Typography, { TypographyTypeMap } from '@mui/material/Typography';
import dayjs from 'dayjs';

export interface DateFieldProps {
  value: string | Date;
  format: string;
  fontVariant?: TypographyTypeMap['props']['variant'];
}

const DateField: React.FC<DateFieldProps> = (props) => {
  if (!props.value) return <></>;

  return <Typography variant={props.fontVariant ?? 'body1'}>{dayjs(props.value).format(props.format)}</Typography>;
};

export default DateField;
