import { Alert, Button, Stack, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

const AlertNotification = (props: { title: string; caption: string; action?: { title: string; action: () => void } | null }) => {
  return (
    <Stack mb={2}>
      <Alert
        color="error"
        variant="border"
        icon={<WarningIcon />}
        action={
          props.action && (
            <Stack direction="column" mt={0.9} mr={1}>
              <Button variant="contained" color="warning" onClick={() => props.action?.action()} sx={{ minHeight: 28, minWidth: 125 }}>
                <Typography variant="subtitle2">{props.action?.title}</Typography>
              </Button>
            </Stack>
          )
        }
      >
        <Typography lineHeight={1} variant="h6" fontWeight={800}>
          {props.title}
        </Typography>

        <Typography variant="caption">{props.caption}</Typography>
      </Alert>
    </Stack>
  );
};

export default AlertNotification;
