// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

import config from 'config';

// assets
import { resetUserSettings } from 'store/reducers/user-settings';
import { useDispatch } from 'store';
import { useNavigate } from 'react-router';

// ==============================|| ERROR - MAIN ||============================== //

export interface ErrorProps {
  message: string;
}

function GeneralError(props: ErrorProps) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const retryHandler = () => {
    dispatch(resetUserSettings());
    navigate(config.defaultPath);
  };

  return (
    <>
      <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: '100vh' }}>
        <Grid item xs={12}>
          <Stack justifyContent="center" alignItems="center">
            <Typography align="center" variant={matchDownSM ? 'h4' : 'h3'}>
              An error has occurred
            </Typography>
            <Typography color="textSecondary" variant="subtitle1" align="center" sx={{ width: { xs: '73%', sm: '70%' }, mt: 1 }}>
              {props.message}
            </Typography>
            <Button onClick={retryHandler} variant="contained" sx={{ textTransform: 'none', mt: 4 }}>
              Retry
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default GeneralError;
