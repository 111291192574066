import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';

// third-party
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';
import { useSelector } from 'store';
import { formatMoney } from 'utils/stringUtils';

const AgedDebtAnalysisChart = () => {
  const theme = useTheme();
  const customerSnapshot = useSelector((state) => state.customerSnapshot);

  var chartOptions = {
    chart: {
      id: 'aged-debt-analysis-chart',
      type: 'bar',
      toolbar: {
        show: false
      },
      height: 100,
      animations: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 1,
        horizontal: true,
        distributed: true
      }
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      formatter: (val: number, options: any) => {
        return formatMoney(customerSnapshot?.snapshot?.agedDebtAnalysis.currencySymbol ?? '£', val);
      },
      style: {
        colors: [theme.palette.text.primary, theme.palette.text.primary, theme.palette.text.primary, theme.palette.text.primary]
      }
    },
    tooltip: {
      fixed: {
        enabled: true
      },
      x: {
        show: false
      },
      marker: {
        show: false
      }
    },
    labels: ['Up to 30 Days', '31 - 60 Days', '61 - 90 Days', 'Over 91 Days'],
    xaxis: {
      show: false,
      crosshairs: {
        width: 0
      },
      labels: {
        show: false
      },
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: false
      }
    },
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
        top: -25,
        bottom: -23
      }
    },
    legend: {
      show: false
    },
    colors: [theme.palette.success.main, theme.palette.warning.main, theme.palette.warning.main, theme.palette.error.main]
  };

  const [options] = useState<ChartProps>(chartOptions);

  const [series] = useState([
    {
      name: 'Aged Debt',
      data: [
        customerSnapshot.snapshot?.agedDebtAnalysis.upto30Days ?? 0,
        customerSnapshot.snapshot?.agedDebtAnalysis.from31to60Days ?? 0,
        customerSnapshot.snapshot?.agedDebtAnalysis.from61to90Days ?? 0,
        customerSnapshot.snapshot?.agedDebtAnalysis.above91Days ?? 0
      ]
    }
  ]);

  return <ReactApexChart options={options} series={series} type="bar" height={100} />;
};

export default AgedDebtAnalysisChart;
