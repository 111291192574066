import { ArrowBackOutlined } from '@mui/icons-material';
import { Button, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import IconButton from './@extended/IconButton';

export interface BackNavigationProps {
  size?: 'small' | 'medium' | 'large';
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | 'default' | 'grey';
  style?: 'icon' | 'button' | null;
}

const BackNavigation = (props: BackNavigationProps) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const style = props.style ?? 'icon';

  if (style === 'icon') {
    return (
      <IconButton
        variant="outlined"
        size={props.size ?? 'large'}
        style={{ color: props.color ?? theme.palette.grey[300] }}
        aria-label="back"
        onClick={() => navigate(-1)}
      >
        <ArrowBackOutlined style={{ color: theme.palette.grey[900] }} />
      </IconButton>
    );
  } else {
    return (
      <Button
        // @ts-ignore
        color={props.color ?? 'inherit'}
        style={{ color: props.color ?? theme.palette.grey[500] }}
        variant="outlined"
        size={props.size ?? 'large'}
        startIcon={<ArrowBackOutlined style={{ color: theme.palette.grey[900] }} />}
        onClick={() => navigate(-1)}
      >
        <Typography variant="body1">Back</Typography>
      </Button>
    );
  }
};

export default BackNavigation;
