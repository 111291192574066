import { dispatch, useSelector } from 'store';
import { addToRecentlyUsedAccounts, clearRecentlyUsedAccounts } from 'store/reducers/recentlyUsedAccounts';
import { Account } from 'types/account';

const useRecentAccounts = () => {
  const recentAccountsState = useSelector((state) => state.recentlyUsedAccounts);

  return {
    accounts: recentAccountsState.accounts?.map((acc) => acc.account) ?? [],
    set: (account: Account) => dispatch(addToRecentlyUsedAccounts(account)),
    clear: () => dispatch(clearRecentlyUsedAccounts())
  };
};

export default useRecentAccounts;
