import { SalesOrderSummary } from 'types/pages/sales-orders';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dispatch, RootState } from 'store';
import axiosServices from 'utils/api/axiosServices';
import { ApiResponse, EventSummary, PagedList } from 'types/api';
import { GoodsOut } from 'types/pages/customer-deliveries';
import { RelatedSalesEntities } from 'types/related-entities';

export interface SalesOrderDetailsProps {
  summary: SalesOrderSummary | null;
  isSummaryFetching: boolean;
  deliveries: GoodsOut[] | null;
  isDeliveriesFetching: boolean;
  events: EventSummary[];
  isEventsFetching: boolean;
  related: RelatedSalesEntities | null;
  isRelatedFetching: boolean;
}

export const SalesOrderDetailsInitialState: SalesOrderDetailsProps = {
  summary: null,
  isSummaryFetching: false,
  deliveries: null,
  isDeliveriesFetching: false,
  events: [],
  isEventsFetching: false,
  related: null,
  isRelatedFetching: false
};

const slice = createSlice({
  name: 'salesOrderDetails',
  initialState: { ...SalesOrderDetailsInitialState },
  reducers: {
    setSummary(state, action: PayloadAction<SalesOrderSummary>) {
      state.summary = action.payload;
    },
    setSummaryFetching(state, action: PayloadAction<boolean>) {
      state.isSummaryFetching = action.payload;
    },
    setDeliveries(state, action: PayloadAction<GoodsOut[]>) {
      state.deliveries = action.payload;
    },
    setDeliveriesFetching(state, action: PayloadAction<boolean>) {
      state.isDeliveriesFetching = action.payload;
    },
    setEvents(state, action: PayloadAction<EventSummary[]>) {
      state.events = action.payload;
    },
    setEventsFetching(state, action: PayloadAction<boolean>) {
      state.isEventsFetching = action.payload;
    },
    setRelated(state, action: PayloadAction<RelatedSalesEntities>) {
      state.related = action.payload;
    },
    setRelatedFetching(state, action: PayloadAction<boolean>) {
      state.isRelatedFetching = action.payload;
    },
    resetSalesOrderSummary(state) {
      state.summary = SalesOrderDetailsInitialState.summary;
      state.deliveries = SalesOrderDetailsInitialState.deliveries;
      state.events = SalesOrderDetailsInitialState.events;
      state.related = SalesOrderDetailsInitialState.related;
      state.isSummaryFetching = SalesOrderDetailsInitialState.isSummaryFetching;
      state.isDeliveriesFetching = SalesOrderDetailsInitialState.isDeliveriesFetching;
      state.isEventsFetching = SalesOrderDetailsInitialState.isEventsFetching;
      state.isRelatedFetching = SalesOrderDetailsInitialState.isRelatedFetching;
    }
  }
});

export default slice.reducer;
export const { resetSalesOrderSummary } = slice.actions;

export function loadSalesOrderSummary(orderNumber: number) {
  return async () => {
    try {
      dispatch(slice.actions.setSummaryFetching(true));

      const response = await axiosServices.get<ApiResponse<SalesOrderSummary>>(`sales-orders/${orderNumber}`);

      dispatch(slice.actions.setSummary(response.data.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setSummaryFetching(false));
    }
  };
}

export function loadRelatedSalesEntities(orderNumber: number) {
  return async () => {
    try {
      dispatch(slice.actions.setRelatedFetching(true));

      const relatedResponse = await axiosServices.get<ApiResponse<RelatedSalesEntities>>(`sales-orders/${orderNumber}/related`);

      dispatch(slice.actions.setRelated(relatedResponse.data.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setRelatedFetching(false));
    }
  };
}

export function loadRelatedDeliveries(orderNumber: number) {
  return async () => {
    try {
      dispatch(slice.actions.setDeliveriesFetching(true));

      const response = await axiosServices.get<ApiResponse<PagedList<GoodsOut>>>(
        `customer-deliveries?entityType=ordernumbers&entities=${orderNumber}&pageSize=25`
      );

      dispatch(slice.actions.setDeliveries(response.data.data.items));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setDeliveriesFetching(false));
    }
  };
}

export function loadSalesOrdersEvents(orderNumber: number) {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(slice.actions.setEventsFetching(true));

      const response = await axiosServices.get<ApiResponse<EventSummary[]>>(`customer-events/sales-invoice/${orderNumber}`);

      dispatch(slice.actions.setEvents(response.data.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setEventsFetching(false));
    }
  };
}
