export enum AccountType {
  Customer = 1,
  Supplier = 2
}

export enum AccountPermissionLevel {
  Employee = 0,
  Admin = 1,
  User = 2,
  ViewOnly = 3
}

export interface Account {
  key: string;
  name: string;
  accountCode: string;
  accountTypeId: AccountType;
  accountType: string;
}

export interface TeamRole {
  id: number;
  name: string;
  description?: string;
}

export interface AccountRole {
  account: Account;
  teamRole: TeamRole;
}

export const AdminRole: TeamRole = {
  id: AccountPermissionLevel.Admin,
  name: 'Admin',
  description: ''
};
