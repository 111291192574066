import { InteractionRequiredAuthError, IPublicClientApplication } from '@azure/msal-browser';
import { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { loginRequestOptions } from '../../b2cAuthConfig';

const addAzureB2cTokenInterceptor = (axiosInstance: AxiosInstance, msalInstance: IPublicClientApplication) => {
  axiosInstance.interceptors.request.use(async (request: InternalAxiosRequestConfig) => {
    let apiToken: string | null = null;

    try {
      var tokenResponse = await msalInstance.acquireTokenSilent(loginRequestOptions);

      apiToken = tokenResponse.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        msalInstance.acquireTokenRedirect(loginRequestOptions);
      }
    }

    if (!apiToken) return request;

    let bearerToken = `Bearer ${apiToken}`;

    if (request.headers) {
      request.headers['Authorization'] = bearerToken;
    } else {
      // @ts-ignore
      request.headers.set('Authorization', bearerToken);
    }

    return request;
  });
};

export default addAzureB2cTokenInterceptor;
