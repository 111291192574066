import { ShoppingCartTwoTone } from '@mui/icons-material';
import { Box, Badge, ButtonBase, CircularProgress, Drawer, Stack, useMediaQuery, useTheme } from '@mui/material';
import PinnedOrderSummary from 'components/pinned-order-summary/PinnedOrderSummary';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'store';
import Avatar from 'components/@extended/Avatar';
import { assignPinnedOrder } from 'store/reducers/pinned-order';
import useDashboardNavigate from 'hooks/useDashboardNavigate';
import Routes from 'routes/RouteNames';

const PinnedOrderButton = () => {
  const pinnedOrderState = useSelector((state: RootState) => state.pinnedOrder);
  const theme = useTheme();
  const lgAndDown = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));
  const navigate = useDashboardNavigate();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const summaryOpen = Boolean(anchorEl);

  const cartItems = pinnedOrderState?.pinnedOrder?.products?.length ?? 0;

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Stack alignItems="center">
        <ButtonBase
          disabled={pinnedOrderState.isFetchingPinnedOrder}
          sx={{
            p: 0.25,
            px: 1,
            borderRadius: 1,
            '&:hover': { bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter' },
            '&:focus-visible': {
              outline: `2px solid ${theme.palette.secondary.dark}`,
              outlineOffset: 2
            }
          }}
          aria-label="open help"
          aria-haspopup="false"
          onClick={(ev) => (!summaryOpen ? handleOpen(ev) : handleClose())}
        >
          <Stack direction="row" spacing={1} alignItems="center" sx={{ p: 0.5 }}>
            {!pinnedOrderState.isFetchingPinnedOrder && !pinnedOrderState.isUpdatingPinnedOrder && (
              <Badge badgeContent={cartItems} color={cartItems <= 0 ? 'secondary' : 'info'} showZero>
                <Avatar alt="help" size="xs">
                  <ShoppingCartTwoTone fontSize="small" />
                </Avatar>
              </Badge>
            )}
            {(pinnedOrderState.isFetchingPinnedOrder || pinnedOrderState.isUpdatingPinnedOrder) && (
              <CircularProgress color="primary" size={20} />
            )}
          </Stack>
        </ButtonBase>
      </Stack>

      <Drawer
        anchor="right"
        open={summaryOpen}
        onClose={handleClose}
        PaperProps={{
          sx: { width: lgAndDown ? '80%' : '50%' }
        }}
      >
        <Stack pt={lgAndDown ? 0 : 8}>
          <PinnedOrderSummary
            onCheckout={() => {
              handleClose();
              navigate(`${Routes.OrderDrafts}/${pinnedOrderState!.pinnedOrder!.id}`);
            }}
            onClose={() => {
              handleClose();
            }}
            onClear={() => {
              dispatch(assignPinnedOrder(null));
            }}
          />
        </Stack>
      </Drawer>
    </Box>
  );
};

export default PinnedOrderButton;
