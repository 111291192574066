import { Stack, Typography } from '@mui/material';
import { Account } from 'types/account';

export interface AccountItemProps {
  account: Account | null;
}

export const AccountSelectItem: React.FC<AccountItemProps> = (props: AccountItemProps) => {
  return (
    <Stack direction="column" alignItems="flex-start">
      {!props.account && (
        <Typography variant="subtitle1" fontWeight="450" noWrap>
          No Account Selected
        </Typography>
      )}
      {props.account && (
        <>
          <Typography variant="subtitle1" fontWeight="450" noWrap>
            {props.account?.name} ({props.account?.accountCode})
          </Typography>
          <Typography pt={0} variant="subtitle2" noWrap>
            {props.account?.accountType}
          </Typography>
        </>
      )}
    </Stack>
  );
};
