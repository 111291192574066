import { useEffect, useState } from 'react';

export interface UseImageReturnType {
  loading: boolean;
  error: any | null;
  image: string | null;
}

const useImage = (fileNameAndPath: string): UseImageReturnType => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any | null>(null);
  const [image, setImage] = useState<string | null>(null);

  useEffect(() => {
    let isMounted = true;

    const fetchImage = async () => {
      try {
        const response = await import(`../assets/images/${fileNameAndPath}`);

        if (isMounted) {
          setImage(response.default);
          setLoading(false);
        }
      } catch (err) {
        if (isMounted) {
          setError(err);
          setLoading(false);
        }
      }
    };

    fetchImage();

    return () => {
      isMounted = false;
      setLoading(false);
    };
  }, [fileNameAndPath]);

  return {
    loading,
    error,
    image
  };
};

export default useImage;
