import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ApiResponse } from 'types/api';
import { SalesOrderDraftDto } from 'types/pages/sales-orders';
import axiosServices from 'utils/api/axiosServices';

export interface SalesOrdersDraftsProps {
  isFilterFetching: boolean;
  drafts: SalesOrderDraftDto[];
  isDeletingDraft: boolean;
}

export const SalesOrderDraftsInitialState: SalesOrdersDraftsProps = {
  isFilterFetching: false,
  drafts: [],
  isDeletingDraft: false
};

const slice = createSlice({
  name: 'salesOrderDrafts',
  initialState: { ...SalesOrderDraftsInitialState },
  reducers: {
    setFilterFetching(state, action: PayloadAction<boolean>) {
      state.isFilterFetching = action.payload;
    },
    setDrafts(state, action: PayloadAction<SalesOrderDraftDto[]>) {
      state.drafts = action.payload;
    },
    setIsDeletingDraft(state, action: PayloadAction<boolean>) {
      state.isDeletingDraft = action.payload;
    },
    resetSalesOrderDrafts: () => SalesOrderDraftsInitialState
  }
});

export default slice.reducer;
export const { resetSalesOrderDrafts } = slice.actions;

export function fetchSalesOrderDrafts() {
  return async (dispatch: any) => {
    try {
      dispatch(slice.actions.setFilterFetching(true));
      dispatch(slice.actions.setDrafts([]));

      const response = await axiosServices.get<ApiResponse<SalesOrderDraftDto[]>>('sales-orders/drafts');
      const drafts = response.data.data;

      dispatch(slice.actions.setDrafts(drafts));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setFilterFetching(false));
    }
  };
}

export function deleteSalesOrderDraft(draftId: number) {
  return async (dispatch: any) => {
    try {
      dispatch(slice.actions.setIsDeletingDraft(true));

      await axiosServices.delete(`sales-orders/drafts/${draftId}`);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setIsDeletingDraft(false));
    }
  };
}
