import { Chip } from '@mui/material';

export interface DisontinuedStatusChipProps {
  size: 'small' | 'medium';
  variant: 'filled' | 'outlined' | 'light' | 'combined';
}

const DisontinuedStatusChip = (props: DisontinuedStatusChipProps) => {
  return <Chip label="Discontinued" color="error" variant={props.variant} size={props.size} />;
};

export default DisontinuedStatusChip;
