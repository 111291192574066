import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { dispatch } from 'store';
import { ApiResponse } from 'types/api';
import { Feature } from 'types/features';
import axiosServices from 'utils/api/axiosServices';

export interface FeaturesState {
  features: Feature[];
  isFetching: boolean;
  isLoaded: boolean;
}

export const initialState: FeaturesState = {
  features: [],
  isFetching: false,
  isLoaded: false
};

const slice = createSlice({
  name: 'features',
  initialState: { ...initialState },
  reducers: {
    setFeatures(state, action: PayloadAction<Feature[]>) {
      state.features = action.payload;
    },
    setFetching(state, action: PayloadAction<boolean>) {
      state.isFetching = action.payload;
    },
    setIsLoaded(state, action: PayloadAction<boolean>) {
      state.isLoaded = action.payload;
    }
  }
});

export default slice.reducer;

export function loadFeatures() {
  return async () => {
    try {
      dispatch(slice.actions.setFetching(true));

      const response = await axiosServices.get<ApiResponse<Feature[]>>('features');

      dispatch(slice.actions.setFeatures(response.data.data));
      dispatch(slice.actions.setIsLoaded(true));

      return response.data.data;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setFetching(false));
    }
  };
}
