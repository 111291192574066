import { Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import LogoMain from 'components/logo/LogoMain';
import AccountSelector from 'layout/MainLayout/Header/HeaderContent/AccountSelector/AccountSelector';

const InvalidAccountPage = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
        <Grid item xs={12}>
          <LogoMain />
        </Grid>
        <Grid item xs={12}>
          <Stack justifyContent="center" alignItems="center">
            <Typography align="center" variant={matchDownSM ? 'h4' : 'h3'}>
              There was an error loading your account.
            </Typography>
            <Typography color="textSecondary" variant="subtitle1" align="center" sx={{ width: { xs: '73%', sm: '70%' }, mt: 1 }}>
              This error occurred for one of the following reasons; the account does not exist, you do not have permission to view it or the
              URL was malformed.
            </Typography>

            <Typography color="textSecondary" variant="subtitle1" align="center" sx={{ width: { xs: '73%', sm: '70%' }, mt: 1 }}>
              Please use the account selector below to select an appropriate account:
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} alignItems="center" justifyContent="center">
          <AccountSelector />
        </Grid>
      </Grid>
    </>
  );
};

export default InvalidAccountPage;
