import { useEffect } from 'react';
import useEnv from './useEnv';
import { isEmptyOrSpaces } from 'utils/stringUtils';

const useDocumentTitle = (title: string) => {
  const env = useEnv();

  useEffect(() => {
    let fullTitle = `${title} - Dashboard`;

    if (!isEmptyOrSpaces(env.REACT_APP_TENANT_NAME)) {
      fullTitle = `${fullTitle} - ${env.REACT_APP_TENANT_NAME}`;
    }

    document.title = fullTitle;

    return () => {
      document.title = 'Dashboard';
    };
  }, [title]);
};

export default useDocumentTitle;
