import { CheckCircle, CloseOutlined, ExpandLessOutlined, ExpandMoreOutlined, WarningAmberTwoTone } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grow,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';
import IconButton from 'components/@extended/IconButton';
import CurrencyInput, { CurrencyInputChangeEvent } from 'components/currency-input/CurrencyInput';
import { useState } from 'react';
import { dispatch, useSelector } from 'store';
import { openAlert } from 'store/reducers/alert-dialog';
import { loadCustomerSnapshot } from 'store/reducers/snapshots/customerSnapshot';
import ErrorIcon from '@mui/icons-material/Error';
import { openSnackbar } from 'store/reducers/snackbar';
import useEnv from 'hooks/useEnv';
import { createPaymentRequest } from 'store/reducers/payments';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import VerifiedUserTwoToneIcon from '@mui/icons-material/VerifiedUserTwoTone';
import { makeStyles } from '@mui/styles';
import useActiveAccount from 'hooks/useActiveAccount';
import Loader from 'components/Loader';

const fullBalanceId = 'full-balance';
const balanceDueId = 'balance-due';
const fixedAmountId = 'fixed-amount';

export interface TrueLayerPaymentDialogProps {
  fullAmount: number;
  dueAmount: number;
  returnLocation: string;
  open: boolean;
  onClose: () => void;
  onCompleted: () => void;
}

const useStyles = makeStyles({
  root: {
    height: 27,
    paddingTop: 0,
    paddingBottom: 0
  },
  label: {}
});

const TrueLayerPaymentDialog = (props: TrueLayerPaymentDialogProps) => {
  const { isCreatingPayment } = useSelector((state) => state.payments);
  const [paymentOption, setPaymentOption] = useState(fullBalanceId);
  const [amount, setAmount] = useState<number>(props.fullAmount > 0 ? props.fullAmount : 0);
  const [expandManualPayment, setExpandManualPayment] = useState<boolean>(false);

  const env = useEnv();
  const account = useActiveAccount();

  const classes = useStyles();

  const handleClose = () => {
    props.onClose();
  };

  const handlePaymentOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentOption(event.target.value);

    if (event.target.value === fullBalanceId) {
      setAmount(props.fullAmount > 0 ? props.fullAmount : 0);
    } else if (event.target.value === balanceDueId) {
      setAmount(props.dueAmount > 0 ? props.dueAmount : 0);
    } else {
      setAmount(0);
    }
  };

  const handlePaymentCompleted = () => {
    dispatch(
      openAlert({
        open: true,
        size: 'xs',
        title: (
          <Stack direction="column" width="100%" justifyContent="center" alignItems="center" spacing={2}>
            <CheckCircle color="success" sx={{ fontSize: '4rem' }} />
            <Typography variant="h5">Your payment is on the way</Typography>
          </Stack>
        ),
        message: (
          <Stack direction="column" width="100%" textAlign="center" spacing={2} pb={1}>
            <Typography variant="body1">
              Your payment will be processed and appear on your account shortly. Allow up to 30 minutes before contacting us.
            </Typography>
            <Typography variant="body1">
              Please forward all remittance advices to assist with allocations to {env.REACT_APP_FINANCE_RECEIVABLE_EMAIL as string}.
            </Typography>
          </Stack>
        ),

        confirmButtonText: 'Continue',
        confirmButtonColour: 'success',
        onConfirm: () => {
          dispatch(loadCustomerSnapshot());
          props?.onCompleted();
        }
      })
    );
  };

  const handlePaymentError = (error: any) => {
    dispatch(
      openAlert({
        open: true,
        title: (
          <Stack direction="column" width="100%" justifyContent="center" alignItems="center" spacing={2}>
            <ErrorIcon color="error" sx={{ fontSize: '4rem' }} />
            <Typography variant="h5">Your payment request has failed</Typography>
          </Stack>
        ),
        message: (
          <Stack direction="column" width="100%" textAlign="center" pb={2}>
            <Typography variant="body1">
              Sorry, we were unable to create your payment request. Please try again later or contact our support via the live chat.
            </Typography>
          </Stack>
        ),
        cancelButtonText: 'Continue',
        onCancel: () => {}
      })
    );

    dispatch(
      openSnackbar({
        open: true,
        message: 'An error has occurred creating your payment request. Please try again later or contact support.',
        variant: 'alert',
        alert: {
          color: 'error'
        },
        close: true
      })
    );
  };

  const handleContinue = async () => {
    const returnUrl = `${env.REACT_APP_HOST_URL}//${props.returnLocation}`;

    await dispatch(createPaymentRequest(amount, returnUrl, handlePaymentCompleted, handlePaymentError));

    handleClose();
  };

  return (
    <>
      {isCreatingPayment && <Loader />}
      <Dialog open={props.open} onClose={handleClose} fullWidth maxWidth="xs">
        <Stack>
          <DialogTitle>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" alignItems="center" spacing={1}>
                <LockTwoToneIcon color="warning" sx={{ fontSize: '1.6rem' }} />
                <Typography variant="h5">Make a Payment</Typography>
              </Stack>
              <IconButton shape="rounded" onClick={handleClose}>
                <CloseOutlined color="secondary" fontSize="small" />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Stack spacing={1} direction="column">
              <Stack direction="row" minWidth="200" alignItems="end">
                <Stack width="50%">
                  <RadioGroup name="make-a-payment-button-popover-radio-group" value={paymentOption} onChange={handlePaymentOptionChange}>
                    <FormControlLabel
                      className={classes.label}
                      value={fullBalanceId}
                      control={<Radio className={classes.root} />}
                      label="Full Balance"
                    />
                    <FormControlLabel
                      className={classes.label}
                      value={balanceDueId}
                      control={<Radio className={classes.root} />}
                      label="Balance Due"
                    />
                    <FormControlLabel
                      className={classes.label}
                      value={fixedAmountId}
                      control={<Radio className={classes.root} />}
                      label="Fixed Amount"
                    />
                  </RadioGroup>
                </Stack>
                <Stack direction="row" spacing={1} justifyItems="end">
                  <FormControl fullWidth>
                    <CurrencyInput
                      name="amount"
                      label="Enter the payment amount"
                      amount={amount}
                      onChange={(event: CurrencyInputChangeEvent) => setAmount(Number(event.target.value))}
                      readonly={paymentOption !== fixedAmountId || isCreatingPayment}
                    />
                  </FormControl>
                </Stack>
              </Stack>

              <Stack direction="column" alignItems="end">
                <Button
                  id="make-a-payment-continue-button"
                  disabled={amount <= 0 || isCreatingPayment}
                  variant="contained"
                  color="primary"
                  onClick={() => handleContinue()}
                >
                  Continue
                </Button>
              </Stack>

              <Stack direction="column" spacing={1}>
                <Typography variant="subtitle1" sx={{ textDecoration: 'underline' }}>
                  How does it work?
                </Typography>
                <Typography variant="body2" fontWeight={500}>
                  Pay via online bank transfer directly from your current account. We accept all major UK banks.
                </Typography>
                <Stack spacing={0.2} direction="column" pl={2}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <VerifiedUserTwoToneIcon color="success" sx={{ fontSize: '1.5rem' }} />
                    <Typography variant="body2" fontWeight={500}>
                      Fast, safe and secure
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <VerifiedUserTwoToneIcon color="success" sx={{ fontSize: '1.5rem' }} />
                    <Typography variant="body2" fontWeight={500}>
                      Authorise in your bank app
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <VerifiedUserTwoToneIcon color="success" sx={{ fontSize: '1.5rem' }} />
                    <Typography variant="body2" fontWeight={500}>
                      No mistakes from manual typing
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction="column" justifyContent="center" sx={{ cursor: 'pointer' }} spacing={1} pt={1}>
                <Stack direction="row" justifyContent="center" onClick={() => setExpandManualPayment(!expandManualPayment)}>
                  <Typography variant="subtitle1" color="secondary" sx={{ textDecoration: 'underline' }}>
                    Or send us a manual payment
                  </Typography>
                  {!expandManualPayment && <ExpandMoreOutlined color="secondary" />}
                  {expandManualPayment && <ExpandLessOutlined color="secondary" />}
                </Stack>
                {expandManualPayment && (
                  <Stack spacing={2}>
                    <Stack mt={1}>
                      <Divider />
                    </Stack>
                    <Grow in={expandManualPayment}>
                      <Stack justifyContent="center" spacing={1}>
                        <Stack>
                          <Typography variant="body2" fontWeight={500}>
                            Use these details to make a manual bank transfer to our account.
                          </Typography>
                        </Stack>
                        <Stack>
                          <Stack direction="row" spacing={1}>
                            <Typography variant="subtitle2" fontWeight={600}>
                              Account Name:
                            </Typography>
                            <Typography variant="body2">{env.REACT_APP_PAYMENT_ACCOUNT_NAME}</Typography>
                          </Stack>
                          <Stack direction="row" spacing={1}>
                            <Typography variant="subtitle2" fontWeight={600}>
                              Account Number:
                            </Typography>
                            <Typography variant="body2">{env.REACT_APP_PAYMENT_ACCOUNT_NUMBER}</Typography>
                          </Stack>
                          <Stack direction="row" spacing={1}>
                            <Typography variant="subtitle2" fontWeight={600}>
                              Sort Code:
                            </Typography>
                            <Typography variant="body2">{env.REACT_APP_PAYMENT_SORT_CODE}</Typography>
                          </Stack>
                          <Stack direction="column" spacing={1}>
                            <Stack direction="row" spacing={1}>
                              <Typography variant="subtitle2" fontWeight={600}>
                                Reference:
                              </Typography>
                              <Typography variant="body2">{account?.accountCode}</Typography>
                            </Stack>

                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                              <WarningAmberTwoTone color="warning" sx={{ fontSize: '1.2rem' }} />
                              <Typography variant="caption" color="warning.main">
                                Please make sure the reference matches exactly
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Grow>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </DialogContent>
        </Stack>
      </Dialog>
    </>
  );
};

export default TrueLayerPaymentDialog;
