import { SearchOutlined } from '@mui/icons-material';
import { MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material';
import IconButton from 'components/@extended/IconButton';
import LightTooltip from 'components/LightToolTip';
import { LookupItem } from 'types/api';

export interface FilterSearchProps {
  entityTypes?: LookupItem[];
  selectedEntityType?: LookupItem;
  searchString: string | null;
  disabled?: boolean;
  label?: string;
  autoFocus?: boolean;
  errorText?: string;
  onEntityTypeChanged?: (value: LookupItem) => void;
  onSearchStringChanged?: (value: string) => void;
  onBlur?: () => void;
  onSearch: (value?: string) => void;
}

const handleEntityTypeChanged = (props: FilterSearchProps, event: SelectChangeEvent) => {
  if (!props.entityTypes || !props.onEntityTypeChanged) return;

  const matchingType = props.entityTypes.find((f) => f.value === event.target.value);

  if (matchingType) props.onEntityTypeChanged(matchingType);
};

export const FilterSearch = (props: FilterSearchProps) => {
  return (
    <Stack direction="row" columnGap={1} width="100%">
      {!!props.entityTypes && (
        <Stack direction="row">
          <Select
            size="small"
            sx={{ backgroundColor: 'white' }}
            value={props.selectedEntityType?.value}
            disabled={props.disabled}
            onChange={(event) => {
              handleEntityTypeChanged(props, event);
            }}
            renderValue={() => <Typography variant="body1">{props.selectedEntityType?.displayValue}</Typography>}
          >
            {props.entityTypes.map((o) => (
              <MenuItem key={o.value} value={o.value}>
                <Typography variant="body1">{o.displayValue}</Typography>
              </MenuItem>
            ))}
          </Select>
        </Stack>
      )}
      <Stack direction="row" width="100%" spacing={1}>
        <TextField
          autoComplete="off"
          variant="outlined"
          label={props.label ?? 'e.g. 1345, 12324'}
          value={props.searchString}
          disabled={props.disabled}
          fullWidth
          autoFocus={props.autoFocus}
          error={!!props.errorText}
          helperText={props.errorText ?? null}
          sx={{
            '& .MuiInputBase-input': {
              height: '16px'
            }
          }}
          InputLabelProps={{
            style: { color: 'grey' }
          }}
          onChange={(event) => {
            if (!!props.onSearchStringChanged) {
              props.onSearchStringChanged(event.target.value);
            }
          }}
          onBlur={props.onBlur}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              //@ts-ignore
              props.onSearch(props.searchString);
            }
          }}
        />

        <LightTooltip title="Search">
          <IconButton
            size="medium"
            variant="contained"
            color="success"
            disabled={props.disabled}
            onClick={() => {
              props.onSearch(props.searchString ?? '');
            }}
          >
            <SearchOutlined />
          </IconButton>
        </LightTooltip>
      </Stack>
    </Stack>
  );
};
