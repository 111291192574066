import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dispatch } from '../index';
import axiosServices from 'utils/api/axiosServices';
import { openSnackbar } from './snackbar';

export interface CustomerStatementActionsProps {
  isSendingStatement: boolean;
}

const InitialState: CustomerStatementActionsProps = {
  isSendingStatement: false
};

const slice = createSlice({
  name: 'customerStatements',
  initialState: { ...InitialState },
  reducers: {
    setSendingStatement(state, action: PayloadAction<boolean>) {
      state.isSendingStatement = action.payload;
    }
  }
});

export default slice.reducer;

export function sendCustomerStatement() {
  return async () => {
    try {
      dispatch(slice.actions.setSendingStatement(true));

      await axiosServices.post(`queue-customer-statement`);

      dispatch(
        openSnackbar({
          open: true,
          message: 'Your request is being processed. You should receive your statement by email in a minute or two.',
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: true
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setSendingStatement(false));
    }
  };
}
