import { Stack, Typography } from '@mui/material';

export interface TitleAndCaptionRegionProps {
  title: string;
  caption?: string;
  titleFontVariant?: string;
  captionFontVariant?: string;
}

const TitleAndCaptionRegion = (props: TitleAndCaptionRegionProps) => {
  return (
    <Stack direction="column">
      <Typography
        sx={{ lineHeight: 1.2 }}
        // @ts-ignore
        variant={props.titleFontVariant ?? 'h6'}
        noWrap
        fontWeight={800}
      >
        {props.title}
      </Typography>
      {props.caption && (
        <Typography
          // @ts-ignore
          variant={props.captionFontVariant ?? 'caption'}
        >
          {props.caption}
        </Typography>
      )}
    </Stack>
  );
};

export default TitleAndCaptionRegion;
