// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import snackbar from './snackbar';
import userSettings from './user-settings';
import accountInContext from './accountInContext';
import customerStatementsReducer from './customer-statement';
import salesInvoiceSummaryReducer from './pages/sales-invoice-summary';
import teamAdminReducer from './pages/team-admin';
import customerSnapshotReducer from './snapshots/customerSnapshot';
import deliverySummaryReducer from './pages/delivery-details';
import warehouseSummarySnapshotReducer from './snapshots/warehouse-snapshot';
import shippingSnapshotReducer from './snapshots/shipping-snapshot';
import homePageReducer from './pages/home-page';
import customerReportsReducer from './pages/reports';
import returnsSnapshotReducer from './snapshots/returns-snapshot';
import returnDetailsReducer from './pages/sales-return-details';
import salesOrderSnapshotReducer from './snapshots/sales-orders-snapshot';
import salesOrderSummaryReducer from './pages/sales-order-details';
import salesOrderCreateReducer from './pages/create-sales-order';
import productDetailsReducer from './pages/product-details';
import customerAlertsReducer from './customer-alerts';
import paymentsReducer from './payments';
import alertDialogReducer from './alert-dialog';
import featuresReducer from './features';
import salesOrderDraftsReducer from './pages/sales-order-drafts';
import pinnedOrderReducer from './pinned-order';
import recentlyUsedAccountsReducer from './recentlyUsedAccounts';
import notificationTopicsReducer from './notification-topics';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  userSettings,
  accountInContext,
  customerStatements: customerStatementsReducer,
  salesInvoiceSummary: salesInvoiceSummaryReducer,
  teamAdmin: teamAdminReducer,
  customerSnapshot: customerSnapshotReducer,
  deliverySummary: deliverySummaryReducer,
  warehouseSummarySnapshot: warehouseSummarySnapshotReducer,
  shippingSnapshot: shippingSnapshotReducer,
  homePage: homePageReducer,
  reports: customerReportsReducer,
  returnsSnapshot: returnsSnapshotReducer,
  returnDetails: returnDetailsReducer,
  salesOrdersSnapshot: salesOrderSnapshotReducer,
  salesOrderSummary: salesOrderSummaryReducer,
  salesOrderCreate: salesOrderCreateReducer,
  productDetails: productDetailsReducer,
  customerAlerts: customerAlertsReducer,
  payments: paymentsReducer,
  alertDialog: alertDialogReducer,
  features: featuresReducer,
  salesOrderDrafts: salesOrderDraftsReducer,
  pinnedOrder: pinnedOrderReducer,
  recentlyUsedAccounts: recentlyUsedAccountsReducer,
  notificationTopics: notificationTopicsReducer
});

export default reducers;
