import { useEffect, useState } from 'react';
import { NavigateOptions, Outlet } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Container, Toolbar } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import Footer from './Footer';
import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import Breadcrumbs from 'components/@extended/Breadcrumbs';

// types
import { openDrawer } from 'store/reducers/menu';
import { useDispatch, useSelector } from 'store';
import { AccountAlert, AccountAlertType } from 'types/customer-alerts';
import AlertNotification from 'layout/alert-notification';
import useAuth from 'hooks/useAuth';
import useDashboardNavigate from 'hooks/useDashboardNavigate';

const Alerts = (props: { alerts: AccountAlert[] | null }) => {
  const navigate = useDashboardNavigate();
  const auth = useAuth();

  // @ts-ignore
  const openChat = { title: 'Start Chat', action: () => openGorgiasChatWindow(auth.user?.email ?? '') };
  const navigateToLink = (alert: AccountAlert) => {
    const options = alert.type === AccountAlertType.MakeAPayment ? ({ state: { openMakeAPayment: true } } as NavigateOptions) : undefined;

    return { title: alert.navigateToLinkTitle!, action: () => navigate(alert.navigateToLink!, options) };
  };

  return (
    <>
      {props.alerts?.map((alert, index) => {
        const action = alert.navigateToLink ? navigateToLink(alert) : openChat;

        return <AlertNotification key={index} title={alert.title} caption={alert.message} action={action} />;
      })}
    </>
  );
};

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
  const customerAlerts = useSelector((state) => state.customerAlerts);

  const { container, miniDrawer } = useConfig();
  const dispatch = useDispatch();

  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  // drawer toggler
  const [open, setOpen] = useState(!miniDrawer || drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownLG);
      dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box component="main" sx={{ width: 'calc(100% - 260px)', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
        <Toolbar />
        {container && (
          <Container
            maxWidth="lg"
            sx={{ px: { xs: 0, sm: 2 }, position: 'relative', minHeight: 'calc(100vh - 110px)', display: 'flex', flexDirection: 'column' }}
          >
            <Breadcrumbs navigation={navigation} card={false} divider={false} />

            <Alerts alerts={customerAlerts?.alerts ?? null} />

            <Outlet />
            <Footer />
          </Container>
        )}
        {!container && (
          <Box sx={{ position: 'relative', minHeight: 'calc(100vh - 110px)', display: 'flex', flexDirection: 'column' }}>
            <Breadcrumbs navigation={navigation} card={false} divider={false} />

            <Alerts alerts={customerAlerts?.alerts ?? null} />

            <Outlet />
            <Footer />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MainLayout;
