export interface AccountAlert {
  title: string;
  message: string;
  openChatAction?: boolean;
  navigateToLink?: string;
  navigateToLinkTitle?: string;
  type?: AccountAlertType;
}

export enum AccountAlertType {
  MakeAPayment = 1,
  OpenChat = 2
}
