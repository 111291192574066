import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { ApiResponse } from 'types/api';
import { SalesOrderSnapshot } from 'types/snapshots/salesOrdersSnapshot';
import axiosServices from 'utils/api/axiosServices';

export interface SalesOrdersSnapshotProps {
  isSnapshotFetching: boolean;
  snapshot: SalesOrderSnapshot | null;
}

export const SalesOrdersSnapshotInitialState: SalesOrdersSnapshotProps = {
  isSnapshotFetching: false,
  snapshot: null
};

const slice = createSlice({
  name: 'salesOrdersSnapshot',
  initialState: { ...SalesOrdersSnapshotInitialState },
  reducers: {
    setSnapshotFetching(state, action: PayloadAction<boolean>) {
      state.isSnapshotFetching = action.payload;
    },
    setSnapshot(state, action: PayloadAction<SalesOrderSnapshot>) {
      state.snapshot = action.payload;
    },
    resetSalesOrdersSnapshot(state) {
      state.snapshot = SalesOrdersSnapshotInitialState.snapshot;
    }
  }
});

export default slice.reducer;
export const { resetSalesOrdersSnapshot } = slice.actions;

export function loadSalesOrdersSnapshot() {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch(slice.actions.setSnapshotFetching(true));

      const response = await axiosServices.get<ApiResponse<SalesOrderSnapshot>>('sales-orders/snapshot');

      dispatch(slice.actions.setSnapshot(response.data.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setSnapshotFetching(false));
    }
  };
}
