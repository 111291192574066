import { CircularProgress, Divider, Grid, Stack, Typography } from '@mui/material';
import MainCard from 'components/MainCard';
import { ReactNode } from 'react';

export interface SnapshotCardProps {
  title: string;
  subTitle: string | null;
  children: ReactNode | null;
  isLoading: boolean;
  loadingText: string | null;
}

export const SnapshotCard = (props: SnapshotCardProps) => {
  return (
    <MainCard sx={{ borderRadius: { xs: 0, sm: 1 }, height: '100%' }}>
      <Grid container>
        <Grid item xs={12}>
          <Stack>
            <Typography variant="h5">{props.title}</Typography>
            <Typography variant="caption">{props.subTitle ?? ''}</Typography>
            <Divider sx={{ pt: 1, width: '100%' }} />
          </Stack>
        </Grid>

        {props.isLoading && (
          <Grid item xs={12} mt={2}>
            <Stack direction="row" justifyContent="center" spacing={3}>
              <Stack direction="column" alignItems="center" justifyContent="center">
                <CircularProgress color="info" />
                <Typography mt={1} variant="body1">
                  {props.loadingText ?? ''}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        )}
        <Grid item xs={12}>
          {!props.isLoading && <>{props.children ?? 'No content'}</>}
        </Grid>
      </Grid>
    </MainCard>
  );
};
