import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState, dispatch } from 'store';
import { ApiResponse } from 'types/api';
import { CustomerStockSetupDto, ProductStockDto, SaveCustomerStockSetupDto } from 'types/pages/products';
import { SalesOrderHeader } from 'types/pages/sales-orders';
import axiosServices from 'utils/api/axiosServices';

export interface ProductDetailsProps {
  isFetchingProduct: boolean;
  isFetchingRelatedProducts: boolean;
  isFetchingOrders: boolean;
  isFetchingProductInsights: boolean;
  isSavingCustomerSetup: boolean;
  isInvalidProduct: boolean;
  productStock: ProductStockDto | null;
  relatedProducts: ProductStockDto[] | null;
  relatedOrders: SalesOrderHeader[] | null;
  productInsightsUrl: string | null;
  customerSetup: SaveCustomerStockSetupDto | null;
}

export const ProductDetailsInitialState: ProductDetailsProps = {
  isFetchingProduct: false,
  isFetchingRelatedProducts: false,
  isFetchingOrders: false,
  isFetchingProductInsights: false,
  isSavingCustomerSetup: false,
  isInvalidProduct: false,
  productStock: null,
  relatedProducts: null,
  relatedOrders: null,
  productInsightsUrl: null,
  customerSetup: null
};

const slice = createSlice({
  name: 'productDetails',
  initialState: { ...ProductDetailsInitialState },
  reducers: {
    setFetchingProduct(state, action: PayloadAction<boolean>) {
      state.isFetchingProduct = action.payload;
    },
    setInvalidProduct(state, action: PayloadAction<boolean>) {
      state.isInvalidProduct = action.payload;
    },
    setProduct(state, action: PayloadAction<ProductStockDto>) {
      state.productStock = action.payload;
    },
    setFetchingRelatedProducts(state, action: PayloadAction<boolean>) {
      state.isFetchingRelatedProducts = action.payload;
    },
    setFetchingProductInsights(state, action: PayloadAction<boolean>) {
      state.isFetchingProductInsights = action.payload;
    },
    setSavingCustomerSetup(state, action: PayloadAction<boolean>) {
      state.isSavingCustomerSetup = action.payload;
    },
    setRelatedProducts(state, action: PayloadAction<ProductStockDto[]>) {
      state.relatedProducts = action.payload;
    },
    setFetchingOrders(state, action: PayloadAction<boolean>) {
      state.isFetchingOrders = action.payload;
    },
    setRelatedSalesOrders(state, action: PayloadAction<SalesOrderHeader[]>) {
      state.relatedOrders = action.payload;
    },
    setProductInsightsUrl(state, action: PayloadAction<string>) {
      state.productInsightsUrl = action.payload;
    },
    setCustomerSetup(state, action: PayloadAction<SaveCustomerStockSetupDto>) {
      state.customerSetup = action.payload;
    },
    resetProductDetails(state) {
      state.isFetchingProduct = ProductDetailsInitialState.isFetchingProduct;
      state.isInvalidProduct = ProductDetailsInitialState.isInvalidProduct;
      state.productStock = ProductDetailsInitialState.productStock;
      state.isFetchingRelatedProducts = ProductDetailsInitialState.isFetchingRelatedProducts;
      state.relatedProducts = ProductDetailsInitialState.relatedProducts;
      state.isFetchingOrders = ProductDetailsInitialState.isFetchingOrders;
      state.relatedOrders = ProductDetailsInitialState.relatedOrders;
      state.productInsightsUrl = ProductDetailsInitialState.productInsightsUrl;
      state.isFetchingProductInsights = ProductDetailsInitialState.isFetchingProductInsights;
      state.isSavingCustomerSetup = ProductDetailsInitialState.isSavingCustomerSetup;
      state.customerSetup = ProductDetailsInitialState.customerSetup;
    }
  }
});

export default slice.reducer;
export const { resetProductDetails, setCustomerSetup } = slice.actions;

export function loadProductDetails(stockCode: string) {
  return async () => {
    try {
      dispatch(slice.actions.setFetchingProduct(true));

      const summaryResponse = await axiosServices.get<ApiResponse<ProductStockDto>>(`products/${stockCode}`);

      dispatch(slice.actions.setProduct(summaryResponse.data.data));

      const customerSetup: SaveCustomerStockSetupDto = {
        ranged: summaryResponse.data.data.ranged,
        customerSku: summaryResponse.data.data.product.customerSku
      };

      dispatch(slice.actions.setCustomerSetup(customerSetup));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.setInvalidProduct(true));
    } finally {
      dispatch(slice.actions.setFetchingProduct(false));
    }
  };
}

export function saveCustomerSetup() {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const currentState = getState();
      const productStock = currentState?.productDetails?.productStock;
      const customerSetup = currentState?.productDetails?.customerSetup;

      dispatch(slice.actions.setSavingCustomerSetup(true));

      const response = await axiosServices.put<ApiResponse<CustomerStockSetupDto>>(
        `products/${productStock!.product!.sku}/customer-stock-setup`,
        customerSetup
      );

      const data = response.data.data;

      dispatch(slice.actions.setCustomerSetup(data));

      const updatedProductStock = {
        ...productStock!,
        ranged: data.ranged,
        proposed: data.proposed,
        product: { ...productStock!.product!, customerSku: data.customerSku }
      };

      dispatch(slice.actions.setProduct({ ...updatedProductStock }));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setSavingCustomerSetup(false));
    }
  };
}

export function loadRelatedProducts(stockCode: string) {
  return async () => {
    try {
      dispatch(slice.actions.setFetchingRelatedProducts(true));

      const response = await axiosServices.get<ApiResponse<ProductStockDto[]>>(`products/${stockCode}/related-products`);

      dispatch(slice.actions.setRelatedProducts(response.data.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setFetchingRelatedProducts(false));
    }
  };
}

export function loadRelatedSalesOrders(stockCode: string) {
  return async () => {
    try {
      dispatch(slice.actions.setFetchingOrders(true));

      const ordersResponse = await axiosServices.get<ApiResponse<SalesOrderHeader[]>>(`products/${stockCode}/sales-orders`);

      dispatch(slice.actions.setRelatedSalesOrders(ordersResponse.data.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setFetchingOrders(false));
    }
  };
}

export function loadProductInsightUrls(stockCode: string) {
  return async () => {
    try {
      dispatch(slice.actions.setFetchingProductInsights(true));

      const response = await axiosServices.get<ApiResponse<string>>(`reports/product-insights/${stockCode}`);

      dispatch(slice.actions.setProductInsightsUrl(response.data.data));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(slice.actions.setFetchingProductInsights(false));
    }
  };
}
