import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import { useMemo } from 'react';

// ==============================|| ROUTING RENDER ||============================== //

export interface RoutesProps {
  basePath: string;
}

export default function Routes(props: RoutesProps) {
  const mainRoutes = useMemo(() => MainRoutes(props.basePath), [props.basePath]);

  return useRoutes([LoginRoutes, mainRoutes]);
}
