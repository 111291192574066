// material-ui
import { Theme } from '@mui/material/styles';

// project import
import getColors from 'utils/getColors';

// types
import { ChipVariantProps, ExtendedStyleProps } from 'types/extended';

// ==============================|| CHIP - COLORS ||============================== //

interface ChipStyleProps extends ExtendedStyleProps {
  variant: ChipVariantProps;
}
function getColor({ color, theme }: ExtendedStyleProps) {
  const colors = getColors(theme, color);
  const { dark } = colors;

  return {
    '&.Mui-focusVisible': {
      outline: `2px solid ${dark}`,
      outlineOffset: 2
    }
  };
}

function getColorStyle({ variant, color, theme }: ChipStyleProps) {
  const colors = getColors(theme, color);
  const { light, lighter, main, contrastText } = colors;

  // TODO : Finish color styles....
  switch (variant) {
    case 'light':
      return {
        color: main,
        backgroundColor: lighter,
        borderColor: light,
        '& .MuiChip-deleteIcon': {
          color: main,
          '&:hover': {
            color: light
          }
        }
      };
    case 'filled':
      return {
        color: contrastText,
        backgroundColor: main,
        borderColor: main,
        '& .MuiChip-deleteIcon': {
          color: main,
          '&:hover': {
            color: light
          }
        }
      };

    default:
      return {
        color: main,
        backgroundColor: lighter,
        borderColor: light,
        '& .MuiChip-deleteIcon': {
          color: main,
          '&:hover': {
            color: light
          }
        }
      };
  }
}

// ==============================|| OVERRIDES - CHIP ||============================== //

export default function Chip(theme: Theme) {
  const defaultLightChip = getColorStyle({ variant: 'light', color: 'secondary', theme });
  const defaultFilledChip = getColorStyle({ variant: 'filled', color: 'secondary', theme });
  const defaultCombinedChip = getColorStyle({ variant: 'combined', color: 'secondary', theme });

  return {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          '&:active': {
            boxShadow: 'none'
          },
          '&.MuiChip-colorPrimary': getColor({ color: 'primary', theme }),
          '&.MuiChip-colorSecondary': getColor({ color: 'secondary', theme }),
          '&.MuiChip-colorError': getColor({ color: 'error', theme }),
          '&.MuiChip-colorInfo': getColor({ color: 'info', theme }),
          '&.MuiChip-colorSuccess': getColor({ color: 'success', theme }),
          '&.MuiChip-colorWarning': getColor({ color: 'warning', theme }),
          '&.MuiChip-colorHighlight': getColor({ color: 'highlight', theme })
        },
        sizeLarge: {
          fontSize: '1rem',
          height: 40
        },
        light: {
          ...defaultLightChip,
          '&.MuiChip-lightPrimary': getColorStyle({ variant: 'light', color: 'primary', theme }),
          '&.MuiChip-lightSecondary': getColorStyle({ variant: 'light', color: 'secondary', theme }),
          '&.MuiChip-lightError': getColorStyle({ variant: 'light', color: 'error', theme }),
          '&.MuiChip-lightInfo': getColorStyle({ variant: 'light', color: 'info', theme }),
          '&.MuiChip-lightSuccess': getColorStyle({ variant: 'light', color: 'success', theme }),
          '&.MuiChip-lightWarning': getColorStyle({ variant: 'light', color: 'warning', theme }),
          '&.MuiChip-lightHighlight': getColorStyle({ variant: 'light', color: 'highlight', theme })
        },
        filled: {
          ...defaultFilledChip,
          '&.MuiChip-filledPrimary': getColorStyle({ variant: 'filled', color: 'primary', theme }),
          '&.MuiChip-filledSecondary': getColorStyle({ variant: 'filled', color: 'secondary', theme }),
          '&.MuiChip-filledError': getColorStyle({ variant: 'filled', color: 'error', theme }),
          '&.MuiChip-filledInfo': getColorStyle({ variant: 'filled', color: 'info', theme }),
          '&.MuiChip-filledSuccess': getColorStyle({ variant: 'filled', color: 'success', theme }),
          '&.MuiChip-filledWarning': getColorStyle({ variant: 'filled', color: 'warning', theme }),
          '&.MuiChip-filledHighlight': getColorStyle({ variant: 'filled', color: 'highlight', theme })
        },
        combined: {
          border: '1px solid',
          ...defaultCombinedChip,
          '&.MuiChip-combinedPrimary': getColorStyle({ variant: 'combined', color: 'primary', theme }),
          '&.MuiChip-combinedSecondary': getColorStyle({ variant: 'combined', color: 'secondary', theme }),
          '&.MuiChip-combinedError': getColorStyle({ variant: 'combined', color: 'error', theme }),
          '&.MuiChip-combinedInfo': getColorStyle({ variant: 'combined', color: 'info', theme }),
          '&.MuiChip-combinedSuccess': getColorStyle({ variant: 'combined', color: 'success', theme }),
          '&.MuiChip-combinedWarning': getColorStyle({ variant: 'combined', color: 'warning', theme }),
          '&.MuiChip-combinedHighlight': getColorStyle({ variant: 'combined', color: 'highlight', theme })
        }
      }
    }
  };
}
