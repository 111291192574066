import { CircularProgress, Divider, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { dispatch, useSelector } from 'store';
import { loadCustomerSnapshot } from 'store/reducers/snapshots/customerSnapshot';
import { formatMoney } from 'utils/stringUtils';
import AgedDebtAnalysisChart from './AgedDebtAnalysisChart';

import { SnapshotCard } from 'components/snapshot-card/SnapshotCard';
import MakeATrueLayerPaymentButton from 'components/make-a-payment-button/MakeAPaymentButton';
import FeatureToggle from 'components/feature-toggle/FeatureToggle';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LightTooltip from 'components/LightToolTip';
import PermissionGuard from 'components/permission-guard/PermissionGuard';
import { AccountPermissionLevel } from 'types/account';
import MainCard from 'components/MainCard';
import { useLocation } from 'react-router';

export interface CustomerSnapshotProps {
  onTrueLayerDialogClosed: () => void;
}

const CustomerSnapshot = (props: CustomerSnapshotProps) => {
  const snapshotState = useSelector((state) => state.customerSnapshot);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const { state } = useLocation();

  useEffect(() => {
    dispatch(loadCustomerSnapshot());

    // @ts-ignore
    if (state?.openMakeAPayment) {
      setPaymentDialogOpen(true);
    }
  }, []);

  useEffect(() => {
    // @ts-ignore
    setPaymentDialogOpen(state?.openMakeAPayment ?? false);

    // @ts-ignore
  }, [state?.openMakeAPayment]);

  const balances = snapshotState.snapshot?.balances;
  const isLoading = snapshotState.isLoading || !snapshotState.snapshot?.balances;

  const handleTrueLayerOpen = () => {
    setPaymentDialogOpen(true);
  };

  const handleTrueLayerClose = () => {
    setPaymentDialogOpen(false);
    props.onTrueLayerDialogClosed();
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <MainCard sx={{ borderRadius: { xs: 0, sm: 1 }, height: '100%' }}>
            <Grid container>
              <Grid item xs={12}>
                <Stack>
                  <Typography variant="h5">Your financial snapshot</Typography>
                  <Typography variant="caption">Here is a summary of your account</Typography>
                  <Divider sx={{ pt: 1, width: '100%' }} />
                </Stack>
              </Grid>

              {isLoading && (
                <Grid item xs={12} mt={2}>
                  <Stack direction="row" justifyContent="center" spacing={3}>
                    <Stack direction="column" alignItems="center" justifyContent="center">
                      <CircularProgress color="info" />
                      <Typography mt={1} variant="body1">
                        Building your snapshot...
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} mt={2}>
                    <Stack direction="column" justifyContent="flex-start" mr={2}>
                      {!isLoading && (
                        <>
                          <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="body1" fontWeight={600}>
                              Balance
                            </Typography>
                            <Typography variant="body1">{formatMoney(balances!.currencySymbol, balances!.openInvoices)}</Typography>
                          </Stack>
                          <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Stack direction="row">
                              <Typography variant="body1" fontWeight={600}>
                                Balance Due
                              </Typography>
                              {balances?.hasOutstandingPayments && (
                                <LightTooltip
                                  placement="right"
                                  title="You have unallocated payments which may result in a higher than expected balance due"
                                >
                                  <InfoOutlinedIcon fontSize="small" color="warning" sx={{ ml: 0.5 }} />
                                </LightTooltip>
                              )}
                            </Stack>
                            <Typography variant="body1">{formatMoney(balances!.currencySymbol, balances!.overdueInvoices)}</Typography>
                          </Stack>
                          <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="body1" fontWeight={600}>
                              Credit Limit
                            </Typography>
                            <Typography variant="body1">{formatMoney(balances!.currencySymbol, balances!.creditLimit)}</Typography>
                          </Stack>
                          <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="body1" fontWeight={600}>
                              Credit Available
                            </Typography>
                            <Typography variant="body1">{formatMoney(balances!.currencySymbol, balances!.creditAvailable)}</Typography>
                          </Stack>
                        </>
                      )}
                      <FeatureToggle feature="MakeAPayment">
                        <PermissionGuard canAccess={[AccountPermissionLevel.Admin, AccountPermissionLevel.User]}>
                          <Stack mt={1}>
                            <MakeATrueLayerPaymentButton
                              open={paymentDialogOpen}
                              onClicked={handleTrueLayerOpen}
                              onClose={handleTrueLayerClose}
                              onCompleted={handleTrueLayerClose}
                              returnLocation="sales-invoices"
                              fullAmount={balances?.openInvoices ?? 0}
                              dueAmount={balances?.overdueInvoices ?? 0}
                            />
                          </Stack>
                        </PermissionGuard>
                      </FeatureToggle>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
        <Grid item xs={12} md={7}>
          <SnapshotCard
            title="Your aged debt analysis"
            subTitle="Here is an analysis of your credit standing"
            isLoading={isLoading}
            loadingText="Analysing your aged debt..."
            children={
              <Stack mt={1}>
                <AgedDebtAnalysisChart />
              </Stack>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CustomerSnapshot;
