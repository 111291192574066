import { FormattedMessage } from 'react-intl';
import { NavItemType } from 'types/menu';
// assets
import { AnalyticsOutlined } from '@mui/icons-material';
import { MenuItemIds } from './menu-item-ids';
import Routes from 'routes/RouteNames';

// icons
const icons = {
  AnalyticsOutlined
};

const analytics: NavItemType = {
  id: MenuItemIds.AnalyticsGroupItemId,
  title: <FormattedMessage id="analytics-group" />,
  type: 'group',
  children: [
    {
      id: MenuItemIds.AnalyticsReportsNavItemId,
      title: <FormattedMessage id={MenuItemIds.AnalyticsReportsNavItemId} />,
      type: 'item',
      url: `/${Routes.Reports}`,
      icon: icons.AnalyticsOutlined,
      breadcrumbs: false
    }
  ]
};

export default analytics;
