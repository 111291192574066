// material-ui

import useEnv from 'hooks/useEnv';
import useImage from 'hooks/useImage';

const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
  const env = useEnv();
  const image = useImage(`branding/${env.REACT_APP_LOGO_LARGE_URL}`);

  if (image.loading || !image.image) return <></>;

  return <img src={image.image} alt="Branding Large" width="150" />;
};

export default LogoMain;
